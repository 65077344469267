.courseHeader {
  .home-dashboard-header .fixed-header {
    left: 250px;
    justify-content: unset;
  }
  .MuiTypography-root {
    margin: auto;
  }
  @media (max-width: 910px) {
    .home-dashboard-header .fixed-header {
      // left: 250px;
      justify-content: center;
    }
    .breadcrumbs {
      display: none;
    }
  }
  .MuiBreadcrumbs-li a {
    color: var(--themeColor);
  }
  .MuiBreadcrumbs-li a:hover {
    color: var(--ctaColor) !important;
    font-weight: 500;
  }
  .header-content .MuiBreadcrumbs-root {
    // margin-left: 1rem;
    margin-left: 10%;
  }
  .MuiBreadcrumbs-li p {
    font-family: Source Sans Pro;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;

    color: rgba(0, 0, 0, 0.5);
  }
  .header-heading2 {
    // width: 215px;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: rgba(33, 42, 57, 0.75);
    margin-left: 20px;
    max-width: calc(100% - 372px);
  }
  .heading-text2 {
    width: 100%;
    // overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .nav-item.header-get-help-container {
    min-width: 110px;
  }
  @media only screen and (max-width: 910px) {
    .heading-text2,
    .header-heading2 {
      display: none;
    }
  }
}
