.li {
}

.prof-dtl-modal {
  max-width: 750px;
}
.prof-dtl-header {
  color: #3c4852;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.nsdc-dtl-header {
  color: #3c4852;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  text-transform: capitalize;
}

.nsdc-dtl-header-2 {
  color: #a09fa0;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  text-transform: capitalize;
}

.nsdc-dtl-txt {
  color: rgba(60, 72, 82, 0.8);
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin-bottom: 32px;
}

.nsdc-dtl-txt-2 {
  color: rgba(60, 72, 82, 0.8);
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  margin-bottom: 16px;
}

.nsdc-create-btn {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  background: var(--baseColor);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  z-index: 101;
  outline: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  padding: 16px 32px;
}

.nsdc-create-btn:hover {
  color: #fff !important;
}

.nsdc-update-btn {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  background: rgba(3, 86, 66, 0.1);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #035642;
  font-weight: 600;
  z-index: 101;
  outline: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  padding: 16px 32px;
}

.nsdc-tutorial-btn {
  display: flex;
  justify-content: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  color: #035642;
  font-weight: 600;
  z-index: 101;
  outline: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1.5px solid #055646;
  background: #fff;
}

.nsdc-logo-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}

.nsdc-btn-gap {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding-bottom: 32px;
}

.nsdc-reminder-box {
  display: flex;
  padding: 16px 16px 24px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: #fdf4e7;
  margin-bottom: 16px;
}

.nsdc-reminder-txt {
  color: #a36809;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nsdc-submit-btn {
  display: flex;
  width: 180px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  // opacity: 0.7;
  background: #035642;
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
}

.nsdc-submit-btn:hover {
  color: #fff;
}

.nsdc-profile-format-box {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: 8px;
  background: rgba(60, 72, 82, 0.08);
  margin-top: 8px;
  margin-bottom: 8px;
}

.nsdc-profile-format-heading {
  color: rgba(60, 72, 82, 0.8);
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin-bottom: 2px;
}

.nsdc-profile-format-txt {
  color: rgba(60, 72, 82, 0.8);
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  margin-bottom: 0px;
}

.nsdc-profile-link-heading {
  color: rgba(60, 72, 82, 0.8);
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.nsdc-mobile-box {
  display: flex;
  margin-bottom: 24px;
  margin-top: 8px;
}

.nsdc-ccod-box {
  width: 22%;
  border-radius: 8px;
  background: rgba(60, 72, 82, 0.08);
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 12px;
}

.nsdc-ccod-txt {
  color: #3c4852;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  text-transform: capitalize;
}

.nsdc-number-box {
  width: 77%;
  margin-left: 4px;
}

.nsdc-number-box-txt {
  display: flex;
  padding: 12px 16px 12px 16px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  background: #fff;
  height: 100%;
}

.nsdc-number-error-box-txt {
  display: flex;
  padding: 12px 16px 12px 16px;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  background: #fff;
  height: 100%;
  color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
}

.nsdc-url-box {
  display: flex;
  margin-bottom: 24px;
  margin-top: 8px;
}

.nsdc-confirm-txt {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 32px;
}

.nsdc-span-txt {
  color: #3c4852;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.nsdc-sure-btn {
  display: flex;
  width: 180px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #fff;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  border-radius: 8px;
  background: #035642;
}

.nsdc-unsure-btn {
  display: flex;
  width: 180px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #6c757d;
  color: #fff;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
}

.nsdc-mobile-error {
  color: #dc3545;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}

.job-aply-header {
  color: #3c4852;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.job-dtl-txt {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  margin-bottom: 0px;
}

.job-dtl-txt2 {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
}
p[data-f-id="pbf"] {
  opacity: 0;
  display: none;
}
.button-container {
  display: flex;
  flex-wrap: wrap;
}

.button-container button {
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  cursor: pointer;
  width: calc(33.33% - 16px); /* Adjust width as needed */
}

.button-container button.selected {
  background-color: #055646;
  color: #fff;
}

.add-ques-opt {
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  cursor: pointer;
  width: 100%;
}

.add-ques-opt.selected {
  background-color: #055646;
  color: #fff;
}

.add-ques-opt span {
  display: block;
}

.resume-icon {
  min-height: 60%;
  min-width: 60%;
}

.resume-icon-2 {
  min-height: 45%;
  min-width: 45%;
}

.resume-txt {
  display: flex;
  margin-left: 8px;
  flex-direction: column;
}

.resume-wrap {
  display: flex;
  justify-content: space-between;
  width: 88%;
}
.centr-content {
  justify-content: center;
}

.lTag {
  background: linear-gradient(
      0deg,
      rgba(60, 72, 82, 0.25) 0%,
      rgba(60, 72, 82, 0.25) 100%
    ),
    #fff;
}

.resume-heading {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
}

.upload-div-2 {
  display: flex;
  border-radius: 8px;
  border: 1px solid rgba(60, 72, 82, 0.25);
  margin-top: 16px;
}

.upload-div-2.drop-zone:hover {
  cursor: pointer;
}

.resume-flex-wrap {
  display: flex;
  justify-content: space-between;
  width: 98%;
  margin-right: 24px;
}

.as-dl-logo-job {
  height: 32px;
  margin-top: 10px;
  width: auto;
  margin-left: 20px;
  border-radius: 4px;
  border: 1px solid rgba(60, 72, 82, 0.15);
}

@media only screen and (max-width: 440px) {
  .as-dl-logo-job {
    margin-left: 0 !important;
  }
  .resume-icon-2 {
    width: 40px;
  }
}

.as-dl-logo-job-1 {
  height: 24px;
  width: 24px;
  top: 12px;
  position: relative;
  // margin-left: 20px;
}

.as-dl-logo-job-2 {
  height: 100%;
  width: 100%;
  // top: 12px;
  // position: relative;
  // margin-left: 20px;
}

.congrats-div-job-txt {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal !important;
  margin-bottom: 16px;
}

.congrats-div-job-txt-2 {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}

.congrats-div-job-txt-3 {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 4px;
}

.missed-reason-heading {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 16px 0px;
}

.missed-txt-div {
  border-radius: 8px;
  border: 1px solid rgba(60, 72, 82, 0.25);
  background: #fff;
}

.actionRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0.5rem 0;
  gap: 1rem;
}

.installmentRow-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  width: 100%;
}

.installmentRow-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.vertical {
  flex-direction: column;
  padding: 0;
  margin-top: 1rem;
  gap: 0;
  box-shadow: 0px 3px 0px #3c485240 inset;
}

.vertical-override {
  flex-direction: column;
  padding: 0;
  margin-top: 1rem;
  gap: 0;
  box-shadow: 0px 3px 0px #000 inset;
}

.installment-2 {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  gap: 1rem;
  margin: 0;
  flex-direction: row;
}

.amount {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #3c4852;
  margin: 0;
}

.apply-success-btn {
  border-radius: 4px;
  // opacity: 0.75;
  background: #014644;
  color: #fff;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.apply-success-btn-1 {
  border-radius: 4px;
  opacity: 0.75;
  background: #014644;
  color: #fff;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
}

.apply-success-div {
  justify-content: center;
  display: flex;
}

.apply-danger-btn {
  border-radius: 4px;
  // opacity: 0.75;
  background: #dc3545;
  color: #fff;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.resume-upload-img {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 642px;
  height: 351px;
  flex-shrink: 0;
  border-radius: 3px;
  background: rgba(60, 72, 82, 0.15);
}

.resume-upload-txt {
  color: #3c4852;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.congrats-wrap-txt {
  margin-left: 16px;
  margin-right: 24px;
}

.prof-dtl-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.prof-dtl-img-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  // max-width: 250px;
  // max-height: 250px;
}
.prof-dtl-img-2 {
  display: flex;
  justify-content: center;
  max-width: 50px;
  max-height: 50px;
  margin-left: 10px;
  margin-top: 20px;
}

.prof-dtl-img-3 {
  display: flex;
  justify-content: center;
  max-width: 50px;
  max-height: 50px;
  margin-left: 10px;
  margin-top: 5px;
}

.prof-dtl-txt {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.aply-success-txt {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

.selected-jobtype {
  background: linear-gradient(
      0deg,
      rgba(3, 86, 66, 0.15) 0%,
      rgba(3, 86, 66, 0.15) 100%
    ),
    #fff !important;
  box-shadow: 0px 0px 16px 0px rgba(3, 86, 66, 0.25);
  border: 0.75px solid #212a39 40%;
}

.quick-status-dsplay {
  display: flex;
}

.btn-section {
  width: 70%;
  /* background: green; */
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  align-items: center;
}

.btn-section-2 {
  // width: 90%;
  /* background: green; */
  display: flex;
  justify-content: center;
  // padding-right: 30px;
  align-items: center;
}

.btn-section-3 {
  // width: 90%;
  /* background: green; */
  display: flex;
  justify-content: center;
  // padding-right: 30px;
  align-items: center;
  min-height: 48px;
}

.breadCrumbsContainer {
  // margin-top: 50px;
}

.resume-prev h5 {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.larger-img {
  position: absolute; /* Positioned absolutely relative to parent */
  width: 30px; /* Example width larger than parent */
  height: 30px; /* Example height larger than parent */
  transform: translate(-50%, -50%); /* Centers image properly */
}

.revenuemaincontainer {
  margin-top: 15px;
  // margin-left: 20px;
  display: flex;
  // flex-wrap: wrap;
  gap: 24px;
  justify-content: space-between;
}

.job-overview-container h4 {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  color: #3c4852;
  letter-spacing: -0.304px;
}

.job-overview-container h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.76px;
  color: #097252;
}

.job-overview-container {
  width: 25%;
  height: 122px;
  border-radius: 16px;
  background: #fff;
  padding: 16px 24px;
  margin: 0px 8px;
}

.job-overview-container:hover {
  cursor: pointer;
}

.plc-portal-width-inherit {
  width: inherit;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.joblist-maincontainer-2 {
  margin-top: 10px;
  // margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  // justify-content: space-between;
  border-bottom: 0.75px solid #212a39;
}

.job-overview-container-2 {
  min-width: 100px;
  height: 50px;
  // border-radius: 16px;
  // background: #fff;
  // box-shadow: 0px 0px 16px 0px rgba(3, 86, 66, 0.25);
  padding: 16px 24px;
}

.container-selected {
  font-weight: 700;
  color: #055646;
  border-bottom: 5px solid #055646;
}

.job-button-container {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: #3c4852;
}

.some-padding {
  padding: 10px !important;
}

.job-toppoints {
  margin-left: 10px;
  color: #3c4852;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.job-toppoints-2 {
  margin-left: 10px;
  color: #3c4852;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.job-toppoints-3 {
  color: #3c4852;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.job-toppoints-4 {
  // margin-left: 10px;
  color: #3c4852;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.save-job {
  justify-content: space-between;
  display: flex;
  padding-right: 0px;
}

.job-info-ttl {
  // display: inline-flex;
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 0px;
  // border-bottom: 0.75px solid rgba(33, 42, 57, 0.25);
}

.job-quick-info {
  /* display: inline-flex; */
  font-size: 18px;
  color: #212a39;
  line-height: 22px;
  width: 100%;
  // padding-top: 15px;
}

.job-info-ctnr {
  width: 100%;
  min-height: 20px;
  padding: 5px 0 0 25px;
}

.job-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  flex-direction: column;
  color: #3c4852;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 122.222% */
  text-transform: capitalize;
}

.companyTopRow-2 {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // margin-top: 10px !important;
}

.crs-resume-btn {
  border: 1px solid var(--baseColor);
  border-radius: 8px;
  height: 50%;
  width: 40%;
  background: var(--baseColor);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  z-index: 101;
  outline: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  padding: 7px;
}

.crs-resume-btn-2 {
  display: flex;
  justify-content: center;
  border: 1px solid var(--baseColor);
  border-radius: 8px;
  height: 50%;
  width: 40%;
  background: var(--baseColor);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  z-index: 101;
  outline: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  padding: 7px;
}

.crs-resume-btn-2:hover {
  color: #fff !important;
}

.crs-resume-btn-3 {
  display: flex;
  justify-content: center;
  border: 1px solid var(--baseColor);
  border-radius: 8px;
  height: 100%;
  width: 40%;
  background: var(--baseColor);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  z-index: 101;
  outline: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  padding: 7px;
}

.crs-resume-btn-3:hover {
  color: #fff !important;
}

.upload-resume-btn {
  // border: 1px solid var(--baseColor);
  border-radius: 8px;
  background: #3abaa8;
  width: 200px;
  height: 48px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  z-index: 101;
  outline: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  padding: 7px;
}

.labs-main {
  margin-left: 250px;
  font-family: "Source Sans Pro", sans-serif;
}

.jobs-main {
  /* margin-left: 50px; */
  font-family: "Source Sans Pro", sans-serif;
  background: rgba(5, 86, 70, 0.06);
}

.labs-sidebar {
  position: fixed;
  z-index: 2;
  top: 10vh;
}
.jobs-root {
  z-index: 3;
}

.jobs-addquesmodal {
  width: 50% !important;
}

.jobs-grantaccessmodal {
  width: 330px !important;
}

.jobs-editquesmodal {
  width: 50% !important;
}

.progress-container {
  margin-top: 60px;
  width: 50%;
  position: relative;
  margin: auto;
  top: 40px;
  left: 0;
  z-index: 999; /* Ensures it's above other content */
}

.circle-container {
  display: flex;
  justify-content: space-between;
}

.cjr_nav_child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: max-content;
  opacity: 0.5;
}

.cjr_nav_child_active {
  opacity: 1;
}

.progress-nav-header {
  font-weight: 600;
  font-size: 1.4vw;
}

.profile-bar-progress-1 {
  border-radius: 32px;
  background: linear-gradient(
      0deg,
      rgba(60, 72, 82, 0.25) 0%,
      rgba(60, 72, 82, 0.25) 100%
    ),
    #fff;
  height: 9px;
  position: absolute;
  top: 9px;
  // left: calc(24px + 10px);
  width: 100%;
}

.profile-bar-completed-1 {
  border-radius: 32px;
  background: #097252;
  height: 100%;
  min-height: 5px;
}

.profile-bar-inprogress-1 {
  border-radius: 32px;
  height: 100%;
  min-height: 5px;
}

.bar-1 {
  z-index: 5;
  border-radius: 4px;
  height: 6px;
  background-color: var(--themeColor);
  position: absolute;
  top: 9px;
  left: 0;
  transition: width 0.3s ease-in-out;
}

.bar-2 {
  z-index: 5;
  border-radius: 4px;
  height: 6px;
  background-color: red;
  position: relative;
  top: 9px;
  left: 0;
  transition: width 0.3s ease-in-out;
}

.upload-div {
  width: 98%;
  height: 56px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px dashed rgba(60, 72, 82, 0.4);
  background: rgba(9, 114, 82, 0.1);
}

.upload-text {
  color: var(--097252, #097252);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-align: center;
  padding-top: 15px !important;
  height: 56px;
}

.ctc-row {
  width: 100% !important;
}
.radiotext {
  overflow: hidden;
  color: #212a39;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.radiotext-2 {
  overflow: hidden;
  color: #212a39;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 63px;
  height: 16px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
}

a[href*="www.froala.com"] {
  display: none !important;
}

.companyTopRow {
  margin-left: 0px;
  padding-right: 24px;
}

.companyTopRow-3 {
  margin-left: 0px;
  padding-right: 50px;
}

.companyTopRow-4 {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  margin-bottom: 8px;
}

.companyNameRow {
  margin-left: 24px;
}

.companyRow {
  margin-left: 32px;
}

.companyWebsite {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.previewTag {
  color: #3c4852;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 47px;
  // margin-top: 1rem;
}

.previewTag-2 {
  color: #3c4852;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // margin-left: 40px;
  // margin-top: 1rem;
}

.previewTagResponse-2 {
  // margin-top: 1rem;
  color: #3c4852;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 4px;
  max-height: 25px;
  margin-top: 2px;
}

.previewTagResponse {
  // margin-top: 1rem;
  color: #3c4852;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 4px;
  max-height: 25px;
}

.previewTagResponse2 {
  margin-top: 1rem;
  color: #3c4852;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 4px;
}

.skillTagDisplay {
  border-radius: 51px;
  background: rgba(5, 86, 70, 0.15);
  flex-shrink: 0;
  max-height: 25px;
  // width: 125px;
  padding-top: 6px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 26px;
  text-align: center;
}

.resizeHTML {
  margin-left: 45px;
  margin-right: 15px;
}

.ck-editor__editable_inline {
  min-height: 300px;
}

// .addques-newoption {
//   margin-left: 5px !important;
//   margin-top: 10px;
//   color: #3abaa8;
//   font-size: 16px;
//   font-style: normal;
//   font-weight: 600;
//   line-height: normal;
//   display: flex;
//   width: 17%;
//   height: 26px;
//   flex-direction: column;
//   justify-content: center;
//   flex-shrink: 0;
//   background: #fff;
//   border: 1px #fff;
// }

.jobs-sidebar {
  position: fixed;
  z-index: 200;
  margin-top: 16px;
  margin-left: 50px;
  border-radius: 12px !important;
  border: 1px solid rgba(33, 42, 57, 0.15);
  background: #fff;
}

.adques-all {
  border-radius: 8px;
  border: 1px solid rgba(33, 42, 57, 0.15);
  background: #fff;
  // margin-right: 15px;
}

.adques-header {
  border-bottom: 1px solid grey;
  margin-bottom: 32px;
  margin-top: 5px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  /* margin: 5px; */
}

.job-preview-header-2 {
  border-bottom: 0.75px solid #212a39 40%;
  margin-bottom: 32px;
  margin-top: 5px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.adques-footer {
  /* border-bottom: 1px solid grey; */
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  /* margin: 5px; */
}

.addcrit-content {
  margin: 15px;
}

.addcrit-details {
  min-height: 125px;
}

.addcrit-footer {
  margin-top: 50px;
}

.labs-sidebar-elem {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  width: 250px;
  padding: 10px;
  padding-top: 15px;
  text-align: start;
  text-decoration: none;
  cursor: pointer;
}

.labs-sidebar-active {
  border-left: solid 8px;
  background-color: #00000071;
}

.jobs-sidebar-elem {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  /* border-radius: 8px; */
  color: #3c4852;
  width: 250px;
  padding: 10px;
  padding-top: 15px;
  text-align: start;
  text-decoration: none;
  cursor: pointer;
}

.jobs-sidebar-elem-0 {
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 25px;
  /* border-radius: 6px; */
  /* border: 1px solid rgba(33, 42, 57, 0.15); */
  color: #3c4852;
  width: 250px;
  padding: 10px;
  padding-top: 15px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.jobs-sidebar-active {
  /* border-left: solid 8px; */
  color: #fff;
  background-color: #055646;
}

.topbar {
  background-color: #fff;
  height: 8vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
}

.v_application_topbar {
  height: 60px;
  fill: #fff;
  margin-top: 10px;
  margin-left: 50px;
  background-color: #fff;
}

.labs-content {
  margin: 3rem;
}

.jobs-header {
  margin-top: 1rem;
  margin-left: 310px;
  border-radius: 4px;
  background: rgba(33, 42, 57, 0.08);
  height: 40px;
  display: flex;
  align-items: center;
}

.minheader-text1 {
  color: #055646 !important;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 10px;
  margin-top: 5px;
  margin-right: 10px;
}

.previewques {
  margin-left: 10px;
  padding-right: 50px;
}

.apply-sucess-modal {
  max-width: 300px;
}
// .jobs-content {
//   // margin-top: 16px;
//   // margin-left: 10px;
// }

.highlight-name {
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 137.5% */
  margin-top: 15px;
}

.highlight-text {
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  margin-top: 15px;
}

.labs-table-header {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #3c4852;
}

.jobs-table-header {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #3c4852;
  margin-left: 10px;
  /*margin-right: 10px; */
}

.jobs-table-header-2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #3c4852;
  margin-left: 10px;
  /*margin-right: 10px; */
}

.jobs-table-header-3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #3c4852;
  margin-left: 10px;
  /*margin-right: 10px; */
}

.rvm-v1-cont-ctnr-3 {
  padding: 16px 10% !important;
  padding-bottom: 22px;
  background-color: #f2f6f9;
  margin-bottom: 8px;
}

.rvm-v1-cont-ctnr-4 {
  padding: 16px 12% !important;
  padding-bottom: 22px;
  background-color: #f2f6f9;
  margin-bottom: 8px;
}

.rvm-v1-cont-ctnr-2 {
  padding: 30px 12% !important;
  background-color: #f2f6f9;
}

.rvm-v1-cont-ctnr-2.rvm-v1-part-2-otr-ctnr {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.rvm-v1-cont-ctnr-2.rvm-v1-part-2-otr-ctnr-2 {
  padding-bottom: 30px !important;
  padding-top: 16px !important;
}

.rvm-v1-cont-ctnr-3 {
  padding: 0px 10% !important;
  background-color: #f2f6f9;
}

.rvm-v1-cont-ctnr-3.rvm-v1-part-2-otr-ctnr {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.topbar-heading {
  width: 100%;
  overflow: hidden;
  color: #3c4852;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  justify-items: center;
}

.labs-table-btn-1 {
  background: #035642 !important;
  width: 100% !important;
  height: 50px !important;
  border-radius: 8px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #ffffff !important;
  cursor: pointer !important;
}

.labs-table-btn-2 {
  width: 100%;
  height: 50px;
  border: 1px solid #035642;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #035642;
  background-color: #fff;
}

.labs-table-btn-3 {
  width: 100%;
  height: 35px;
  border: 1px solid #035642;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #035642;
  background-color: #fff;
}

.labs-table-btn-4 {
  width: 100%;
  height: 40px;
  border: 1px solid #035642;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  background: #dc3545;
  justify-content: right;
}

.jobs-table-btn-55 {
  background: #035642 !important;
  width: 80% !important;
  height: 50px !important;
  border-radius: 8px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #ffffff !important;
  cursor: pointer !important;
  margin-bottom: 5px;
}

.jobs-table-btn-6 {
  background: #fff !important;
  color: #055646 !important;
  width: 80% !important;
  text-align: center !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
  cursor: pointer !important;
  margin-bottom: 5px;
  border-radius: 8px !important;
  border-color: #055646 !important;
  height: 50px !important;
}

.jobs-table-btn-7 {
  background: #3abaa8 !important;
  width: 80% !important;
  height: 50px !important;
  border-radius: 8px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #ffffff !important;
  cursor: pointer !important;
  margin-bottom: 5px;
}

.jobs-table-btn-8 {
  background: #fff !important;
  width: 80% !important;
  height: 26px !important;
  border-radius: 4px !important;
  border: 1px solid #dc3545 !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #dc3545 !important;
  cursor: pointer !important;
  margin-bottom: 5px;
  padding: 0px !important;
}

.jobs-table-btn-9 {
  background: #3abaa8 !important;
  width: 80% !important;
  height: 26px !important;
  border-radius: 4px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #ffffff !important;
  cursor: pointer !important;
  margin-bottom: 5px;
  padding: 0px !important;
}

.jobs-table-btn-10 {
  background: #035642 !important;
  width: 80% !important;
  height: 26px !important;
  border-radius: 4px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #ffffff !important;
  cursor: pointer !important;
  margin-bottom: 5px;
  padding: 0px !important;
}

.job-fltr-modal {
  overflow: scroll;
  height: 400px;
}

.labs-edit-btn {
  height: auto;
  width: auto;
  padding: 6px;
  font-size: 12px;
  background-color: #198dd5;
  border-radius: 4px;
}

.labs-create-new-form {
  padding: 10px;
}

.labs-create-new-form > div {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #212a39;
  padding-bottom: 8px;
}

.labs-create-new-form-text_input {
  width: 100%;
  height: 48px;
  left: 406px;
  top: 216px;
  background: #ffffff;
  border: 1px solid rgba(60, 72, 82, 0.25);
  border-radius: 8px;
  font-size: 14px;
  padding: 5px;
}

.labs-create-new-form-date_input {
  width: 70%;
  height: 48px;
  left: 406px;
  top: 216px;
  margin-left: 10px;
  background: #ffffff;
  border: 1px solid rgba(60, 72, 82, 0.25);
  border-radius: 8px;
  font-size: 14px;
  padding: 5px;
}

.labs-disabled {
  background: rgba(60, 72, 82, 0.08);
}

.labs-create-new-header {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-transform: capitalize;
  color: #3c4852;
}

.labs-add-student-card-main {
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000005b;
}

.labs-add-student-card-content {
  width: 30%;
  background-color: #fff;

  padding: 1rem;
  border-radius: 8px;
}

.labs-add-student-card-header {
  display: flex;
  justify-content: space-between;
}

.labs-add-student-card-header > span {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #212a39;
}

.jobs-border {
  border-bottom: solid;
}

.labs-table-req-body > td {
  padding: 10px;
  border-bottom: solid 1px;
}

.labs-Active,
.labs-Inactive {
  background-color: rgb(43, 173, 17);
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
}

.labs-Inactive {
  background-color: rgb(207, 16, 16);
}

.labs-note {
  font-size: 12px;
  color: #000000a1;
}

.lab-capital {
  text-transform: capitalize;
}

.toggle-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 24px;
}
.as-dl-sidebar {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  background-color: #055646;
  color: var(--white-color);
  overflow-x: hidden;
  z-index: 1;
}

.as-dl-sidebar-lg {
  height: 80px;
}

.as-dl-logo {
  height: 32px;
  margin-top: 30px;
  width: auto;
  margin-left: 40px;
}

.btch-search {
  display: flex !important;
  justify-content: flex-end;
  width: fit-content;
  margin: unset;
}

.btch-dt-chk {
  font-size: 16px !important;
  font-weight: 600 !important;
  min-width: 50px !important;
  text-align: center !important;
}

.btch-dt-chk-2 {
  font-size: 16px !important;
  font-weight: 600 !important;
  text-align: center !important;
}

.btch-dt-all {
  margin-left: 0;
  transform: scale(2) !important;
}

.btch-dt-all-unsub {
  margin-left: 0;
  transform: scale(2) !important;
}

.job-dt-inp {
  margin-left: 10px !important;
  transform: scale(1.5) !important;
}

.resume-link {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s; /* Smooth transition for color change */
  color: #0ba5ec;
}

.resume-link:hover {
  color: #055646; /* Change color on hover */
}

.job-dt-tog-btn1 {
  border: none;
  display: flex;
  width: 100%;
  max-height: 50px;
  border-radius: 10px;
  outline: none;
  font-size: 18px;
  font-weight: 600;
  /* color:#055646; */
  margin-top: 10px;
}

.btch-toggle {
  color: #fff;
  background-color: #055646;
  border-radius: 10px;
  width: 50%;
  display: block;
  padding: 10px;
}
.btch-toggle-unchecked {
  color: #055646;
  width: 50%;
  display: block;
  padding: 10px;
}

.btch-dt-inp {
  margin-left: 15px;
  /* transform: scale(2) !important; */
}

.btch-dt-inp-unsub {
  margin-left: 0;
  transform: scale(2) !important;
}
.btch-dt-th-2 {
  font-size: 16px !important;
  font-weight: 600 !important;
  max-width: 25px !important;
  text-align: center !important;
}

.btch-dt-th-3 {
  font-size: 16px !important;
  font-weight: 600 !important;
  max-width: 15px !important;
  text-align: center !important;
}

.btch-dt-th {
  font-size: 16px !important;
  font-weight: 600 !important;
  min-width: 200px !important;
  text-align: center !important;
}
.btch-dt-td {
  font-size: 13px !important;
  font-weight: 600 !important;
  min-width: 175px !important;
  text-align: center !important;
}

.btch-dt-td-4 {
  font-size: 13px !important;
  font-weight: 600 !important;
  min-width: 350px !important;
  text-align: center !important;
  padding: 5px !important;
}

.btch-dt-td-3 {
  font-size: 13px !important;
  font-weight: 600 !important;
  min-width: 125px !important;
  text-align: center !important;
}

.btch-dt-td-2 {
  font-size: 13px !important;
  font-weight: 600 !important;
  min-width: 525px !important;
  text-align: center !important;
}
.custom-unsub-text {
  display: inline-block;
  background-color: #f1b605;
  border: 1px solid #ffc107;
  color: #fff;
  padding: 8px 16px;
  text-align: center;
}

.custom-sub-text {
  display: inline-block;
  background-color: #35a44f;
  border: 1px solid #35a44f;
  color: #fff;
  padding: 8px 16px;
  text-align: center;
}

.btch-pending {
  display: "flex";
  /* color:#FFF; */
  /* background-color: #055646; */
  border-radius: 1px;
  width: 30%;
  display: block;
  padding: 1px;
  justify-content: space-between !important;
}
.job-toggle {
  color: #fff;
  background-color: #58944c;
  border-radius: 10px;
  width: 50%;
  display: block;
  /* padding: 6px; */
}
.job-toggle-unchecked {
  color: #055646;
  width: 50%;
  display: block;
  /* padding: 16px; */
}
.job-tog-btn1 {
  border: none;
  display: flex;
  width: 100%;
  height: 30px;
  border-radius: 10px;
  outline: none;
  font-size: 18px;
  font-weight: 600;
  /* color:#055646; */
  /* margin-top: 10px; */
}

#batch-data-table .MuiTableCell-root {
  border-right: 1px solid rgba(224, 224, 224);
  /* width: 100%; */
}

.sticky-first {
  position: sticky;
  left: 0;
  z-index: 100;
  background: #f1f2f5;
  border-right: none !important;
}

.sticky-second {
  position: sticky;
  left: 50px;
  z-index: 100;
  background: #f1f2f5;
  border-right: none !important;
}

.sticky-third {
  position: sticky;
  left: 225px;
  z-index: 100;
  background: #f1f2f5;
  border-right: none !important;
}

.sticky-fourth {
  position: sticky;
  left: 400px;
  z-index: 100;
  background: #f1f2f5;
}

.sticky-header-both-first {
  position: sticky;
  left: 50px;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  border-right: none !important;
}

.sticky-header-both-second {
  position: sticky;
  left: 225px;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  border-right: none !important;
}

.sticky-header-both-third {
  position: sticky;
  left: 400px;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  border-right: none !important;
}

.sticky-header-both-fourth {
  position: sticky;
  left: 400px;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  border-right: none !important;
}

.sticky-header-both-zero {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
}

.sticky-header-both-first-2 {
  position: sticky;
  left: 10px;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  border-right: none !important;
}

.sticky-header-both-second-2 {
  position: sticky;
  left: 225px;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  border-right: none !important;
}

.sticky-header-both-third-2 {
  position: sticky;
  left: 400px;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  border-right: none !important;
}

.sticky-header-both-fourth-2 {
  position: sticky;
  left: 400px;
  top: 0;
  z-index: 300;
  background: #e8ecf1;
  border-right: none !important;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 200;
  background: #e8ecf1;
}

.batchDataExtractionNew .MuiAutocomplete-root {
  background: #055646;
  margin: 60px auto;
  min-width: 95%;
}

.batchDataExtractionNew .MuiInputLabel-root,
.batchDataExtractionNew .MuiSvgIcon-root,
.batchDataExtractionNew .MuiInputBase-root {
  color: white !important;
}

.batchDataExtractionNew .MuiInputLabel-root.Mui-focused,
.batchDataExtractionNew .MuiInputBase-root.Mui-focused,
.batchDataExtractionNew .MuiOutlinedInput-notchedOutline.Mui-focused {
  color: white !important;
}

.batchDataExtractionNew .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.batchDataExtractionNew .MuiOutlinedInput-notchedOutline.Mui-focused {
  border-color: white !important;
}

.ui.green.button {
  float: right !important;
}

.batchDataExtractionNew .MuiInputLabel-root.Mui-disabled,
.batchDataExtractionNew .Mui-disabled .MuiSvgIcon-root,
.batchDataExtractionNew .MuiInputBase-root.Mui-disabled {
  color: rgb(165, 165, 165) !important;
}

.batchDataExtractionNew .Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: rgb(165, 165, 165) !important;
}

.btch-dt-foot-main {
  display: flex;
  margin-top: 20px;
  margin-left: 250px;
  padding: 10px;
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  text-align: center;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  z-index: 300;
  background-color: #3c4852;
  position: fixed;
}
.btch-dt-foot-cnt {
  color: #fff;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-top: 30px;
  margin-left: 100px;
}

.btch-dt-foot-btn {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 350px;
  height: 60px;
  /* flex-direction: column; */
  justify-content: center;
  flex-shrink: 0;
  margin-right: 330px;
}

.btch-dt-foot-btn1 {
  width: 200px;
  /* height: 100px; */
  border-radius: 10px;
  outline: none;
  font-size: 18px;
  font-weight: 600;
  color: #055646;
  margin-top: 10px;
}

.btch-dt-foot-btn2 {
  width: 300px !important;
  /* height: 100px; */
  border-radius: 10px !important;
  outline: none !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #055646 !important;
  margin-top: 10px !important;
}

.job-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  flex-direction: column;
}

.job-not-found-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 100px;
  flex-direction: column;
}

.modal-header {
  color: #212a39;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  font-weight: 800;
}

.apply-job .modal-title {
  color: #212a39;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
}

.apply-body {
  margin-left: 13px;
}

.modal-title {
  color: #212a39;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.modal-title-2 {
  color: #3c4852;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.job-dtl {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}
.dropdown-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
}

.rvm-v1-part-2-certi-logo-1 {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100%;
  border: 1px solid rgb(221, 221, 221);
}
