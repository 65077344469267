.ics_container_main {
  margin: 1rem;
  background: #eff4f5;
  border-radius: 5px;
  border: solid 1px;
  border-color: #5e5c5c3f;
}

.ics_top_tabs {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 3rem;
}

.ics_tabs {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 16px;
  /* border-bottom: solid 1px #5e5c5c3f; */
  color: #666;
}

.ics_active_tab {
  color: #035642;
  border-bottom: solid 2px;
}

.ics_accept_btn {
  margin-right: 1rem;
  border: none;
  background: #035642;
  border-radius: 5px;
  padding: 11px 31px;
  color: #fff;
  width: auto;
  min-width: 166px;
  font-weight: 600;
}

.ics_acc_btn_2 {
  height: 32px;
  padding: 6px 13px;
  min-width: 100px;
}

.ics_fill_containers {
  padding: 6px 30px;
}

.ics_acc_contain {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.ics_accept_btn:disabled {
  background: #035642;
  opacity: 0.5;
}

.ics_red {
  background-color: #dc3545;
}

.ics_decline_head {
  color: #a09fa0;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.ics_decline_sub_head {
  color: #dc3545;
  text-align: center;
  font-size: 22px;
  margin-top: 1rem;
}

.ics_f18 {
  font-size: 18px;
}

.ics_ques_head {
  width: 90%;
  margin-top: 40px;
  color: #212a39;
  font-weight: 700;
  font-size: 20px;
}

.ics_textarea {
  resize: none;
  width: 90%;
  border-radius: 5px;
  min-height: 150px;
  margin-top: 0.8rem;
  padding: 1rem;
  overflow-wrap: break-word;
}

.ics_decline_btns_container {
  margin-top: 4rem;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.ics_decline_btn1,
.ics_decline_btn2 {
  border: none;
  min-width: 100px;
  padding: 11px 48px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-weight: 700;
}

.ics_decline_btn2 {
  background-color: #dc3545;
}

.ics_decline_remark {
  background: rgba(220, 53, 69, 0.05);
  min-height: 150px;
  width: 90%;
  padding: 1rem;
  color: #dc3545;
  margin-top: 1rem;
  overflow-wrap: break-word;
}

.ics_ac_main {
  height: 85vh;
  padding: 0.7rem 2rem;
  overflow-y: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ics_ac_main::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.ics_ac_main {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ics_ac_single {
  background-color: #fff;
  padding: 1rem;
  border-radius: 16px;
  margin: 1rem;
  padding: 24px;
}

.ics_ac_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.ics_ac_down_img {
  width: 12px;
}

.ics_ac_crs_header {
  color: #212a39;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
}

.ics_ac_up_img {
  rotate: 180deg;
}

.ics_ac_catgory_div_main {
  border: 1px solid rgba(5, 86, 70, 0.1);
  border-radius: 8px;
  margin: 1rem 0px;
  padding: 1rem;
}

.ics_ac_catgory_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.ics_ac_cat_sub {
  color: #035642;
  font-weight: 600;
}

.ics_ac_cat_head {
  color: #212a39;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}

.ics_ac_agree_main {
  background: #eff4f5;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem;
  margin: 1rem 0px;
  border-radius: 8px;
}

.ics_ac_agree_actions {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.ics_ac_decline_main {
  background-color: #fdf5f5;
  color: #dc3545;
}

.ics_no_application {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ics_na_head {
  color: #212a39;
  text-align: center;
  /* font-family: "Source Sans 3"; */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ics_na_desc {
  color: #212a39;
  text-align: center;
  /* font-family: "Source Sans 3"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ics_view_head {
  color: #212a39;

  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ics_view_sub {
  color: #035642;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.ics_view_heads {
  margin-top: 1rem;
}

.ics_view_sub2 {
  color: #575e64;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.ics_red_text {
  color: #dc3545;
}

.ics_background_grey {
  background-color: #6c757d;
}

.ics_review_sub {
  border: 1px solid rgba(5, 86, 70, 0.5);
  color: #035642;
  min-height: 10vh;
  padding: 1rem;
  margin-top: 1rem;
  overflow-wrap: break-word;
}

.ics_review_header {
  color: #a09fa0;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
  text-transform: uppercase;
}

.ics_review_sub_head {
  color: #3c4852;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-top: 2rem;

  line-height: normal;
}

.ics_loader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0000007e;
  z-index: 9999;
}

.ics_modal_agreement {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  transform: translate(-50%, -50%) !important;
}
