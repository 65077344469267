.levelContainer {
  margin-right: 48px;

  .profileComplete {
    width: 100%;
  }

  .rvm-rsm-start-crs-btn {
    font-size: 16px;
    font-weight: 600;
    float: right;
    padding: 8px 18px !important;
    border: 1px solid var(--themeColor);
    background: white;
    color: var(--themeColor);
    &:hover {
      background: var(--themeColor);
      color: var(--textColor);
    }
  }

  @media only screen and (max-width: 1000px) {
    .rvm-rsm-start-crs-btn {
      width: 100%;
      margin-top: 20px;
    }
  }

  @media (min-width: 911px) {
    & {
      margin-left: 298px;
      margin-top: 48px;
    }
  }

  // .MuiBreadcrumbs-root {
  // }
  .MuiBreadcrumbs-ol {
    flex-wrap: nowrap;
    overflow-x: auto;
    @media (max-width: 768px) {
      & {
        padding-bottom: 10px;
      }
    }
  }

  .MuiBreadcrumbs-li {
    white-space: nowrap;
    cursor: pointer;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    a {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;

      color: var(--themeColor);
    }
    p {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;

      color: #3c4852;
      &:hover {
        color: var(--themeColor);
      }
    }
  }
  .empty {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 0;
  }
  .emptyState {
    font-family: Source Sans Pro;
    font-size: 16px;
    line-height: 20px;

    color: #000000;

    opacity: 0.75;
  }
  .row {
    font-family: Source Sans Pro;
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 1rem;
    align-items: center;
    margin: 16px auto;
    .entry {
      height: 104px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(207, 224, 235, 0.5);
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      transition: all 0.1s ease-in;
      align-items: center;
      padding-right: 24px;
      // animation-timing-function: ease-in;
      &:hover {
        transform: translateX(20px);
      }
      @media (-moz-touch-enabled: 1),
        (pointer: coarse),
        (hover: none),
        (hover: on-demand) {
        &:hover {
          transform: unset !important;
        }
      }
      .left {
        border-radius: 4px;
        width: 8px;
        height: 104px;
        min-width: 8px;
      }

      .progressBar {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        gap: 16px;
        display: flex;
        justify-content: flex-start;
      }

      .levelProgress {
        width: 80%;

        border-radius: 0px;
        height: 4px;

        @media (max-width: 768px) {
          width: 80%;
        }

        @media (max-width: 468px) {
          width: 60%;
        }
      }

      .levelProgress::-webkit-progress-value {
        background-color: #048e6c;
      }

      .levelProgress::-webkit-progress-bar {
        background-color: #d9d9d9;
      }

      .progressBar_desc {
        color: #000;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        gap: 8px;
      }

      .thumbnailpic {
        min-width: 72px;
        margin: auto 1rem;
      }
      .label {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #212a39;
        margin: 0 1rem 0 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        @media (max-width: 768px) {
          & {
            margin-right: auto;
          }
        }
      }
      .link {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        color: #055646;
        margin: auto 0% auto auto;
        display: flex;
        // align-items: baseline;
        svg {
          margin-top: 2px;
        }

        @media (max-width: 768px) {
          & {
            display: none;
          }
        }
      }
    }
    .entry1 .left {
      background: #6a87c4;
    }
    .entry2 .left {
      background: #47c3cf;
    }
    .entry3 .left {
      background: #e4a69f;
    }
    .entry4 .left {
      background: #68c29d;
    }
  }
}

@media only screen and (max-width: 768px) {
  .levelContainer {
    margin: 12px;
  }
}
