.codingAssignmentScss {
  overflow: hidden;
  padding: 0 20px 32px 20px;

  @media screen and (min-width: 1100px) {
    margin: 0 auto;
    width: 90%;
    padding: 0 0 32px 0;
  }

  .assignmentName {
    color: #212a39;
    font-size: 20px;
    font-weight: 600;
    margin-top: 16px;
    margin-bottom: 8px;
  }

  .line {
    border-bottom: 0.75px solid rgba(33, 42, 57, 0.25);
  }

  .body {
    border-radius: 16px;
    border: 1px solid rgba(60, 72, 82, 0.24);
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(207, 224, 235, 0.5);
    margin: 24px 0 16px;
    padding: 16px;
  }

  .body-title {
    color: #212a39;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .instructions-cont {
    border-radius: 16px;
    background: #fdf4e7;
    box-shadow: 0px 4px 8px 0px rgba(207, 224, 235, 0.5);
    padding: 16px;
  }

  .instructions {
    color: #212a39;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .instructions-text {
    list-style-type: inherit !important;
  }

  .sample-cont {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space: pre-line;
  }

  .sample {
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }

  .sample-title {
    border-radius: 4px;
    background: rgba(33, 42, 57, 0.15);
    height: 48px;
    color: #212a39;
    font-size: 16px;
    font-weight: 600;
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .sample-body {
    display: flex;
  }

  .sample-body-child {
    border-radius: 4px;
    margin: 16px 0px;
    padding: 16px 18px;
    border: 1px solid rgba(60, 72, 82, 0.24);
    box-shadow: 0px 4px 8px 0px rgba(207, 224, 235, 0.5);
  }

  .sample-body-heading {
    color: #212a39;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
  }

  .sample-body-text {
    color: #212a39;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .reset-lang {
    display: flex;
    justify-content: space-between;
    background: #1e1e1e;
    padding: 16px 20px;
    // border-top-left-radius: 8px;
    // border-top-right-radius: 8px;
  }

  .reset-btn {
    border-radius: 4px;
    padding: 4px 12px;
    background: #1e1e1e;
    color: white;
    border: 0.75px solid white;
    font-size: 16px;
  }

  .lang-change {
    border-radius: 4px;
    padding: 4px 12px;
    background: #1e1e1e;
    color: white;
    border: 0.75px solid white;
    font-size: 16px;
    cursor: pointer;
  }

  .overflow-guard,
  .monaco-editor {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .rule {
    color: rgba(33, 42, 57, 0.75);
    font-size: 14px;
    font-weight: 600;
    margin: 16px 0 8px;
  }

  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
  }

  .custom-input-cont {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .custom-input {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #212a39;
    font-size: 16px;
    font-weight: 600;
  }

  .test-run-button {
    width: 200px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #035642;
    color: #035642;
    background: white;
    font-size: 16px;
    font-weight: 600;
  }

  .submit-button {
    width: 200px;
    height: 48px;
    border-radius: 5px;
    color: white;
    background: #035642;
    font-size: 16px;
    font-weight: 600;
    margin-left: 16px;
  }

  .as-tt-msg {
    width: 100%;
    padding: 10px;
    color: #ffffff;
    background-color: #357de9;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
  }

  .as-dl-msg {
    width: 100%;
    padding: 10px;
    color: #ffffff;
    background-color: #e94b35;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
  }

  .as-tt-msg-2 {
    width: 100%;
    padding: 10px;
    color: #ffffff;
    background-color: red;
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
  }

  .content {
    flex-grow: 1;
    overflow: auto;
  }

  .resizable-div {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #d9edf7;
    border-top: 2px solid #007bff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .resize-handle {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 10px;
    cursor: ns-resize;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .custom-input-container {
    padding: 16px;
    width: 100%;
    box-shadow: 0px 4px 8px 0px rgba(207, 224, 235, 0.5);
    height: fit-content;
    border-radius: 4px;
    background: #f4f7fa;
    margin-bottom: 24px;
  }

  .custom-input-header {
    color: #212a39;
    font-size: 20px;
    font-weight: 600;
  }

  .custom-input-field {
    border: none;
    margin-top: 12px;
    background: #f4f7fa;
    min-height: 96px;
    overflow-y: auto;
    width: 100%;
  }

  .custom-input-run {
    border-radius: 5px;
    background: #035642;
    padding: 12px 36px;
    color: white;
    cursor: pointer;
  }

  .disable {
    opacity: 0.5;
  }

  .force-zeroed {
  }

  .submitted-cont {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(207, 224, 235, 0.5);
    padding: 16px;
  }

  .submitted-title {
    color: #3c4852;
    font-size: 16px;
    line-height: 20px;
  }

  .submitted-code-time {
    border-radius: 8px;
    border: 1px solid rgba(3, 86, 66, 0.24);
    background: rgba(3, 86, 66, 0.04);
    margin: 16px 0;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .submitted-time {
    display: flex;
    align-items: center;
  }

  .submitted-data-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .resubmit {
    padding: 8px 12px;
    border-radius: 4px;
    background: #035642;
    cursor: pointer;
    color: white;
    margin-top: 8px;
  }

  .view-submission {
    border-radius: 4px;
    background: linear-gradient(
        0deg,
        rgba(3, 86, 66, 0.16) 0%,
        rgba(3, 86, 66, 0.16) 100%
      ),
      #fff;
    color: #035642;
    cursor: pointer;
    padding: 8px 12px;
  }

  .submitted-data-action-text {
    color: #3c4852;
    font-size: 14px;
    line-height: 20px;
  }

  .reviewed-cont {
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(207, 224, 235, 0.5);
    padding: 16px;
    border: 1px solid rgba(60, 72, 82, 0.24);
  }

  .reviewed-cont-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .reviewed-cont-text {
    color: #3c4852;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .reviewed-cont-status {
    border-radius: 20px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
  }

  .reviewed-cont-marks {
    display: flex;
    align-items: center;
  }

  .reviewed-cont-marks-text {
    color: rgba(33, 42, 57, 0.8);
    font-size: 16px;
    line-height: 24px;
  }

  .reviewed-cont-marks-mark {
    color: #28a745;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-left: 4px;
  }

  .reviewed-cont-instrutor-remarks-header {
    color: #3c4852;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin: 12px 0 4px 0;
  }

  .reviewed-cont-instrutor-remarks {
    color: #3c4852;
    font-size: 14px;
    line-height: 20px;
  }

  .result-cont {
    padding: 16px 24px;
    border-radius: 16px;
    border: 1px solid rgba(60, 72, 82, 0.24);
  }

  .test-cases-cont {
    margin-bottom: 16px;
    display: flex;
  }

  .test-case-no {
    padding: 12px 8px;
    width: 100px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
  }

  .test-case-no-selected {
    background: rgba(0, 0, 0, 0.08);
  }

  .test-case-no-failed {
    color: rgb(229, 30, 30) !important;
  }

  .test-case-no-passed {
    color: #28a745 !important;
  }

  .test-case-no-selected-failed {
    border-bottom: 2px solid #dc3545;
  }

  .test-case-no-selected-passed {
    border-bottom: 2px solid #28a745;
  }

  .test-case-details-cont {
    display: flex;
    margin-bottom: 16px;
  }

  .test-case-details-cont-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 160px;
  }

  .test-case-details-cont-header {
    color: #3c4852;
    font-size: 16px;
    font-weight: 700;
  }

  .test-case-details-cont-text {
    color: rgba(60, 72, 82, 0.8);
    font-size: 14px;
    font-weight: 600;
  }

  .test-case-details-more-cont {
    margin-top: 16px;
  }

  .test-case-details-more-cont-item {
    margin-bottom: 16px;
  }

  .test-case-details-more-output-cont {
    display: flex;
    justify-content: space-between;
  }

  .test-case-details-more-output-cont > div {
    width: 50%;
  }

  .nonDesktop {
    display: none;
  }
  .desktop {
    display: block;
  }

  @media only screen and (max-width: 1080px) {
    .nonDesktop {
      display: block !important;
    }
    .desktop {
      display: none !important;
    }
  }

  .desktop-alert {
    color: #a36809;
    font-size: 16px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e9940d;
    background: rgba(233, 148, 13, 0.08);
  }

  .desktop-alert-title {
    font-weight: 600;
  }

  .desktop-alert-body {
    line-height: 24px; /* 150% */
  }
}
