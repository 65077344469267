.upload-div {
  width: 98%;
  height: 56px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px dashed rgba(60, 72, 82, 0.4);
  background: rgba(9, 114, 82, 0.1);
}

.resume-div {
  width: 98%;
  max-height: 80px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid rgba(60, 72, 82, 0.4);
  background: rgba(235, 238, 237, 0.1);
}

.resume-div-2 {
  width: 98%;
  /* height: 56px; */
  padding-top: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(60, 72, 82, 0.4);
  background: rgba(235, 238, 237, 0.1);
}

.resume-div-2:hover {
  cursor: pointer;
}

.resume-div-2.selected {
  background-color: #e2e9e8;
  /* color: #fff; */
}

.resume-prev .modal-header {
  width: 100%;
}

.resume-dsply-modal .modal-dialog {
  justify-content: center;
  max-width: none;
}
.profile-job {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
}

.job-display-profile {
  border-bottom: 1px solid rgba(33, 42, 57, 0.15);
}

.job-dtl-heading {
  margin-top: 15px;
}

.profile-job-dsc {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
}

.profile-job-selected {
  color: #055646;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 160% */
  border-bottom: 5px solid #055646;
}

.profile-preview-name {
  padding: 10px;
  font-size: 24px;
  color: #3c4852;
}

.profile-preview-fields {
  padding-top: 5px;
  color: #3c4852;
}

.upload-text {
  color: var(--097252, #097252);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-align: center;
  padding-top: 15px !important;
  height: 56px;
}

.profile-bar-2 {
  a {
    text-decoration: none;
    color: black;
  }
}

.profile-box {
  border-radius: 3px;
  border: 1px solid rgba(33, 42, 57, 0.25);
  background: rgba(60, 72, 82, 0.1);
}

.profile-heading {
  color: #212a39;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}

.profile-preview-heading {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.profile-preview-heading-2 {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 20px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profile-preview-heading-3 {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: normal !important;
  line-height: normal;
}

.profile-preview-detail {
  color: #212a39;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ignore-row-margin {
  margin-left: 0px !important;
}

.img-container-2 {
  position: relative;
  width: 100%;
  height: 100%;
}

.full-space-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 70%;
  height: 70%;
  /* overflow: hidden; */
}
@media only screen and (max-width: 440px) {
  .addMTonPhone {
    margin-top: 1.5rem !important;
  }
  .UID_pp {
    font-size: 16px;
    font-weight: bold;
  }
}
.profile-img_pp {
  width: 150px;
  height: auto;
  border-radius: 50%;
}

.main-content-width {
  max-width: 100% !important;
}

.addcrit-footer {
  margin-top: 50px;
}

.adques-footer {
  /* border-bottom: 1px solid grey; */
  margin-bottom: 10px;
  margin-top: 5px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  /* margin: 5px; */
}

.jobs-table-header-2 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #3c4852;
  margin-left: 10px;
  /*margin-right: 10px; */
}

.jobs-table-btn-5 {
  background: #035642 !important;
  width: 100% !important;
  height: 50px !important;
  border-radius: 8px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #ffffff !important;
  cursor: pointer !important;
  margin-bottom: 5px;
  font-family: "Source Sans Pro";
}

.jobs-viewjob-btn {
  background: #035642 !important;
  width: 100% !important;
  height: 50px !important;
  border-radius: 8px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 18px !important;
  color: #ffffff !important;
  cursor: pointer !important;
  margin-bottom: 5px;
  font-family: "Source Sans Pro";
}

.jobs-table-btn-66 {
  background: #035642 !important;
  width: 100% !important;
  height: 40px !important;
  border-radius: 8px !important;
  border: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #ffffff !important;
  cursor: pointer !important;
  margin-bottom: 5px;
  min-width: 100px !important;
  font-family: "Source Sans Pro";
}

.labs-table-btn-2 {
  width: 100%;
  height: 50px;
  border: 1px solid #035642;
  border-radius: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #035642;
  background-color: #fff;
  font-family: "Source Sans Pro";
}

.addques-newoption {
  margin-left: 5px !important;
  margin-top: 10px;
  color: #3abaa8;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  width: 30%;
  height: 26px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  background: #fff;
  border: 1px #fff;
}

.valid {
  border-color: #32e650;
}

.invalid {
  border-color: crimson;
}

.prof-remind-header {
  display: flex;
  justify-content: end;
  padding-right: 10px;
  padding-top: 10px;
}

.levelContainer .modal-body {
  padding-top: none;
  padding-right: 20px;
}

.profile-prev-job-exp-heading {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 160% */
}
