.previewdivmain {
  max-width: 496px;
  max-height: 396px;
  width: 100%;
  height: 396px;
  background: #ffffff;
  box-shadow: 0px 0px 12px rgba(60, 72, 82, 0.15);
  border-radius: 8px;
  display: flex;
  justify-content: center;
}

.preview_colorfirst {
  background: linear-gradient(90.02deg, #0f9994 6.45%, #255ca8 102.22%);
  box-shadow: 0px 0px 12px rgba(60, 72, 82, 0.15);
  border-radius: 8px;
  height: 148px;
  position: absolute;
  padding: 20px;
}

.previewimportimg {
  background: linear-gradient(
      0deg,
      rgba(60, 72, 82, 0.08),
      rgba(60, 72, 82, 0.08)
    ),
    #ffffff;
  border-radius: 8px;
  width: 460px;
  height: 240px;
  margin: 0 auto;
}

.previewcolor_heading {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  /* line-height: 56px; */
  color: rgba(60, 72, 82, 0.75);
  text-transform: capitalize;
  margin: 0px;
}

.previewcolor_subheading {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* line-height: 56px; */
  text-transform: capitalize;
  color: rgba(60, 72, 82, 0.75);
  margin: 0px;
}
