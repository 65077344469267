.login__new_parent_1 {
  :global(.Mui-focused) {
    color: #048e6c !important;
  }
  // :global(.MuiOutlinedInput-notchedOutline) {
  //     border-color: #048E6C !important;
  // }
  :global(.Mui-focused) {
    fieldset {
      border-color: #048e6c !important;
    }
    input {
      color: rgb(35, 35, 35);
    }
  }
}
