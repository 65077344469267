:root {
  --sidebar-width: 216px;
  --SourceSansPro: "Source Sans Pro", sans-serif;
  --baseColor: #055646;
  --btnPrimaryColor: #055646;
  --btnSelectedStateColor: #022a22;
  --btnOnHover: #055646;
  /* --themeColor: #035642; */
  /* --ctaColor: #022a22; */
  --themeColor: #3c4852;
  --ctaColor: #035642;
  --textColor: #fff;
}

.btn-primary {
  background-color: var(--ctaColor) !important;
  border-color: var(--ctaColor) !important;
}

.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--ctaColor);
  border-color: var(--ctaColor);
}

body {
  font-family: "Source Sans Pro";
  margin: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #71748d;
  background-color: #f2f6f9;
  -webkit-font-smoothing: antialiased;
}

.body::-webkit-scrollbar-thumb {
  background-color: #212a39;
  display: block;
}
.modal-open {
  padding: 0 !important;
}
.body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.center-all {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
a:hover {
  text-decoration: none;
}
.full-header {
  width: 100% !important;
  left: 0 !important;
}

::placeholder {
  color: rgba(33, 42, 57, 0.25);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(33, 42, 57, 0.25);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(33, 42, 57, 0.25);
}

/*-----------------------
  Top Header
  -------------------------*/

.home-dashboard-header {
  /* display: flex; */
  /* align-items: center; */
  /* background: red; */
  /* height: 100px; */
}
.home-dashboard-header .header-content {
  display: flex;
  /* border-bottom: 0.5px solid rgba(33, 42, 57, 0.25); */
  width: 100%;
  /* margin-left: -16px; */
  /* background: red; */
  /* /* max-width: 880px;  /*
  /* margin-left: 10%; */
}
.home-dashboard-header .back-blue-icon {
  margin-right: 8px;
  margin-top: 2px;
}
.home-dashboard-header .back-btn {
  cursor: pointer;
}
.home-dashboard-header .fixed-header {
  position: fixed;
  top: 0;
  width: calc(100% - 250px);
  /* left: 8px; */
  /* right: 16px; */
  /* /* max-width: 880px;  /*
  left: calc(250px);
  /* margin: 0 auto; */
  z-index: 630;
  right: 0;
}

.home-dashboard-header .fixed-header-2 {
  position: fixed;
  top: 0;
  /* width: calc(100% - 250px); */
  /* left: 8px; */
  /* right: 16px; */
  /* /* max-width: 880px;  /*
  left: 0px;
  /* margin: 0 auto; */
  z-index: 630;
  right: 0px;
}
.home-dashboard-header .fixed-gdtl-header {
  position: fixed;
  top: 0;
  width: calc(100% - 250px);
  /* left: 8px; */
  /* right: 16px; */
  /* /* max-width: 880px;  /*
  left: calc(300px);
  /* margin: 0 auto; */
  z-index: 630;
  right: 0;
}
.ex-hr {
  /* max-width: 880px;  */
  margin: 0 auto;
  margin-bottom: 8px;
}
.home-custom-align {
  /* padding: 0; */
  /* padding-left: 5px; */
  /* padding-right: 5px; */
  width: 100%;
  /* display: flex; */
  /* background: red; */
  /* /* max-width: 880px;  /*
  /* margin:0; */
  /* margin: */
}

.home-dashboard-header .nav-bar {
  padding: 0px;
  /* /* max-width: 880px;  /*
  display: flex;
  /* padding-left: 16px; */
  justify-content: center;
  border-bottom: 0.5px solid rgba(33, 42, 57, 0.25);
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  /* background: #F3F7FA; */
  /* width: calc(100% - 216px); */
  /* width:100%; */
  /* max-width:100%!important; */
  margin: 0 !important;
  /* -webkit-box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.1); */
  /* box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.1); */
  /* -webkit-transition: all 0.3s ease; */
  height: 60px;
  display: flex;
  /* padding-left: 16px; */
  padding-right: 16px;
}

.home-dashboard-header .welcome-msg {
  display: inline-block;
  font-size: 23px;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(33, 42, 57, 0.5);
  margin: 0;
  padding: 0;
  vertical-align: 10%;
  padding-left: 15px;
}

.navbar-brand {
  display: inline-block;
  margin-right: 1rem;
  line-height: inherit;
  white-space: nowrap;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--themeColor);
  margin-top: 7px;
}
.navbar-brand:hover {
  color: var(--themeColor);
}

.navbar-right-top {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 50px;
  margin: 0;
  text-decoration: none !important;
  padding: 0;
  padding-left: 10px;
}

.navbar-right-top .nav-item {
  list-style: none;
}

.navbar-right-top .nav-item:last-child {
  border: none;
}

.notification-link {
  font-size: 22px;
  color: #82849f;
  background: transparent;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 7px;
  color: rgba(33, 42, 57, 0.4);
  outline: none !important;
}
.username-link {
  color: #82849f;
  background: transparent;
  border: none;
  padding-right: 0;
  max-width: 150px;
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-right: 0px;
  color: rgba(33, 42, 57, 0.4);
  outline: none !important;
}
.username-link .user-name {
  max-width: 120px;
  width: max-content;
  text-align: left;
  font-size: 16px;
  /* background: red; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  color: rgba(33, 42, 57, 0.75);
}
.username-link .bold-angle-down {
  font-weight: 600;
  padding-left: 5px;
  vertical-align: bottom;
  margin-top: 6px;
  font-size: 16px;
  color: rgba(33, 42, 57, 0.75);
}
.home-dashboard-header .bold-angle-left {
  font-weight: 600;
  padding-left: 5px;
  vertical-align: bottom;
  margin-top: -2px;
  font-size: 18px;
  color: rgba(33, 42, 57, 0.75);
  transform: rotate(90deg);
}
.ppc-container {
  position: relative;
  /* width: max-content; */
  /* height: max-content; */
}
.ppc-container .cropper-cover {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  z-index: 100;
  position: absolute;
}
.ppc-container .cropper-cover-2 {
  width: 100%;
  height: calc(100% + 20px);
  /* height: 0; */
  /* background: red; */
  margin-top: -10px;
  /* background: rgba(255,255,255,0.8); */
  top: 0;
  z-index: 100;
  position: absolute;
}
.cropper-view-box {
  border-radius: 50%;
}

/* .cropper-face {
    border-radius: 50%;
    } */
.home-dashboard-header .profile-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width:230px; */
}
.home-dashboard-header .toggle-icon-container-mb,
.home-dashboard-header .ecko-logo-container-mb {
  display: none;
}

.home-dashboard-header .ecko-logo-container-mb-2 {
  display: block;
  padding-left: 20px;
  padding-top: 10px;
}

.job-breadcrumb {
  margin-top: 40px;
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 30px;
  margin-left: 0px !important;
}

.job-prev-padding {
  padding-left: 15% !important;
  padding-right: 15% !important;
  margin-top: 20px;
}

.job-prev-padding-2 {
  margin-top: 20px;
  padding-bottom: 0px !important;
}

.home-dashboard-header .ecko-logo-container .logo {
  margin-top: 12px;
}
.home-dashboard-header .profile-search-container-mb {
  display: none;
}

.group-sidebar-mb .sidebar-header {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border-bottom: 0.5px solid rgba(33, 42, 57, 0.25);
  padding: 5px;
}
.group-sidebar-mb .sidebar-header img {
  /* width: 50px;*/
  height: 31px;
}

/* ------  Notification Dropdown --------  */

.notification {
}

.notification-title {
  font-size: 14px;
  color: #3d405c;
  text-align: center;
  padding: 8px 0px;
  border-bottom: 1px solid #e3e3e3;
  line-height: 1.5;
  background-color: #fffffe;
}
.scroller,
.scroller-heading {
  display: none;
}
.scroller .img-ss {
  width: 100%;
  height: 93px;
  position: relative;
  overflow: hidden;
  background-color: rgba(207, 224, 235, 0.5);
}
.scroller .dum-1 {
  width: 70%;
  height: 15px;
  border-radius: 20px;
  margin-top: 15px;
  margin-left: 8px;
  position: relative;
  overflow: hidden;
  background-color: rgba(207, 224, 235, 0.5);
}
.scroller .dum-2 {
  width: 50%;
  height: 15px;
  border-radius: 20px;
  margin-top: 15px;
  margin-left: 8px;
  position: relative;
  overflow: hidden;
  background-color: rgba(207, 224, 235, 0.5);
}
.scroller .card-img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 93px;
  object-fit: cover;
}
.scroller-heading {
  font-family: "Source Sans Pro", sans-serif;
  color: #212a39;
  padding-left: 16px;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  padding-bottom: 10px;
}
.jg-extra-spacing {
  margin-bottom: 20px;
  width: 100%;
  height: 2px;
  /* background: red; */
}
.dp,
.header-cover {
  display: none;
}
.card-heading {
  padding: 0;
  width: calc(100% - 2px);
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 15px;
  /* overflow: hidden; */
  /* white-space: nowrap;  */
  /* text-overflow: ellipsis; */
  color: #212a39;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.c-heading-container {
  height: 100%;
  display: block;
}
.c-heading-container .gradient-cover {
  width: 80px;
  height: 30px;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.6),
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0.9),
    #fff
  );
  position: absolute;
}
.grp-name-container .grp-name {
  color: #212a39;
  font-size: 19.2px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  /* padding: 8px; */
  height: 54px;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -o-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  -o-box-orient: vertical;
  box-orient: vertical;
  /* autoprefixer: on */
  text-overflow: ellipsis;
  white-space: normal;
}
.c-heading-container .c-heading {
  color: #212a39;
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  /* padding: 8px; */
  height: 100%;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -o-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  -o-box-orient: vertical;
  box-orient: vertical;
  /* autoprefixer: on */
  text-overflow: ellipsis;
  white-space: normal;
}

.star-rating {
  display: inline-block;
  font-size: 17px;
  /* font-family: 'Source Sans Pro', sans-serif; */
  line-height: 1;
  color: #000;
}
.carousel-card-container .rating-container {
  /* padding-left: 12px; */
  padding-right: 12px;
  /* background: red; */
  width: max-content;
  display: inline-block;
  /* height: 50px; */
  /* width: 50%; */
}
.carousel-card-container .cc-cover,
.fc-carousel-card-container .fc-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  border-radius: 4px;
  cursor: pointer;
}
.carousel-card-container .cc-cover:hover,
.fc-carousel-card-container .fc-carousel-card:hover {
  /* box-shadow: 0 4px 15px 0 rgba(207, 224, 235, 0.7); */
  box-shadow: 0 8px 16px 2px rgba(207, 224, 235, 0.7);
  cursor: pointer;
}
.carousel-card-container .rating-text {
  display: inline-block;
  font-size: 12px;
  margin-left: -6px;
  vertical-align: 1px;
  /* background: green; */
  font-family: "Source Sans Pro", sans-serif;
}

.srtng_p[data-rating="0"],
.srtng_p[data-rating="0.0"] {
  --prv: 0;
}
.srtng_p[data-rating="0.1"] {
  --prv: 0.1;
}
.srtng_p[data-rating="0.2"] {
  --prv: 0.2;
}
.srtng_p[data-rating="0.3"] {
  --prv: 0.3;
}
.srtng_p[data-rating="0.4"] {
  --prv: 0.4;
}
.srtng_p[data-rating="0.5"] {
  --prv: 0.5;
}
.srtng_p[data-rating="0.6"] {
  --prv: 0.6;
}
.srtng_p[data-rating="0.7"] {
  --prv: 0.7;
}
.srtng_p[data-rating="0.8"] {
  --prv: 0.8;
}
.srtng_p[data-rating="0.9"] {
  --prv: 0.9;
}
.srtng_p[data-rating="1"],
.srtng_p[data-rating="1.0"] {
  --prv: 1;
}
.srtng_p[data-rating="1.1"] {
  --prv: 1.1;
}
.srtng_p[data-rating="1.2"] {
  --prv: 1.2;
}
.srtng_p[data-rating="1.3"] {
  --prv: 1.3;
}
.srtng_p[data-rating="1.4"] {
  --prv: 1.4;
}
.srtng_p[data-rating="1.5"] {
  --prv: 1.5;
}
.srtng_p[data-rating="1.6"] {
  --prv: 1.6;
}
.srtng_p[data-rating="1.7"] {
  --prv: 1.7;
}
.srtng_p[data-rating="1.8"] {
  --prv: 1.8;
}
.srtng_p[data-rating="1.9"] {
  --prv: 1.9;
}
.srtng_p[data-rating="2"],
.srtng_p[data-rating="2.0"] {
  --prv: 2;
}
.srtng_p[data-rating="2.1"] {
  --prv: 2.1;
}
.srtng_p[data-rating="2.2"] {
  --prv: 2.2;
}
.srtng_p[data-rating="2.3"] {
  --prv: 2.3;
}
.srtng_p[data-rating="2.4"] {
  --prv: 2.4;
}
.srtng_p[data-rating="2.5"] {
  --prv: 2.5;
}
.srtng_p[data-rating="2.6"] {
  --prv: 2.6;
}
.srtng_p[data-rating="2.7"] {
  --prv: 2.7;
}
.srtng_p[data-rating="2.8"] {
  --prv: 2.8;
}
.srtng_p[data-rating="2.9"] {
  --prv: 2.9;
}
.srtng_p[data-rating="3"],
.srtng_p[data-rating="3.0"] {
  --prv: 3;
}
.srtng_p[data-rating="3.1"] {
  --prv: 3.1;
}
.srtng_p[data-rating="3.2"] {
  --prv: 3.2;
}
.srtng_p[data-rating="3.3"] {
  --prv: 3.3;
}
.srtng_p[data-rating="3.4"] {
  --prv: 3.4;
}
.srtng_p[data-rating="3.5"] {
  --prv: 3.5;
}
.srtng_p[data-rating="3.6"] {
  --prv: 3.6;
}
.srtng_p[data-rating="3.7"] {
  --prv: 3.7;
}
.srtng_p[data-rating="3.8"] {
  --prv: 3.8;
}
.srtng_p[data-rating="3.9"] {
  --prv: 3.9;
}
.srtng_p[data-rating="4"],
.srtng_p[data-rating="4.0"] {
  --prv: 4;
}
.srtng_p[data-rating="4.1"] {
  --prv: 4.1;
}
.srtng_p[data-rating="4.2"] {
  --prv: 4.2;
}
.srtng_p[data-rating="4.3"] {
  --prv: 4.3;
}
.srtng_p[data-rating="4.4"] {
  --prv: 4.4;
}
.srtng_p[data-rating="4.5"] {
  --prv: 4.5;
}
.srtng_p[data-rating="4.6"] {
  --prv: 4.6;
}
.srtng_p[data-rating="4.7"] {
  --prv: 4.7;
}
.srtng_p[data-rating="4.8"] {
  --prv: 4.8;
}
.srtng_p[data-rating="4.9"] {
  --prv: 4.9;
}
.srtng_p[data-rating="5"] {
  --prv: 5;
}

.srtng_p::before {
  --srt_per: calc(var(--prv) / 5 * 100%);
  background: linear-gradient(
    90deg,
    #fc0 var(--srt_per),
    #dddcdc var(--srt_per)
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.star-rating::before {
  content: "★★★★★";
  letter-spacing: 0px;
}

.carousel-container .card-enroll {
  color: rgba(33, 42, 57, 0.75);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  margin-top: 8px;
  /* padding-left:12px; */
  /* padding-right:12px; */
  margin-bottom: 5px;
}
.fc-carousel-container .card-enroll {
  color: rgba(33, 42, 57, 0.75);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  /* margin-top:8px; */
  /* padding-left:12px; */
  /* padding-right:12px; */
  margin-bottom: 5px;
}
.fc-carousel-container .vw-card-btn {
  width: calc(100% - 32px);
  bottom: 16px;
  height: 48px;
  border: 1px solid var(--themeColor);
  border-radius: 2px;
  background: #fff;
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: var(--themeColor);
  font-weight: 600;
  position: absolute;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease !important;
}
.fc-carousel-container .jn-card-btn {
  width: calc(100% - 32px);
  bottom: 16px;
  height: 48px;
  border: 1px solid var(--themeColor);
  border-radius: 2px;
  background: var(--themeColor);
  outline: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  position: absolute;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease !important;
}
.fc-carousel-container .jn-card-btn:hover {
  background: rgb(38, 78, 76);
}
.fc-carousel-container .vw-card-btn:hover {
  background: var(--themeColor);
  color: #fff;
}
.carousel-container .card-heading-container {
  height: max-content;
  font-size: 20px;
  min-height: 48px;
}
.fc-carousel-container .card-heading-container {
  height: max-content;
  font-size: 20px;
  min-height: 48px;
}
.tc-carousel-container .tc-card-heading-container,
.fc-carousel-container .fc-card-heading-container {
  /* background: red; */
  height: 50px;
  font-size: 20px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Source Sans Pro", sans-serif;
  color: #212a39;
  /* padding-left: 18px; */
  font-size: 18px;
  font-weight: 600;
  padding-top: 15px;
  margin: 0;
  /* padding-bottom: 10px; */
}
.carousel-wrapper {
  overflow-x: hidden;
  margin-left: -10px;
  width: 100%;
  /* margin-right: 10px; */
}
.carousel-sec-container {
  /* width: calc( 100vw - 215px - 50px + 20px) ; */
  /* total - sidebar_width - for_extra_margin + padding_right_in_c_card_container  */
  height: 266px;
  /* background: yellow; */
  padding-left: 10px;
  display: flex;
  background: transparent;
}
.tc-carousel-sec-container,
.fc-carousel-sec-container {
  /* width: calc( 100vw - 215px - 50px + 20px) ; */
  /* total - sidebar_width - for_extra_margin + padding_right_in_c_card_container  */
  height: 347px;
  /* overflow: hidden; */
  /* background: yellow; */
  display: flex;
  padding-left: 10px;
  background: transparent;
}
.carousel-container .carousel-card-container {
  height: 100%;
  width: 25%;

  /* background: pink; */
  /* border: 1px solid #000; */
  padding-right: 16px;
}
.tc-carousel-container .tc-carousel-card-container,
.fc-carousel-container .fc-carousel-card-container {
  height: 100%;
  width: 33.333%;

  /* background: pink; */
  /* border: 1px solid #000; */
  padding-right: 16px;
}
.carousel-btn-container .next-btn {
  border-radius: 50%;
  padding: 0;
  border: 0;
  background: transparent;
}
.carousel-btn-container .prev-btn {
  border-radius: 50%;
  padding: 0;
  border: 0;
  background: transparent;
  margin-right: 10px;
  transform: rotate(180deg);
}
.carousel-container .card-btn {
  /* margin-left: 12px; */
  /* margin-right: 12px; */
  width: 100%;
  outline: none;
  height: 44px;
  border: 1px solid var(--themeColor);
  border-radius: 2px;
  background: transparent;
  color: var(--themeColor);
  margin-top: 12px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}
.carousel-container .card-btn-container {
  padding-left: 12px;
  padding-right: 12px;
}
.carousel-btn-container .prev-btn:focus,
.carousel-btn-container .next-btn:focus {
  outline: none;
}
.carousel-header {
  /* margin-top: 18px; */
  width: 100%;
  display: flex;
  margin-bottom: 32px;
  /* justify-content: center;
    align-content: center;
    justify-self: center;
    align-self: center; */
}
.carousel-header .btn-src {
  width: 28px;
  height: 28px;
  /* display: none; */
}
.carousel-heading {
  display: flex;
  /* justify-content: center; */
  /* align-content: center; */
  /* justify-self: center; */
  align-self: center;
  width: calc(100% - 66px);
  color: #000;
  font-size: 20px;
  font-weight: 600;
  /* background: red; */
  margin: 0;
  height: max-content;
  /* text-align: center; */
  font-family: "Source Sans Pro", sans-serif;
}
.carousel-container {
  /* padding-top: 10px; */
  width: max-content;
  background: #31a128;
  height: 286px;
  display: flex;
  background: transparent;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease !important;
}

.carousel-container .carousel-card-container {
  height: 100%;
  width: 25%;

  /* background: pink; */
  /* border: 1px solid #000; */
  padding-right: 16px;
}
.carousel-container .carousel-card {
  width: 100%;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  height: 100%;
  position: relative;
  /* margin-right: 5%; */
}
/* .carousel-container .carousel-card:hover{
    transform : scale(1.1);
    } */
.tc-carousel-container,
.fc-carousel-container {
  padding-top: 0px;
  width: max-content;
  background: #31a128;
  height: 367px;
  display: flex;
  background: transparent;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease !important;
}

.tc-carousel-container .tc-carousel-card-container,
.fc-carousel-container .fc-carousel-card-container {
  height: 100%;
  width: 33.333%;

  /* background: pink; */
  /* border: 1px solid #000; */
  padding-right: 16px;
}
.tc-carousel-container .tc-carousel-card,
.fc-carousel-container .fc-carousel-card {
  width: 100%;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  height: 100%;
  position: relative;
  /* margin-right: 5%; */
}
.tc-carousel-container .tc-carousel-card-1 {
  width: 100%;
  background: #fff;
  border-radius: 5px;
  -webkit-box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  height: calc(50% - 15px);
  display: flex;
}
.tc-carousel-card-1 .members {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  padding-left: 12px;
  padding-top: 5px;
}

.tc-carousel-card .cc-img-container {
}
.tc-carousel-card-1 .cc-img-container {
  width: 125px;
  position: relative;
  background-color: rgba(207, 224, 235, 0.5);
  overflow: hidden;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.tc-carousel-card-1 .card-heading-container {
}
.tc-carousel-card-1 .cc-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
  /* object-position: center; */
}

.tc-carousel-card-1 .card-content {
  width: calc(100% - 125px);
  /* background: red; */
  padding: 20px;
  padding-top: 0px;
  padding-left: 8px;
}
.tc-carousel-card-1 .view-btn {
  margin-left: 12px;
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 18px;
  color: var(--themeColor);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 600;
}
.scroller .img-ss::after,
.course-container .dum-mb-1::after,
.course-container .dum-mb-3::after,
.course-container .dum-mb-2::after,
.scroller .dum-1::after,
.scroller .dum-2::after,
.scroller .learn-btn-ss::after {
  display: block;
  content: "";
  position: absolute;
  width: 80%;
  height: 100%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.001),
    rgba(207, 224, 235, 0.5),
    rgba(255, 255, 255, 0.001)
  );
  -webkit-animation: loading 1s infinite;
  animation: loading 1s infinite;
}

.grp-dtl-container .grp-img-section::after,
.grp-dtl-container .dum-1::after,
.course-container .ss-btn::after,
.course-container .dum-ss-2::after,
.course-container .dum-ss-1::after {
  display: block;
  content: "";
  position: absolute;
  width: 80%;
  height: 100%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.001),
    rgba(207, 224, 235, 0.5),
    rgba(255, 255, 255, 0.001)
  );
  -webkit-animation: loading 1s infinite;
  animation: loading 1s infinite;
}

.tc-carousel-card-1 .cc-img-container::after,
.course-container .crs-img-container::after,
.tc-carousel-card-container .single-card-img-container::after,
.fc-carousel-card-container .single-card-img-container::after,
.carousel-container .cc-img-container::after {
  display: block;
  content: "";
  position: absolute;
  width: 80%;
  height: 100%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.001),
    rgba(207, 224, 235, 0.5),
    rgba(255, 255, 255, 0.001)
  );
  -webkit-animation: loading 1s infinite;
  animation: loading 1s infinite;
}

.home-left-sidebar .dum-btn::after,
.home-left-sidebar-mb .dum-btn::after,
.home-left-sidebar .dum-btn-2::after,
.home-left-sidebar-mb .dum-btn-2::after,
.home-left-sidebar .dum-btn-3::after,
.home-left-sidebar-mb .dum-btn-3::after {
  display: block;
  content: "";
  position: absolute;
  width: 80%;
  height: 100%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    var(--themeColor) 0%,
    var(--ctaColor) 50%,
    var(--themeColor) 100%
  );
  -webkit-animation: loading 1s infinite;
  animation: loading 1s infinite;
}
.group-sidebar .dum-btn::after,
.group-sidebar-mb .dum-btn::after,
.group-sidebar .dum-btn-2::after,
.group-sidebar-mb .dum-btn-2::after,
.group-sidebar .dum-btn-3::after,
.group-sidebar-mb .dum-btn-3::after {
  display: block;
  content: "";
  position: absolute;
  width: 80%;
  height: 100%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.001),
    rgba(72, 105, 150, 0.5),
    rgba(255, 255, 255, 0.001)
  );
  -webkit-animation: loading 1s infinite;
  animation: loading 1s infinite;
}
@-webkit-keyframes loading {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes loading {
  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.tc-carousel-container .padding-div {
  width: 100%;
  background: transparent;
  /* border-radius: 5px; */
  /* -webkit-box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.1); */
  /* box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.1); */
  height: 30px;
}
.fc-carousel-card-container .card-content {
  width: 100%;
  height: calc(100% - 175px);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 16px;
  position: relative;
}
.carousel-card-container .card-content {
  width: 100%;
  /* background: red; */
  /* height: calc(100% - 175px); */
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 16px;
}
.fc-carousel-card-container .card-name {
}
.tc-carousel-card-container .single-card-img-container,
.fc-carousel-card-container .single-card-img-container {
  height: 175px;
  border-top-left-radius: 5px;
  background-color: rgba(207, 224, 235, 0.5);
  border-top-right-radius: 5px;
  position: relative;
  overflow: hidden;
  /* background-size: cover!important; */
}
.tc-carousel-card-container .single-card-img,
.fc-carousel-card-container .single-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}
.tc-carousel-card-container .single-card-content,
.fc-carousel-card-container .single-card-content {
  height: calc(100% - 175px);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  /* background: red; */
}
.tc-carousel-card-container .single-card-left,
.fc-carousel-card-container .single-card-left {
  width: 55%;
  border-bottom-left-radius: 5px;
  /* border-bottom-right-radius: 5px; */
  /* display: flex; */
  /* background: yellow; */
  padding-top: 10px;
  padding-left: 20px;
}
.tc-carousel-card-container .single-card-right,
.fc-carousel-card-container .single-card-right {
  width: 45%;
  /* border-bottom-left-radius: 5px; */
  border-bottom-right-radius: 5px;
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;
  align-items: center;
  /* background: green; */
}
.tc-carousel-card-container .single-btn,
.fc-carousel-card-container .single-btn {
  height: 48px;
  width: 109px;
  border: 1px solid var(--themeColor);
  border-radius: 2px;
  background: #fff;
  color: var(--themeColor);
  font-size: 16px;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
}
.tc-carousel-card-container .tc-single-members,
.fc-carousel-card-container .fc-single-members {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
}
.carousel-container .cc-img {
  width: 100%;
  height: 100%;
  display: none;
}
.carousel-container .cc-img-container {
  height: 130px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: rgba(207, 224, 235, 0.5);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.carousel-container .cc-img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
}
.group-hr {
  height: 10px;
  width: 100%;
  padding: 0;
  margin: 0;
  /* border:2px solid #000; */
  /* color: #000; */
}
.group-msg {
  color: #000;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 18px;
  padding-top: 16px;
  margin: 0;
  margin-bottom: 40px;
}
.scroller .heading-container {
  height: 55px;
  padding-left: 8px;
  padding-top: 8px;
  padding-right: 8px;
}
.scroller .rating-text {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
}
.scroller .enroll {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 3px;
}
.scroller .ellipsis {
  position: absolute;
  right: 8px;
  bottom: 0;
}
.scroller .rating-container {
  padding-left: 8px;
  padding-bottom: 16px;
}
.scroller .star {
  margin-left: 3px;
}
.scroller .view-btn {
  margin: 8px;
  margin-bottom: 16px;
  outline: none;
  height: 36px;
  color: var(--themeColor);
  border-radius: 2.4px;
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  width: calc(100% - 16px);
  font-weight: 600;
  border: 1px solid var(--themeColor);
  background: transparent;
}
.scroller .join-btn {
  margin: 8px;
  margin-bottom: 16px;
  outline: none;
  height: 36px;
  color: #fff;
  border-radius: 2.4px;
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  width: calc(100% - 16px);
  font-weight: 600;
  border: 1px solid var(--themeColor);
  background: var(--themeColor);
}
.scroller .learn-btn-ss {
  margin: 8px;
  height: 36px;
  outline: none;
  overflow: hidden;
  width: calc(100% - 16px);
  background-color: rgba(207, 224, 235, 0.5);
  bottom: 0;
  position: absolute;
}

/* -------------------- 0.4. Sidebar ----------------------- */

/* -----------------------
  Left Navigation Sidebar
  ------------------------- */

.home-left-sidebar {
  position: fixed;
  width: 250px;
  height: calc(100% - 0px);
  top: 0px;
  z-index: 70;
  overflow: auto;
  /* background-image: linear-gradient(110deg,#000000c2,var(--themeColor)); */
  /* background-image: linear-gradient(to right,  #000, var(--themeColor)); */
  background-color: var(--themeColor);
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  -webkit-transition: all 0.3s ease;
}
.home-left-sidebar .dum-btn,
.home-left-sidebar-mb .dum-btn,
.group-sidebar .dum-btn,
.group-sidebar-mb .dum-btn {
  width: calc(100% - 32px);
  position: relative;
  overflow: hidden;
  margin-top: 98px;
  margin-left: 16px;
  height: 16px;
  overflow: hidden;
  position: relative;
  /* background-color: rgb(38, 78, 76); */
  border-radius: 40px;
}
.home-left-sidebar .dum-btn-2,
.home-left-sidebar-mb .dum-btn-2,
.group-sidebar .dum-btn-2,
.group-sidebar-mb .dum-btn-2 {
  width: calc(100% - 80px);
  margin-top: 24px;
  margin-left: 16px;
  height: 16px;
  overflow: hidden;
  position: relative;
  /* background-color: rgb(38, 78, 76); */
  border-radius: 40px;
  position: relative;
  overflow: hidden;
}
.home-left-sidebar .dum-btn-3,
.home-left-sidebar-mb .dum-btn-3,
.group-sidebar .dum-btn-3,
.group-sidebar-mb .dum-btn-3 {
  width: calc(100% - 32px);
  margin-top: 24px;
  margin-left: 16px;
  height: 16px;
  overflow: hidden;
  position: relative;
  /* background-color: rgb(38, 78, 76); */
  border-radius: 40px;
  position: relative;
  overflow: hidden;
}

.home-left-sidebar .logo-container {
  width: 100%;
  /* justify-content: center; */
  display: flex;
  height: 60px;
  /* border-bottom: 0.5px solid rgba(33, 42, 57, 0.25); */
  padding: 18px 0;
}
@media only screen and (min-width: 800px) {
  .home-left-sidebar .logo-container img {
    max-width: 150px !important;
    max-height: 50px;
  }
}

.home-left-sidebar .logo {
  height: 100%;
  width: auto;
  left: 16px;
}
.group-sidebar .logo-container {
  width: 100%;
  padding: 18px 0;

  /* padding-left: 100px; */
  /* justify-content: center; */
  display: flex;
  /* padding-top: 2px; */
  /* padding-bottom:2px; */
  /* margin-top: 12px; */
  width: 100%;
  /* margin-bottom: 12px; */
  height: 60px;
}
.group-sidebar .logo-container img,
.group-sidebar .logo-container svg {
  /* margin: auto; */
  /* width: 50px; */
  /* height: 50px; */
}
.group-sidebar {
  position: fixed;
  width: 250px;
  height: calc(100%);
  top: 0px;
  z-index: 999;
  overflow: auto;
  background-color: var(--themeColor);
  -webkit-box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  -webkit-transition: all 0.3s ease;
}
.home-left-sidebar .sidebar-img {
  height: 20px;
  width: 20px;
}
.my-grp-container .view-btn {
  display: none;
}
.home-left-sidebar .nav-link i {
  font-size: 14px;
  margin-right: 9px;
  text-align: center;
  vertical-align: middle;
  line-height: 16px;
}

.home-left-sidebar .submenu {
  padding-left: 12px;
  padding-right: 12px;
  /* margin-top: 5px; */
  background: #24274a;
}

.home-left-sidebar .submenu .nav .nav-item .nav-link {
  font-size: 14px;
  padding: 6px 12px;
  transition: 0.3s;
}
.home-left-sidebar-mb,
.group-sidebar-mb {
  visibility: hidden;
  /* transition: all 0.1s ease; */
  /* -webkit-transition: all 0.1s ease !important; */
}
.home-left-sidebar-mb .left-sidebar-menu-mb,
.group-sidebar-mb .left-sidebar-menu-mb {
  height: 100vh;
  width: 80vw;
  margin-left: -80vw;
  z-index: 702;
  position: fixed;
  background: var(--themeColor);
  transition: all 0.7s ease;
  -webkit-transition: all 0.7s ease !important;
}
.home-left-sidebar-mb .close-btn {
  left: calc(80vw - 50px);
  z-index: 703;
}
.home-left-sidebar-mb .left-sidebar-menu-mb {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  padding: 0;
  /*justify-content: flex-end;*/
}
.out {
  margin-left: 0 !important;
}

.home-left-sidebar-mb .sidebar-list {
  list-style: none;
  padding: 0;
  padding-top: 0px;
}
.home-left-sidebar-mb .logo-sidebar {
  /*position: absolute;
    bottom: 15px;
    left: 25px;*/
  /*padding-left: 28%;*/
  /* width: 50px; */
  /* height: 50px; */
  max-width: 100%;
  max-height: 50px;
}
.home-left-sidebar-mb .logo-container {
  /*height: calc(100% - 365px);
    width: 100%;*/
  /* background: red; */
  /*position: absolute;
    bottom: 50px;*/
  /*height: 100%;*/
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  min-height: 60px;
  border-bottom: 0.5px solid rgba(33, 42, 57, 0.25);
}
.home-left-sidebar-mb .logo-container img {
  height: 31px;
}
.home-left-sidebar-mb .sidebar-list-item {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  height: 55px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-weight: 600;
}
.home-left-sidebar-mb .active {
  background: var(--ctaColor);
  color: #fff;
  padding-left: 26px;
  border-left: 4px solid #fff;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.group-sidebar-mb .active {
  font-family: var(--SourceSansPro);
  font-size: 18px;
  background: rgba(207, 224, 235, 0.5);
  color: #fff;
  padding-left: 26px;
  border-left: 4px solid var(--themeColor);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.home-left-sidebar-mb .close-btn,
.group-sidebar-mb .close-btn {
  position: fixed;
  margin-right: 10vw;
  top: 10px;
  background: transparent;
  border: none;
  z-index: 702;
  font-size: 30px;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  right: 0;
  outline: none;
}
.home-left-sidebar-mb .close-btn {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  width: 50px;
  height: 60px;
  display: flex;
  justify-content: center;
  z-index: 703;
}
.home-left-sidebar-mb .close-btn img,
.home-left-sidebar-mb .close-btn svg {
  display: unset;
  height: 18px;
  width: 18px;
  margin: 12.5px auto;
}
.group-sidebar-mb .close-btn {
  position: absolute;
  right: 0;
  top: 0;
  left: unset;
  width: 75px;
  height: 60px;
  display: flex;
  justify-content: center;
  margin: 0;
}

.group-sidebar-mb .close-btn img,
.group-sidebar-mb .close-btn svg {
  display: unset;
  height: 18px;
  width: 18px;
  margin: calc(40% - 12px) auto;
}
.navbar-toggler {
  padding: 0.25rem 0.5rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.mb-br {
  display: none;
}
.join-grp-form {
  width: 100%;
  padding: 24px;
  padding-left: 32px;
  /* padding-right:10px; */
}
.join-grp-form .jg-form-label {
  color: rgba(33, 42, 57, 0.75);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
}
.join-grp-form .jg-inp {
  width: 100%;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 19.2px;
  padding: 10px;
  margin-bottom: 24px;
  outline: none !important;
  color: rgba(33, 42, 57, 0.75);
  font-weight: 600;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
}
.join-grp-form .jg-inp-er {
  width: 100%;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 19.2px;
  padding: 10px;
  margin-bottom: 16px;
  outline: none !important;
  color: rgba(33, 42, 57, 0.75);
  font-weight: 600;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
}
.join-grp-form .jg-inp:focus {
  border: 1.5px solid var(--themeColor) !important;
}
.home-cg-container .join-container {
  padding-right: 20px;
}
.tc-ss-container,
.grp-ss-container,
.fc-ss-container {
  padding-top: 10px;
  padding-bottom: 10px;
}
.grp-ss-container .card {
  width: 100%;
  height: 266px;
  /* background: red; */
  border: 0;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  border-radius: 4px;
}
.gc-ss-container .card {
  width: 100%;
  height: 347px;
  /* background: red; */
  border: 0;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  border-radius: 4px;
}
.grp-ss-container .card-col {
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 8px;
  padding-right: 8px;
}
.gc-ss-container .card-col {
  flex: 0 0 25%;
  max-width: 25%;
  padding-left: 8px;
  padding-right: 8px;
}
.grp-ss-container .card-row {
  margin-left: -8px;
  margin-right: -8px;
}
.gc-ss-container .card-row {
  margin-left: -8px;
  margin-right: -8px;
}
.grp-ss-container .g-card-col,
.gc-ss-container .g-card-col {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  padding-left: 8px;
  padding-right: 8px;
}
.grp-ss-container .g-card-row,
.gc-ss-container .g-card-row {
  margin-left: -8px;
  margin-right: -8px;
}
.tc-ss-container .card,
.fc-ss-container .card {
  width: 100%;
  height: 359px;
  /* background: red; */
  border: 0;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  border-radius: 4px;
}
.tc-ss-container .card-container {
  width: 100%;
  height: 359px;
  /* background: #fff; */
  border: 0;
  /* box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5); */
  border-radius: 4px;
}
.tc-ss-container .card-top {
  width: 100%;
  display: flex;
  height: calc(50% - 15px);
  /* margin-bottom: 15px; */
  background: #fff;
  border: 0;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  border-radius: 4px;
}
.tc-ss-container .card-bottom {
  width: 100%;
  display: flex;
  height: calc(50% - 15px);
  background: #fff;
  margin-top: 30px;
  border: 0;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  border-radius: 4px;
}
.tc-ss-container .img-container {
  width: 125px;
  height: 100%;
  background: rgba(207, 224, 235, 0.5);
  /* margin-top: 30px; */
  border: 0;
  position: relative;
  overflow: hidden;
  /* box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5); */
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.tc-ss-container .card-side {
  width: calc(100% - 125px);
  /* height: 100%; */
  /* background: rgba(207, 224, 235, 0.5); */
  /* margin-top: 30px; */
  border: 0;
  /* box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5); */
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding-left: 20px;
}
.tc-ss-container .dum-1 {
  width: calc(100% - 125px);
  height: 15px;
  width: 80%;
  position: relative;
  overflow: hidden;
  margin-top: 15px;
  background: rgba(207, 224, 235, 0.5);
  border-radius: 40px;
}
.tc-ss-container .dum-2 {
  width: calc(100% - 125px);
  height: 15px;
  width: 60%;
  position: relative;
  overflow: hidden;
  margin-top: 15px;
  background: rgba(207, 224, 235, 0.5);
  border-radius: 40px;
}
.tc-ss-container .dum-3 {
  width: calc(100% - 125px);
  height: 15px;
  width: 60%;
  position: relative;
  overflow: hidden;
  margin-top: 60px;
  background: rgba(207, 224, 235, 0.5);
  border-radius: 40px;
}
.tc-ss-container .img-section,
.fc-ss-container .img-section {
  width: 100%;
  height: 175px;
  background-color: rgba(207, 224, 235, 0.5);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  overflow: hidden;
}
.grp-ss-container .img-section {
  height: 130px;
  width: 100%;
  background-color: rgba(207, 224, 235, 0.5);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  overflow: hidden;
}
.gc-ss-container .img-section {
  height: 175px;
  width: 100%;
  background-color: rgba(207, 224, 235, 0.5);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  overflow: hidden;
}
.tc-ss-container .bottom-section,
.fc-ss-container .bottom-section {
  width: 100%;
  height: calc(100% - 175px);
  display: flex;
  /* background: blue; */
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.grp-ss-container .bottom-section {
  width: 100%;
  height: calc(100% - 165px);
  /* display: flex; */
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.grp-ss-container .dum-1 {
  width: 60%;
  height: 15px;
  background-color: rgba(207, 224, 235, 0.5);
  margin-top: 22px;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
}
.grp-ss-container .dum-2 {
  width: 40%;
  height: 15px;
  background-color: rgba(207, 224, 235, 0.5);
  margin-top: 18px;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
}
.grp-ss-container .dum-btn {
  width: calc(100% - 24px);
  position: absolute;
  bottom: 15px;
  height: 45px;
  overflow: hidden;
  background-color: rgba(207, 224, 235, 0.5);
  border-radius: 4px;
}
.gc-ss-container .bottom-section {
  width: 100%;
  height: calc(100% - 165px);
  /* display: flex; */
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.gc-ss-container .dum-1 {
  width: 60%;
  height: 15px;
  background-color: rgba(207, 224, 235, 0.5);
  margin-top: 22px;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
}
.gc-ss-container .dum-2 {
  width: 40%;
  height: 15px;
  background-color: rgba(207, 224, 235, 0.5);
  margin-top: 18px;
  border-radius: 40px;
  position: relative;
  overflow: hidden;
}
.gc-ss-container .dum-btn {
  width: calc(100% - 24px);
  position: absolute;
  bottom: 15px;
  height: 45px;
  overflow: hidden;
  background-color: rgba(207, 224, 235, 0.5);
  border-radius: 4px;
}
.tc-ss-container .left,
.fc-ss-container .left {
  height: max-content;
  width: 100%;
}
.tc-ss-container .left-container,
.fc-ss-container .left-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55%;
  padding-left: 5%;
  /* background: yellow; */
  height: 100%;
}
.tc-ss-container .right,
.fc-ss-container .right {
  width: 45%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: red; */
}
.tc-ss-container .dummy-btn,
.fc-ss-container .dummy-btn {
  height: 40%;
  width: 65%;
  border-radius: 4px;
  background-color: rgba(207, 224, 235, 0.5);
  position: relative;
  overflow: hidden;
}
.tc-ss-container .left-1,
.fc-ss-container .left-1 {
  width: 80%;
  height: 15px;
  border-radius: 25px;
  background-color: rgba(207, 224, 235, 0.5);
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
.tc-ss-container .left-2,
.fc-ss-container .left-2 {
  width: 60%;
  border-radius: 25px;
  height: 15px;
  position: relative;
  overflow: hidden;
  background-color: rgba(207, 224, 235, 0.5);
}
.tc-ss-container .img-section::after,
.grp-ss-container .img-section::after,
.gc-ss-container .img-section::after,
.grp-ss-container .dum-btn::after,
.grp-ss-container .dum-1::after,
.grp-ss-container .dum-2::after,
.gc-ss-container .dum-btn::after,
.gc-ss-container .dum-1::after,
.gc-ss-container .dum-2::after,
.tc-ss-container .dum-2::after,
.tc-ss-container .dum-3::after,
.tc-ss-container .dum-1::after,
.tc-ss-container .img-container::after,
.fc-ss-container .img-section::after,
.tc-ss-container .dummy-btn::after,
.fc-ss-container .dummy-btn::after,
.tc-ss-container .left-2::after,
.fc-ss-container .left-2:after,
.tc-ss-container .left-1::after,
.fc-ss-container .left-1::after {
  display: block;
  content: "";
  position: absolute;
  width: 80%;
  height: 100%;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  background: linear-gradient(
    90deg,
    transparent,
    rgba(207, 224, 235, 0.5),
    transparent
  );
  -webkit-animation: loading 1s infinite;
  animation: loading 1s infinite;
}
/*-----------------------
  Container / Wrapper
  -------------------------*/

.dashboard-main-wrapper {
  min-height: 100%;
  padding-top: 60px;
  position: relative;
}

.dashboard-content-wrapper {
  position: relative;
  left: 0;
  margin-left: 250px;
  min-height: calc(100vh - 60px);
  overflow-x: hidden;
}
.dashboard-content .back-btn {
  color: var(--themeColor) !important;
  cursor: pointer;
}
.dashboard-content .back-img {
  padding-right: 5px;
  margin-top: -3px;
}
.group-db-content-wrapper {
  position: relative;
  left: 0;
  margin-left: 250px;
  min-height: calc(100vh - 60px);
  overflow-x: hidden;
}
.group-db-content-wrapper-2 {
  position: relative;
  left: 0;
  margin-left: 300px;
  min-height: 40px;
  overflow-x: hidden;
  height: 40%;
  /* padding-top: 40px; */
}
@media only screen and (max-width: 770px) {
  .group-db-content-wrapper-2 {
    margin-left: 0;
  }
}

.group-db-content-wrapper-3 {
  position: relative;
  left: 0;
  margin-left: 300px;
  /* min-height: calc(100vh - 60px); */
  overflow-x: hidden;
}
.dashboard-content-wrapper .content-header,
.group-db-content-wrapper .content-header {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  color: var(--themeColor);
  font-weight: 600;
  display: none;
  /* color: rgba(33, 42, 57, 0.75); */
  padding-bottom: 8px;
  border-bottom: 1.5px solid rgba(33, 42, 57, 0.15);
}
.dashboard-content-wrapper .content-header-back,
.group-db-content-wrapper .content-header-back {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--themeColor);
  padding-bottom: 15px;
  border-bottom: 1.5px solid rgba(33, 42, 57, 0.15);
}

.profile-setting-container-2 {
  width: 100%;
  /* height: 7rem; */
  background: #fff;
  /* min-height: calc(100vh - 50px - 84px); */
  /* max-width: 880px;  */
  margin: 0 auto;
  border-radius: 4px;
  /* box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5); */
  /* margin-bottom:10px; */
  padding-bottom: 10px;
  font-family: "Source Sans Pro", sans-serif;
}

.profile-popup-container-2 {
  width: 100%;
  /* max-height: 130px; */
  background: #fff;
  /* min-height: calc(100vh - 50px - 84px); */
  /* /* max-width: 880px;  /*
  /* margin: 0 auto; */
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  /* margin-bottom:10px; */
  padding-bottom: 30px;
  font-family: "Source Sans Pro", sans-serif;
}

.goto-myprofile {
  border-radius: 8px;
  border: 1px solid #035642;
  color: #035642;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px; /* 83.333% */
  min-height: 45px;
  background: #fff;
}

.goto-profile-btn {
  display: flex;
  justify-content: center;
  margin: 16px 0px;
}

.profile-setting-container-7 {
  width: 100%;
  /* height: 7rem; */
  background: #fff;
  /* min-height: calc(100vh - 50px - 84px); */
  /* max-width: 880px;  */
  margin: 0 auto;
  border-radius: 4px;

  /* margin-bottom:10px; */
  padding-bottom: 20px;
  font-family: "Source Sans Pro", sans-serif;
}

.profile-setting-container-8 {
  width: 100%;
  /* max-width: 880px;  */
  margin: 0 auto;
  border-radius: 4px;
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.profile-next-btn {
  padding-top: 1px;
}

.upload-div-main {
  display: flex;
  border-radius: 8px;
  border: 1px solid rgba(60, 72, 82, 0.2);
  width: 98%;
  height: 56px;
  flex-shrink: 0;
  border-radius: 4px;
}

.upload-div-txt {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-top: 16px;
}

.upload-div-txt-2 {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 4px;
}

.upload-div-profile {
  width: 48px;
  height: 48px;
  margin-left: 16px;
  justify-content: center;
  display: flex;
  align-self: center;
  background: rgba(60, 72, 82, 0.15);
  border-radius: 8px;
  margin-right: 8px;
}

.congrats-div-job {
  width: 98px;
  height: 98px;
  justify-content: center;
  display: flex;
  align-self: center;
  background: rgba(60, 72, 82, 0.15);
  border-radius: 8px;
  margin: 24px;
}

.congrats-div-job-content {
  margin-top: 24px;
}

.profile-setting-container-3 {
  width: 100%;
  /* max-width: 880px;  */
  margin: 0 auto;
  border-radius: 4px;
  padding-bottom: 0px;
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.profile-setting-container-4 {
  width: 100%;
  /* height: 7rem; */
  background: #fff;
  min-height: calc(40vh - 50px - 84px);
  /* max-width: 880px;  */
  margin: 0 auto;
  border-radius: 4px;

  /* margin-bottom:10px; */
  padding-bottom: 50px;
  font-family: "Source Sans Pro", sans-serif;
}

.profile-setting-container-foot {
  width: 100%;
  /* max-width: 880px;  */
  margin: 0 auto;
  padding-bottom: 50px;
  font-family: "Source Sans Pro", sans-serif;
}

.profile-setting-container-5 {
  width: 100%;
  /* height: 7rem; */
  background: #fff;

  /* max-width: 880px;  */
  margin: 0 auto;
  border-radius: 4px;

  /* margin-bottom:10px; */
  padding-bottom: 50px;
  font-family: "Source Sans Pro", sans-serif;
}

.profile-setting-container-6 {
  width: 100%;
  /* height: 7rem; */
  background: #fff;
  /* max-width: 880px;  */
  margin: 0 auto;
  border-radius: 4px;

  /* margin-bottom:10px; */
  padding-bottom: 50px;
  font-family: "Source Sans Pro", sans-serif;
}

.profile-setting-container-77 {
  width: 100%;
  /* height: 7rem; */
  background: #fff;
  /* min-height: calc(68vh - 50px - 84px); */
  /* max-width: 880px;  */
  margin: 0 auto;
  border-radius: 16px;

  /* margin-bottom:10px; */
  padding-bottom: 28px;
  font-family: "Source Sans Pro", sans-serif;
}

.prof-dtl-experience-span {
  color: #212a39;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  margin-left: 16px;
}

.prof-dtl-jobexp-heading {
  color: #212a39;
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}

.prof-dtl-jobexp-checkbox {
  color: #212a39;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-left: 8px;
}

.make-it-disable {
  opacity: 25;
}
.profile-bar-2 {
  margin-left: 10px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.profile-progress-bar {
  /* margin-left: 10px; */
  padding-top: 12px;
  padding-bottom: 12px;
}

.profile-bar {
  margin-left: 10px;
  padding-top: 24px;
}

.profile-bar-heading {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.profile-popup-heading {
  color: rgba(60, 72, 82, 0.5);
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 1px solid rgba(33, 42, 57, 0.15);
  padding-bottom: 5px;
}

.profile-bar-detail {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
}

.profile-bar-progress {
  border-radius: 32px;
  background: rgba(60, 72, 82, 0.08);
  height: 100%;
  padding: 0px !important;
}

.profile-bar-completed {
  border-radius: 32px;
  background: linear-gradient(270deg, #469cec 0%, #3abaa8 100%);
  height: 1px;
  min-height: 5px;
}

.profile-popup-completed {
  border-radius: 32px;
  background: #055646;
  height: 1px;
  min-height: 5px;
}

.job-bar {
  border-radius: 32px;
  background: linear-gradient(
      0deg,
      rgba(60, 72, 82, 0.25) 0%,
      rgba(60, 72, 82, 0.25) 100%
    ),
    #fff;
  height: 100%;
  max-height: 5px;
  top: 30%;
}

.job-bar-completed {
  border-radius: 32px;
  background: #097252;
  height: 100%;
  max-height: 5px;
  top: 30%;
}

.img-bar-height {
  height: 25px;
  min-width: 100px;
}

.inc-z-index {
  z-index: 200;
}

.group-db-content-wrapper .profile-setting-container {
  width: 100%;
  height: max-content;
  background: #fff;
  /* min-height: 380px; */
  /* max-width: 880px;  */
  margin: 0 auto;
  border-radius: 16px;
  /* box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5); */
  /* margin-bottom:10px; */
  /* padding-bottom: 50px; */
}
.profile-setting-update-password {
  padding-bottom: 50px;
}
.dashboard-content-wrapper .profile-setting-container {
  width: 100%;
  min-height: calc(100vh - 60px - 84px);
  height: max-content;
  padding-bottom: 32px;
  background: #fff;
  /* max-width: 880px;  */
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  /* margin-bottom:10px; */
}
.cgrp-form-container {
  width: 100%;
  height: max-content;
  background: #fff;
  margin-top: 15px;
  padding-bottom: 25px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  margin-bottom: 10px;
}
.dashboard-content-wrapper .ps-main-content {
  min-height: 300px;
  display: block;
  margin: 0 auto;
}
.dashboard-content-wrapper .cgf-main-content {
  min-height: 300px;
  display: block;
  margin: 0 auto;
}
.dashboard-content-wrapper .edit-profile-heading,
.group-db-content-wrapper .edit-profile-heading {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  padding-top: 24px;
  padding-bottom: 24px;
  font-weight: 600;
  color: #212a39;
  /* text-align: center; */
}
.chng-pwd-heading {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 28px;
  padding-top: 35px;
  padding-bottom: 20px;
  font-weight: 600;
  color: #212a39;
  text-align: center;
  opacity: 0.75;
}
.dashboard-content-wrapper .cgf-heading {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 23.04px;
  padding-top: 35px;
  padding-bottom: 20px;
  font-weight: 600;
  color: rgba(33, 42, 57, 0.75);
  text-align: center;
}
.dashboard-content-wrapper .c-grp-container {
  width: 100%;
  /* max-width: 880px;  */
  height: max-content;
  background: #fff;
  margin: 0 auto;
  margin-top: 0px;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  margin-bottom: 24px;
}
.dashboard-content-wrapper .c-grp-container .row {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.dashboard-content-wrapper .c-grp-container .c-grp-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.dashboard-content-wrapper .c-grp-container .c-grp-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  padding: 0;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  /* background: red; */
}
.c-grp-ill-contianer {
  width: 100%;
  /* max-width: 880px;  */
  min-height: max-content;
  margin: 0 auto;
  background: red;
  /* height: 100px; */
  display: flex;
  justify-content: center;
}
.c-grp-ill {
  /* display: none; */
  width: 100%;
  height: auto;
  background: green;
}
.dashboard-content-wrapper .c-grp-container .c-grp-img {
  width: 90%;
  height: 90%;
}
.dashboard-content-wrapper .c-grp-container .gc-info-container {
  width: 100%;
  height: 100%;
  /* min-width: 490px; */
  margin: 0;
  /* background: red; */
  border-radius: 4px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
  padding-bottom: 32px;
}
.dashboard-content-wrapper .c-grp-container .gc-info {
  width: 100%;
  height: 100%;
  margin: 0;
  /* background: yellow;   */
}
.dashboard-content-wrapper .c-grp-container .gc-info-wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 32px;
  border: 1.5px solid var(--themeColor);
  border-radius: 4px;
  background: #fff;
  /* box-shadow: 0 0 3px 0 rgba(0, 157, 220, 0.45), inset 0 2px 3px 0 rgba(0, 157, 220, 0.3); */
  /* background: green;   */
}
.dashboard-content-wrapper .c-grp-container .gc-heading {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  color: var(--themeColor);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
}
.dashboard-content-wrapper .c-grp-container .gc-content {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(33, 42, 57, 0.5);
  font-size: 16px;
  font-weight: 400;
}
.dashboard-content-wrapper .c-grp-container .c-grp-form {
  width: 100%;
  padding: 30px;
  padding-right: 10px;
}
.dashboard-content-wrapper .c-grp-container .cg-inp {
  width: 65%;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 19.2px;
  padding: 10px;
  outline: none !important;
  color: rgba(33, 42, 57, 0.75);
  font-weight: 600;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
}
.dashboard-content-wrapper .c-grp-container .cg-inp:focus {
  border: 1.5px solid var(--themeColor);
}
.dashboard-content-wrapper .c-grp-container .cg-form-label {
  color: rgba(33, 42, 57, 0.75);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.dashboard-content-wrapper .c-grp-container .cg-btn,
.join-grp-form .jg-btn {
  width: calc(100%);
  /* margin-left:12px; */
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  background-color: var(--themeColor);
  border-radius: 4px;
  color: #fff;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding-top: 14px;
  padding-bottom: 13px;
  outline: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.dashboard-content-wrapper .c-grp-container .cg-btn:hover,
.join-grp-form .jg-btn:hover {
  /* background: rgb(38,78,76);     */
  opacity: 0.9;
}
.dashboard-content-wrapper .c-grp-container .cg-err-web,
.join-grp-form .jg-err-web {
  display: block;
  margin-bottom: 24px !important;
}
.dashboard-content-wrapper .join-grp-form .jg-err-mb {
  display: none;
}
.dashboard-content-wrapper .c-grp-container .cg-err-mb {
  display: none;
}
.join-grp-form .jg-err-mb {
  /* display:none; */
}
.dashboard-content-wrapper .c-grp-container .err-msg,
.join-grp-form .err-msg,
.pf-content-container .err-msg {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  outline: none !important;
  color: #e94b35;
  font-weight: 600;
  margin: 0;
}
.dashboard-content-wrapper .c-grp-container .err-icon,
.join-grp-form .err-icon {
  margin-right: 5px;
}
.tooltip-inner {
  font-family: "Source Sans Pro", sans-serif;
  background-color: #212a39 !important;
  font-weight: 400;
}
.arrow::before {
  border-top-color: #212a39 !important;
}
.dashboard-content-wrapper .profile-img,
.group-db-content-wrapper .profile-img {
  display: block;
  /* margin: 0 auto; */
  height: 120px;
  width: 120px;
  border-radius: 24px;
  z-index: 50;
  object-fit: contain;
  /* cursor: pointer; */
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
}
.dashboard-content-wrapper.img-container {
  display: block;
  position: relative;
  margin: 0 auto;
  height: 120px;
  width: 120px;
  border-radius: 50%;
}
.grp-dtl-container .switch-container {
  position: absolute;
  margin: 0;
  left: 180px;
  top: 20px;
}
.cgrp-form-container .switch-container {
  /* background: red; */
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  /* height: 20px; */
  margin: 0 auto;
  width: 375px;
  max-width: 100%;
  margin-bottom: 15px;
}
.cgrp-form-container .switch-label {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  margin: 0;
  display: inline-block;
  font-weight: 600;
  width: 80%;
}
.cgrp-form-container .admin-switch {
  display: inline-block;
  float: right;
  max-width: 20%;
}
.cgrp-form-container .switch-description {
  /* max-width: 80%; */
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  height: max-content;
  /* width: calc(358px); */
  width: 80%;
  color: rgba(33, 42, 57, 0.5);
  /* margin: 0 auto; */
  margin-bottom: 15px;
}
.cgrp-form-container .des-container {
  width: 375px;
  margin: 0 auto;
  max-width: 100%;
  /* background: red; */
  height: max-content;
}
.dashboard-content-wrapper .edit-img,
.group-db-content-wrapper .edit-img {
  width: 33px;
  height: 33px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: -40px;
  cursor: pointer;
  z-index: 51;
  border-radius: 50%;
  position: relative;
  color: rgba(255, 255, 255, 0.9);
  /* font-size:20px; */
  box-shadow: 0 2px 4px 0 rgba(33, 42, 57, 0.25);
  /* text-align: center; */
}
.dashboard-content-wrapper .edit-img-container .pencil-icon,
.group-db-content-wrapper .edit-img-container .pencil-icon {
  -webkit-text-stroke: 0.8px var(--themeColor);
  vertical-align: middle;
}
.dashboard-content-wrapper .edit-img-container,
.group-db-content-wrapper .edit-img-container {
  padding-left: 80px;
}
.dashboard-content-wrapper .edit-img-container:hover {
  /* rotate:  */
}
.nf-wrapper {
  width: 100%;
  height: 100px;
  /* background: red; */
  margin-top: 80px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: max-content;
}
#edit-img-id {
  width: 100%;
  height: 100%;
}
.nf-wrapper .nf-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.nf-wrapper .back-btn {
  width: 65%;
  min-width: 375px;
  height: 50px;
  font-weight: 600;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  background: var(--themeColor);
  border: 0;
  font-size: 16px;
  border-radius: 4px;
}
.nf-wrapper .back-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.nf-wrapper .back-btn:focus {
  outline: none;
}
.nf-wrapper .nf-img {
  margin-top: 5vh;
  height: 40vh;
  width: auto;
  max-width: 80%;
}
.nf-wrapper .se-img {
  margin-top: 5vh;
  height: 45vh;
  width: auto;
  max-width: 90%;
}
.nf-wrapper .nf-msg {
  margin-top: 50px;
  /* background: green; */
  margin-bottom: 40px;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: rgba(33, 42, 57, 0.75);
  font-size: 24px;
}
.test1 {
  width: 300px;
  height: 300px;
}
.test-img {
  width: 100%;
  height: 100%;
}
.img-crop-container {
  /* width: 300px; */
  /* height: 300px; */
  display: flex;
  justify-content: center;
  /* background: blue; */
}
.dashboard-content-wrapper .content-header-container,
.content-header-container {
  padding: 0;
  display: none;
}
.dashboard-content-wrapper .home-btn-wrapper-first {
  height: 160px;
  padding: 0;
  padding-right: 20px;
  padding-bottom: 10px;
}
.dashboard-content-wrapper .home-btn-wrapper-mid {
  height: 160px;
  padding: 0;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}
.dashboard-content-wrapper .home-btn-wrapper-last {
  height: 160px;
  padding: 0;
  padding-left: 20px;
  padding-bottom: 10px;
}
.dashboard-content-wrapper .home-btn-row {
  margin: 0 auto;
  display: block;
  /* background: yellow; */
  /* overflow: visible; */
  /* max-width: 880px;  */
  display: flex;
}

.dashboard-content-wrapper .home-btn {
  cursor: pointer;
  background: #fff;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  /* filter: grayscale(100%); */
}
/* .dashboard-content-wrapper .home-btn:before
{
	content: '';
	position: absolute;
	top: 0;
	left: 180%;
	height: 100%;
	width: 100%;
	background: rgba(0,0,0,.03);
	z-index: 1;
	transform: skew(45deg);
	transition: .5s;
  } */
/* .dashboard-content-wrapper .home-btn:hover:before
{
	left: -180%;
  } */
.dashboard-content-wrapper .home-btn:hover {
  /* box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.7); */
  box-shadow: 0 8px 16px 2px rgba(207, 224, 235, 0.7);
  /* transform: scale(1.1); */
  /* filter: grayscale(0%); */
}
.dashboard-content-wrapper .home-btn .home-btn-img {
  margin: 0 auto;
  display: block;
  height: 66px;
  width: 66px;
}
.dashboard-content-wrapper .home-btn .home-btn-title {
  padding-top: 8px;
  color: #212a39;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
}
.dashboard-content-wrapper .home-btn .home-btn-content {
  margin-top: 22px;
}
.dashboard-content-wrapper .home-bg-img-container {
  width: 100%;
  margin: 0 auto;
  /* max-width: 880px;  */
  position: relative;
  width: 100vw;
  /* background: red!important; */
  height: calc(100vh - 280px);
}
.dashboard-content-wrapper .cg-bg-img-container {
  width: 100%;
  margin: 0 auto;
  /* max-width: 880px;  */
  position: relative;
  width: 100vw;
  height: calc(100vh - 430px);
}
.dashboard-content-wrapper .home-bg-img-wrapper {
  display: flex;
  width: 100%;
  margin-top: 80px;
  /* position: relative; */
  height: 480px;
}
.dashboard-content-wrapper .home-bg-img {
  position: absolute;
  width: 100%;
  bottom: 0;
  transition: all 1.3s ease;
  -webkit-transition: all 1.3s ease !important;
  /* margin-bottom: -400px; */
}
.dashboard-content {
  overflow: hidden;
  /* max-width:880px; */
  /* top right bottom left */
  padding: 48px;
  /* overflow: hidden; */
  /* padding-bottom: 760px; */
  /* width: 720px; */
  margin: auto;
}

.dashboard-content .explore-container {
  /* max-width: 880px;  */
  margin: 0 auto;
  padding: 0 50px;
  /* margin-top: -14px; */
}
.dashboard-content .pf-content-container {
  padding: 12px;

  width: 100%;
  height: auto;
}
.pf-content-container .epf-err-msg {
  margin: 0;
  padding: 0;
  width: 375px;
  max-width: 100%;
  margin: 0 auto;
}
.dashboard-content .ap-content-container {
  margin-top: 38px;
  width: 100%;
  height: auto;
}
.dashboard-content .role-img-container {
  width: 375px;
  max-width: 100%;
  margin-bottom: 0;
  margin: 0 auto;
}
.dashboard-content .role-img-container .role-img {
  display: block;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin: 0 auto;
  /* object-fit: cover; */
  /* border: solid 1.3px  rgb(38,78,76); */
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
}
.dashboard-content .role-img-container .img-active {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: 3px;
  right: 0px;
}
.dashboard-content .role-img-container .role-img:hover {
  /* border: solid 1.3px  rgb(38,78,76); */
  /* box-shadow: 0 6px 6px 1px rgba(0, 0, 0, 0.1); */
  box-shadow: 0 4px 15px 0 rgba(207, 224, 235, 0.7);
}
.dashboard-content .role-img-container .role-name {
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  margin-top: 10px;
  text-align: center;
}
.dashboard-content .role-img-container .role-img-col {
  cursor: pointer;
  padding: 0;
}
.dashboard-content .role-img-container .imgs-container {
  width: max-content;
  position: relative;
}
.dashboard-content .role-img-container .imgs-wrapper {
  display: flex;
  justify-content: center;
}
.dashboard-content .role-img-container .active {
  /* border: solid 2px  rgb(38,78,76); */
}
.dashboard-content .role-img-container .active:hover {
  /* border: solid 2px  rgb(38,78,76); */
}
.dashboard-content .pf-name {
  width: 375px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: rgba(33, 42, 57, 0.75);
}
.dashboard-content .ps-update-btn {
  width: 375px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  background-color: var(--themeColor);
  border-radius: 4px;
  color: #fff;
  border: none;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  outline: none;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
}
.dashboard-content .ps-update-btn:hover {
  /* background: rgb(38,78,76); */
  opacity: 0.9;
}

.pe {
  max-width: 450px;
}

.pe-img {
  margin: 30px auto;
  display: block;
}
.pe-head {
  color: #212a39;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  width: 100%;
  padding: 10px 0;
}
.pe-drpdwn {
  font-size: 18px;
  opacity: 0.75;
  font-family: "Source Sans Pro";
  text-align: left;
  min-width: 308px;
  max-width: 350px;
  background-color: #ffffff;
  border: none;
  color: #212a39;
  margin: 0 auto;
  display: block;
}
.pe-drpdwn:hover {
  background: #ffffff;
  color: #212a39;
}
.pe-drpdwn i {
  float: right;
  font-size: 24px;
  margin-left: 10px;
}
.pe-error {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 15px 20px;
  text-align: left;
  display: flex;
  align-items: center;

  color: #f16f6b;
}

.pe-cng-drpdwn {
  opacity: 0.75;
  text-align: left;
  background-color: #ffffff;
  color: #212a39;
  max-width: 100%;
  width: 375px;
  height: 48px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  display: block;
  margin: 0 auto;
  outline: none !important;
  color: #212a39;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
  margin-bottom: 20px;
}
.pe-cng-drpdwn:hover {
  background: #ffffff;
  color: #212a39;
}
.pe-cng-drpdwn i {
  float: right;
  font-size: 24px;
  margin-left: 10px;
}
.sc-rm-fnsh {
  display: block;
  margin: 0 auto;
  background-color: var(--ctaColor);
  border-radius: 5px;
  width: 100%;
  max-width: 343px;
  height: 48px;
  font-size: 18px;
  font-family: "Source Sans Pro";
  font-weight: 600;
}
.pe-ad-ml {
  margin: 15px auto 5px auto;
  width: 67%;
  display: block;
}
.pe-ad-ml i {
  color: var(--themeColor);
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}
.pe-ad-ml span {
  color: var(--themeColor);
  font-size: 18px;
  margin-left: 15px;
  font-weight: 600;
  font-family: "Source Sans Pro";
  cursor: pointer;
}
.pe-cnf-btn {
  font-family: "Source Sans Pro";
  display: block;
  width: 74%;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  margin: 20px auto 10px auto;
  background: var(--ctaColor);
  outline: none;
}
.pe-cnf-btn__otp {
  font-family: "Source Sans Pro";
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  margin: 20px auto 10px auto;
  background: var(--ctaColor);
  outline: none;
}
.pe-cnf-btn:hover {
  opacity: 0.9;
  /* background: var(--themeColor); */
}
.pe-vrfy-btn {
  font-family: "Source Sans Pro";
  display: block;
  width: 74%;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  margin: 0 auto 20px auto;
  background: var(--ctaColor);
  outline: none;
}
.pe-vrfy-btn:hover {
  opacity: 0.9;
  background: var(--ctaColor);
}
.pe-cnf-info {
  color: #212a39;
  font-family: "Source Sans Pro";
  font-size: 18px;
  line-height: 24px;
  opacity: 0.75;
  text-align: center;
  margin: 10px auto 50px auto;
}
.pe-vrfy-info {
  color: #212a39;
  font-family: "Source Sans Pro";
  font-size: 18px;
  line-height: 24px;
  opacity: 0.75;
  text-align: center;
  margin: 10px auto 20px auto;
}
.pe-info {
  font-family: "Source Sans Pro";
  display: block;
  width: 80%;
  margin: 20px auto 20px auto;
  text-align: center;
}
.pe-info2 {
  font-family: "Source Sans Pro";
  display: block;
  width: 90%;
  margin: 20px auto 20px auto;
  text-align: left;
}
.pe-adnew {
  margin-top: 3rem;
  max-width: 450px;
}
.pe-adnew-head {
  color: #212a39;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  width: 86%;
  padding: 10px 0;
  margin: 20px auto 0 auto;
}
.pe-adnew-head i {
  float: left;
  font-size: 20px;
  margin-top: 10px;
  cursor: pointer;
}
.pe-adnew-inp {
  font-family: "Source Sans Pro";
  display: block;
  margin: auto auto 0 auto;
  width: 90%;
  background-color: #ffffff;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
  height: 48px;
  text-align: center;
}
.pe-adnew-inp::placeholder {
  text-align: center;
}
.pe-adnew-btn {
  display: block;
  margin: 25px auto 40px auto;
  width: 90%;
  height: 48px;
}
.pe-adnew-btn-1 {
  color: var(--themeColor);
  border-color: var(--themeColor);
  float: left;
  width: 48%;
  height: 100%;
  font-size: 18px;
  font-family: "Source Sans Pro";
}
.pe-adnew-btn-1:hover,
.pe-adnew-btn-2:hover {
  background: rgb(38, 78, 76);
}
.pe-adnew-btn-2 {
  float: right;
  width: 48%;
  height: 100%;
  font-size: 18px;
  font-family: "Source Sans Pro";
  background: var(--themeColor);
}

.dashboard-content .ps-updt-mail {
  width: 375px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.dashboard-content .ps-updt-mail-btn {
  width: 48%;
  float: right;
  display: inline-block;
  margin: 0 auto;
  background-color: var(--themeColor);
  border-radius: 4px;
  color: #fff;
  border: 1px solid var(--themeColor);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  outline: none;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
}
.dashboard-content .ps-updt-mail-btn:hover {
  /* background: rgb(38,78,76); */
  opacity: 0.9;
}
.dashboard-content .ps-updt-mail-btn-1 {
  width: 48%;
  display: inline-block;
  border-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  outline: none;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
}
.dashboard-content .ps-updt-mail-btn-1:hover {
  background-color: #e94b35;
  color: #fff;
}
.dashboard-content .g-desc {
  width: 375px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  color: rgba(33, 42, 57, 0.75);
  margin-bottom: 16px;
  outline: none;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 16px;
  height: 191px;
}
.dashboard-content .dp-btn {
  width: 375px;
  max-width: 100%;
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #e94b35;
  color: #e94b35;
  outline: none;
  /* border:none; */
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.dashboard-content .dp-btn:hover {
  background: #e94b35;
  color: #fff;
}
.dashboard-content .ps-inp {
  max-width: 100%;
  width: 375px;
  height: 48px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  outline: none !important;
  display: block;
  margin: 0 auto;
  /* margin-bottom:20px; */
  color: #212a39;
  font-weight: 600;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
  margin-bottom: 20px;
}

.dashboard-content .pwd-inp {
  font-family: "Source Sans Pro", sans-serif;
  width: 375px;
  max-width: 100%;
  font-size: 18px;
  outline: none !important;
  display: block;
  margin: 0 auto;
  border-radius: 4px;
  /* margin-bottom:20px; */
  color: #212a39;
  font-weight: 600;
  border-radius: 4px;
  margin-bottom: 20px;
}
.pwd-scs {
  height: 104px;
  margin-top: 35%;
}
.pwd-msg-sc {
  font-family: "Source Sans Pro", sans-serif;
  width: 328px;
  max-width: 100%;
  font-size: 18px;
  outline: none !important;
  display: block;
  margin: 0 auto;
  border-radius: 4px;
  /* margin-bottom:20px; */
  color: #212a39;
  font-weight: 600;
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}
/* Chrome, Safari, Edge, Opera */
/* hide up and down arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.info-msg {
  font-family: "Source Sans Pro", sans-serif;
  max-width: 100%;
  outline: none !important;
  display: block;
  margin: 0 auto;
  border-radius: 4px;
  margin-top: 20px;
  color: #212a39;
  font-size: 14px;
  line-height: 20px;
  opacity: 0.5;
  width: 358px;
  text-align: center;
}
.pwd-otp {
  font-family: "Source Sans Pro", sans-serif;
  width: 375px;
  max-width: 100%;
  font-size: 14px;
  outline: none !important;
  display: block;
  margin: 0 auto;
  border-radius: 4px;
  /* margin-bottom:20px; */
  color: rgba(33, 42, 57, 0.75);
  border-radius: 4px;
  margin-bottom: 20px;
  text-align: center;
}
.pwd-otp-gt-cl {
  float: left;
  text-align: left;
  cursor: pointer;
}
.pwd-otp-tmr {
  text-align: center;
  color: var(--themeColor);
  font-weight: 600;
}
.pwd-otp-rs {
  float: right;
  text-align: right;
  cursor: pointer;
}
.dashboard-content .pwd-ccd {
  width: 30%;
  height: 52px;
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #ffffff;
  outline: none !important;
}
.dashboard-content .pwd-ccd-vl {
  color: #212a39;
  opacity: 1;
  height: 52px;
  font-family: "Source Sans Pro", sans-serif;
}
.dashboard-content .pwd-phn {
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 70%;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-left: 1.5px transparent;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: "Source Sans Pro", sans-serif;
  height: 52px;
  font-weight: 600;
  outline: none !important;
}
.dashboard-content .ps-inp-container {
  margin-bottom: 20px;
}
.dashboard-content .ps-inp-ta {
  max-width: 100%;
  width: 375px;
  height: 120px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 19.2px;
  padding: 8px;
  outline: none !important;
  display: block;
  margin: 0 auto;
  margin-bottom: 20px;
  color: rgba(33, 42, 57, 0.75);
  font-weight: 600;
  border: 1.5px solid rgba(33, 42, 57, 0.25);
  border-radius: 4px;
}
.err-inp,
.err-inp:active,
.err-inp:focus {
  border: 1.5px solid #e94b35 !important;
}
.dashboard-content .add-profile-err {
  max-width: 100%;
  width: 375px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  outline: none !important;
  display: block;
  margin: 0 auto;
  margin-bottom: 8px;
  margin-top: -10px;
  color: #e94b35;
  font-weight: 600;
  padding-left: 5px;
}
.dashboard-content .add-profile-err-msg {
  padding-left: 5px;
}

.testing {
  /* width: 40px; */
  /* height: 40px; */
  /* background: red; */
}

.home-dashboard-header .web-search-btn {
  font-size: 22px;
  position: absolute;
  right: 0;
  line-height: 2;
  font-weight: 100 !important;
  background: transparent;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 0px;
  color: rgba(33, 42, 57, 0.3);
  outline: none !important;
  margin-left: 30px;
  transition: all 2.3s ease;
  -webkit-transition: all 2.3s ease !important;
  -webkit-text-stroke: 1px white;
}

.home-dashboard-header .search-inp-web {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 19.2px;
  border: 0;
  padding: 5px;
  width: 420px;
  margin-left: 10px;
  line-height: inherit;
  color: rgba(33, 42, 57, 0.6);
  outline: none !important;
  border-left: 2px solid var(--themeColor);
}
.home-dashboard-header .search-container {
  display: inline-block;
  vertical-align: 25%;
}
.home-dashboard-header .search-close {
  font-size: 27px;
  vertical-align: -10%;
  color: rgba(33, 42, 57, 0.3);
  -webkit-text-stroke: 3.5px #fff;
  cursor: pointer;
}

.home-dashboard-header .profile-pic {
  font-size: 16px;
  color: #82849f;
  background: transparent;
  border: none;
  outline: none !important;
  padding-bottom: 0;
}
.home-dashboard-header .profile-pic .profile-img {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  margin-top: 2px;
  object-fit: contain;
}
.profile-img {
  border-radius: 24px;
  height: 120px;
  width: 120px;
  /* margin-top: -5px; */
  /* object-fit: contain; */
}

@media only screen and (max-width: 420px) {
  .profile-img {
    width: 40px;
    height: 40px;
    margin-top: 0px;
  }
}

.home-dashboard-header .header-center {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% - 215px - 230px);
  flex: 1;
}
.gcs-container {
  width: 90%;
  max-width: 400px;
  display: flex;
  align-self: center;
  height: 60px;
  min-height: max-content;
  background: #fff;
  border-radius: 4px;
  position: fixed;
  bottom: 12px;
  margin: 0 auto;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
  /* border: 1px solid var(--themeColor); */
  box-shadow: 0 4px 15px 0 rgba(207, 224, 235, 0.5);
  z-index: 211;
}
.gcs-hide {
  bottom: -112px;
}
.gcs-container .gc-strip {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  /* background: rgba(207, 224, 235,0.5);  */
  background: #212a39;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
}
.gcs-container .cross {
  position: absolute;
  right: 16px;
  top: 21px;
  display: block;
  cursor: pointer;
}
.gcs-container .gcs-msg {
  color: #fff;
  font-family: var(--SourceSansPro);
  font-size: 16px;
  margin: 0;
  padding: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  /* background: red; */
  text-align: center;
  /* margin-top:20px; */
}
.my-grp-container {
  padding: 0 50px;
  width: calc(100%);
  /* /* max-width: 880px;  /*
  /* background: yellow; */
}
.my-grp-container .grp-card {
  width: 100%;
  position: relative;
  /* min-height: 230px; */
  height: var(--height);
  background: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
}
.my-grp-container .grp-img-container {
  width: 100%;
  /* min-height: 140px; */
  /* height: calc(70%); */
  height: calc(100% - 96px);
  background: rgba(207, 224, 235, 0.5);
}
.my-grp-container .grp-name-container {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* height: 30%; */
  max-height: 96px;
  padding-top: 24px;
  /* background: red; */
  border-radius: 4px;
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
}
.my-grp-container .card-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  /* background: #fff; */
}
.my-grp-container .card-cover:hover {
  box-shadow: 0 8px 16px 2px rgba(207, 224, 235, 0.7);
  /* box-shadow: 0 4px 10px 0 rgba(207, 224, 235, 0.8); */
}

.my-grp-container .grp-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.search-menu {
  background: #fff;
  position: absolute;
  width: 490px;
  left: 32px;
  margin-top: 10px;
  padding: 5px;
}
.user-menu {
  background: #fff;
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
  width: 210px;
  right: 1px;
  margin-top: 26px;
  /* padding:5px; */
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  animation: hidingMenu 0.2s ease-in-out;
}
.user-notification-menu {
  background: #fff;
  position: absolute;
  max-height: 500px;
  overflow-y: auto;
  width: 450px;
  right: 132px;
  margin-top: 40px;
  padding: 10px 20px;
  border-radius: 4px;
  /* box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5); */
  animation: hidingMenu 0.2s ease-in-out;
  box-shadow: 0px 8px 24px rgba(33, 42, 57, 0.15);
}
.notification-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with 50% opacity */
  backdrop-filter: blur(10px); /* Blur the background */
  display: flex;
  justify-content: center;
  align-items: center; /* Center the popup */
  z-index: 9999; /* Ensure it's on top of everything */
}

.notification-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.notification-popup-content button {
  padding: 10px 20px;
  background-color: #035642;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.notification-popup-content button:hover {
  background-color: #035642;
}

.badge.badge-danger {
  font-size: 75% !important;
  position: absolute;
  margin-left: -10px;
  margin-top: -2px;
}
@keyframes hidingMenu {
  from {
    transform: scale(0);
  }
  to {
    height: scale(1);
  }
}
.grp-dd-menu {
  background: #fff;
  position: absolute;
  width: 210px;
  z-index: 1;
  margin-left: -20px;
  /* margin-top: 38px; */
  padding-top: 5px;
  padding-bottom: 5px;
  /* padding:5px; */
  /* padding-left: 10px; */
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
}
.grp-dd-list {
  list-style: none;
  margin: 0;
  color: rgba(33, 42, 57, 0.75);
  padding: 0;
}
.grp-dd-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
}
.grp-dd-link {
  color: rgba(33, 42, 57, 0.75);
}
.grp-dd-item:hover {
  color: var(--themeColor);
  font-weight: 600;
  background: rgba(207, 224, 235, 0.5);
  /* border-left: 4px solid var(--themeColor); */
  /* font-weight: 600; */
  cursor: pointer;
}
.lg-modal-container {
  /* width: 60%; */
  /* background: yellow;  */
  /* margin-right: 10%; */
}
.lg-modal-container .modal-content,
.dp-modal-container .modal-content,
.dpr-modal-container .modal-content {
  /* width: 300px; */
  width: 80%;
  margin-left: 10%;
}
.lg-modal,
.dp-modal,
.dpr-modal {
  /* background: red; */
  height: 200px;
  /* width: 80%; */
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}
.lg-modal .top-section,
.dp-modal .top-section,
.dpr-modal .top-section {
  height: calc(100% - 50px);
  width: 100%;
  /* background: red; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.lg-modal .heading,
.dp-modal .heading,
.dpr-modal .heading {
  color: #212a39;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  /* margin: 0 auto; */
  padding-top: 15px;
  /* background: yellow; */
  justify-content: center;
  /* align-content: center; */
}
.lg-modal .msg,
.dp-modal .msg,
.dpr-modal .msg {
  /* background: red; */
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}
.lg-modal .ngfc-msg {
  /* background: red; */
  height: calc(100% - 50px);
  width: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}
.lg-modal .btn-y,
.dp-modal .btn-y,
.dpr-modal .btn-y {
  width: 50%;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.6px solid rgba(33, 42, 57, 0.25);
  margin-top: 10px;
  margin-bottom: 10px;
}
.lg-modal .btn-n,
.dp-modal .btn-n,
.dpr-modal .btn-n {
  width: 50%;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.lg-modal .btn-y:hover,
.lg-modal .btn-n:hover,
.dp-modal .btn-y:hover,
.dp-modal .btn-n:hover,
.dpr-modal .btn-n:hover {
  color: var(--themeColor);
  font-weight: 600;
  background: rgba(207, 224, 235, 0.5);
  cursor: pointer;
}
.dpr-modal .btn-y:hover {
  color: #e94b35;
  font-weight: 600;
  background: rgba(233, 75, 53, 0.2);
  cursor: pointer;
}
.lg-modal .bottom-section,
.dp-modal .bottom-section,
.dpr-modal .bottom-section {
  height: 50px;
  width: 100%;
  /* background: yellow; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 0.6px solid rgba(33, 42, 57, 0.25);
  display: flex;
}
.user-list {
  padding: 0;
  margin: 0;
  list-style: none;
  /* padding-bottom: 12px; */
}
.user-list .hr {
  margin: 0;
  height: 0px;
  /* margin-left: 20px; */
  /* margin-right: 20px; */
  background: rgba(33, 42, 57, 0.005);
}
.img-canvas-container {
  width: max-content;
  height: max-content;
  background: red;
}
.user-list .user-add-switch {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100%);
  color: rgba(33, 42, 57, 0.75);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  cursor: pointer;
}
.user-list .user-add-switch:hover {
  background: rgba(207, 224, 235, 0.5);
  font-weight: 600 !important;
  color: var(--themeColor);
}
.user-list .user-add-profile {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  width: calc(100%);
  color: var(--themeColor);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  cursor: pointer;
}
.user-list .user-add-profile:hover {
  background: rgba(207, 224, 235, 0.5);
  font-weight: 600 !important;
  color: var(--themeColor);
}
.user-list .user-switch-profile {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  /* margin-left:7px; */
  width: calc(100%);
  /* color: var(--themeColor); */
  color: rgba(33, 42, 57, 0.75);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  cursor: pointer;
}
.user-list .user-switch-profile:hover {
  background: rgba(207, 224, 235, 0.5);
  font-weight: 600;
  color: var(--themeColor);
}
.user-list .right-arrow {
  float: right;
  margin-top: 5px;
}
.user-list .left-arrow {
  margin-top: -2px;
  transform: rotate(180deg);
}
.blue-color {
  color: var(--themeColor);
}
.user-list .user-list-item {
  padding: 6px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  padding-left: 15px;
  cursor: pointer;
  color: rgba(33, 42, 57, 0.75);
  /* color: rgba(33, 42, 57, 0.5); */
}
.user-list .user-add-new {
  color: var(--themeColor);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  width: 100%;
  /* margin-left:7px; */
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  /* transition: all 0.5s ease; 
    -webkit-transition: all 0.5s ease ;
    border-top: 1px solid rgba(33, 42, 57, 0.15); */
}
.user-list .user-add-new:hover {
  background: rgba(207, 224, 235, 0.5);
  font-weight: 600;
  color: var(--themeColor);
}
.fa-lighter {
  -webkit-text-stroke: 1px white;
}
.user-list .profile-pic-container {
  height: 40px;
  padding: 5px;
  padding-left: 0;
}
.user-list .profile-dtls {
  padding: 0;
  padding-left: 3px;
}
.user-list .user-list-row {
  width: 100%;
  padding: 0;
  margin: 0;
  padding-top: 10px;
}
.user-list .profile-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.user-list .profile-name {
  padding: 0;
  margin: 0;
  padding-bottom: 1px;
  /* color: rgba(33, 42, 57, 0.75); */
  font-family: "Source Sans Pro", sans-serif;
}

.user-list .profile-role {
  padding: 0;
  margin: 0;
  color: rgba(33, 42, 57, 0.5);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}

.user-list .user-list-item:hover {
  background: #f9f6f6;
  font-weight: 600;
  color: var(--themeColor) !important;
}

.user-list .user-list-item:hover {
  img {
    color: var(--themeColor) !important;
    fill: var(--themeColor) !important;
  }
}
.user-list
  .user-list-item:hover
  > div:nth-child(1)
  > div:nth-child(2)
  > p:nth-child(2) {
  /* background: rgba(207, 224, 235,0.5); */
  font-weight: 400;
  color: var(--themeColor) !important;
}

.user-list .see-all-profile {
  padding-bottom: 8px;
  color: var(--themeColor);
}
.float-btn {
  color: var(--themeColor) !important;
  position: static !important;
}
.course-container {
  width: 100%;

  /* background: red; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.course-content {
  width: 100%;
  /* /* max-width: 880px;  /*
  margin-bottom: 16px;
  /* display: flex; */
  /* align-items: center; */
}
@media only screen and (min-width: 800px) {
  /* .course-content {
    padding: 0 50px;
  } */
}
.course-container .crs_hr {
  margin: 0;
  margin-top: 24px;
  margin-bottom: 32px;
}
.course-container .crs_hr_2 {
  margin: 0;
  height: 0;
  width: 0;
  margin-top: 16px;
  margin-bottom: 16px;
}
.crs-heading {
  color: rgba(0, 0, 0, 0.75);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 600;
  /* margin:0; */
}
.course-container .cc-container:hover {
  cursor: pointer;
  box-shadow: 0 4px 15px 0 rgba(207, 224, 235, 0.7);
}
.course-container .ic-container {
  width: 100%;
  height: 152px;
  background: #fff;
  margin-top: 16px;
  /* box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5); */
  border-radius: 16px;
  display: flex;
  position: relative;
}
.course-container .dum-container {
  width: 100%;
  height: 100%;
  display: none;
  /* background: red; */
}
.course-container .dum-mb-1 {
  width: calc(45% - 16px);
  height: 20px;
  /* margin-bottom: 40px; */
  position: relative;
  margin-left: 16px;
  background: rgba(207, 224, 235, 0.5);
  margin-top: 16px;
  overflow: hidden;
  border-radius: 20px;
}
.course-container .dum-mb-2 {
  width: calc(60% - 16px);
  height: 20px;
  position: relative;
  overflow: hidden;
  margin-left: 16px;
  background: rgba(207, 224, 235, 0.5);
  margin-top: 16px;
  border-radius: 20px;
}
.course-container .dum-mb-3 {
  width: calc(90% - 16px);
  height: 20px;
  position: relative;
  overflow: hidden;
  margin-left: 16px;
  background: rgba(207, 224, 235, 0.5);
  margin-top: 16px;
  border-radius: 20px;
}
.course-container .ic-container:hover {
  cursor: pointer;
  /* box-shadow: 0 4px 15px 0 rgba(207, 224, 235, 0.7); */
  box-shadow: 0 8px 16px 1px rgba(3, 86, 66, 0.08);
}
.course-container .cc-container {
  width: 100%;
  height: 150px;
  background: #fff;
  margin-top: 16px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  border-radius: 4px;
  display: flex;
  z-index: 52;
  position: relative;
}
.course-container .img-section {
  width: 200px;
  padding: 12px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.course-container .img-section-mb {
  display: none;
}
.course-container .ccrs-name,
.course-container .ccrs-name-wrapper {
  display: none;
}
.course-container .progress-section {
  width: calc(100% - 510px);
  /* background: yellow; */
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 4px;
}
.course-container .progress-section-ss {
  width: calc(100% - 410px);
  /* background: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  padding-left: 15px;
}
.course-container .crs-name {
  color: #212a39;
  width: 80%;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.course-container .btn-section {
  width: 310px;
  /* background: green; */
  display: flex;
  justify-content: flex-end;
  padding-right: 24px;
  align-items: center;
}
.course-container .crs-start-btn {
  border: 1px solid var(--themeColor);
  border-radius: 8px;
  height: 48px;
  width: 170px;
  background: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: var(--themeColor);
  font-weight: 600;
  outline: none;
  z-index: 101;
}
.course-container .crs-start-btn:hover {
  border: 1px solid var(--themeColor);
  background: var(--themeColor);
  color: var(--textColor);
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.course-container .crs-resume-btn {
  border-radius: 8px;
  height: 48px;
  width: 170px;
  border: 1px solid var(--themeColor);
  background: #fff;
  color: var(--themeColor);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 600;
  z-index: 101;
  outline: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.course-container .crs-resume-btn:hover {
  /* border: 1px solid rgb(38, 78, 76); */
  border: 1px solid var(--themeColor);
  /* background: var(--ctaColor); */
  background: var(--themeColor);
  color: var(--textColor);
}
.course-container .ss-btn {
  /* border: 1px solid  rgba(207, 224, 235, 0.5); */
  border-radius: 2px;
  height: 48px;
  position: relative;
  overflow: hidden;
  width: 170px;
  background: rgba(207, 224, 235, 0.5);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  display: block;
}
.course-container .crs-img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  display: none;
}
.course-container .crs-img-container {
  width: 100%;
  height: 100%;
  background: rgba(207, 224, 235, 0.5);
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.course-container .progress-section .content-container {
  width: 100%;
}
.course-container .progress-bar {
  width: calc(100% - 60px); /* percent width + margin-left   */
  height: 5.3px;
  background-color: rgba(33, 42, 57, 0.1);
  border-radius: 5.5px;
}
.course-container .active-status {
  background: #4bb543;
  height: 100%;
  width: 0px;
  border-radius: 5.5px;
  transition: all 3.8s ease;
  -webkit-transition: all 3.8s ease;
}
.course-container .pb-container {
  display: flex;
  width: 50%;
  align-items: center;
  /* justify-content: center; */
  /* background: blue; */
}
.course-container .percent {
  width: 35px;
  margin-left: 25px;
  font-family: var(--SourceSansPro);
}
.loading-modal {
  height: 200px;
}
.pp-modal {
  padding: 32px;
}
.pp-modal .coupon-section {
  margin-top: 32px;
  margin-bottom: 32px;
}
.pp-modal .modal-heading {
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(33, 42, 57, 0.75);
  font-weight: 600;
  font-size: 18px;
  /* padding:16px; */
}
.pp-modal .payment-card {
  width: 100%;
  display: flex;
  height: 80px;
  position: relative;
  border-radius: 3px;
  margin-bottom: 16px;
  border: 1px solid rgba(33, 42, 57, 0.5);
  /* background: red; */
}
.pp-modal .close-btn {
  float: right;
  margin-top: 8px;
  cursor: pointer;
}
.pp-modal-container {
  width: 100%;
  max-width: 596px;
  /* margin-right: 300px; */
  /* margin: 0 auto; */
  /* padding-left: 300px; */
  /* margin-left: 651px; */
  /* width: 400px; */
}
.pp-modal .pk-duration {
  color: rgba(33, 42, 57, 0.75);
  font-family: var(--SourceSansPro);
  font-size: 42px;
}
.pp-modal .pk-unit {
  /* vertical-align: top; */
  /* line-height: 42px; */
  margin-top: 18px;
}
.center-modal-home {
  margin-left: 1000px;
}
.modal {
  /* background: green; */
  margin-left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width));
}
.pp-modal .package-container {
  display: flex;
  width: 100%;
  max-width: max-content;
}
.impartus-button {
  background-color: white;
  display: block;
  margin: 0 auto;
  color: black;
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  padding: 0.7rem 0.8rem;
}
.pp-modal .btn-section {
  width: 65px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pp-modal .content-section {
  width: calc(100% - 65px - 150px);
  height: 100%;
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(33, 42, 57, 0.75);
  font-weight: 600;
  font-size: 18px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.pp-modal .rupee-section {
  display: flex;
  align-items: center;
  width: calc(150px);
  /* background: red; */
  font-family: var(--SourceSansPro);
  color: #212a39;
  font-size: 36px;
  font-weight: 600;
}
.pp-modal .price {
  /* background: yellow; */
  text-align: end;
  width: 100%;
  padding-right: 32px;
  margin: 0;
  height: max-content;
}
.pp-modal .active-border {
  border: 2px solid var(--themeColor);
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgba(139, 169, 188, 0.5);
}
.pp-modal .active-color {
  color: var(--themeColor);
}
.pp-modal .rs-sign {
  font-size: 20px;
  font-weight: 400;
  margin-left: -14px;
  margin-top: 10px;
  /* line-height: 36px; */
  /* margin-left: 16px; */
  position: absolute;
  /* margin-bottom: 15px; */
  /* vertical-align: top; */
  /* margin-top: 25px; */
}
.pp-modal .outer-circle {
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  border-radius: 50%;
  /* background: green; */
  border: 2px solid rgba(33, 42, 57, 0.75);
}
.pp-modal .outer-circle-a {
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  border-radius: 50%;
  /* background: green; */
  border: 2px solid var(--themeColor);
}
.pp-modal .footer {
  margin-top: 16px;
  width: max-content;
  float: right;
}
.pp-modal .back-btn {
  margin-right: 8px;
  color: rgba(33, 42, 57, 0.5);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  padding: 18px;
  border: 1px solid rgba(33, 42, 57, 0.5);
  border-radius: 4px;
  background: #fff;
  outline: none;
  width: 120px;
  font-weight: 600;
}
.pp-modal .btn-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.pp-modal .cc-input {
  width: 240px;
  height: 48px;
  font-family: var(--SourceSansPro);
  color: #212a39;
  background-color: rgba(207, 224, 235, 0.1);
  border: 1.1px solid rgba(33, 42, 57, 0.1);
  outline: none;
  text-align: center;
  border-radius: 2.2px;
  /* padding-left: 50px; */
  /* padding-right: 50px; */
  text-transform: uppercase;
}
.pp-modal .pay-btn {
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  padding: 18px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid var(--themeColor);
  outline: none;
  border-radius: 4px;
  background: var(--themeColor);
  /* width: 120px; */
  font-weight: 600;
  min-width: 120px;
}
.pp-modal .pay-msg {
  font-family: var(--SourceSansPro);
  text-align: center;
  color: var(--themeColor);
  font-weight: 600;
  font-size: 16px;
}
.pp-modal .apply-btn {
  margin-left: 16px;
  font-size: 16px;
  color: var(--themeColor);
  font-family: var(--SourceSansPro);
  cursor: pointer;
}
.pp-modal .applied-btn {
  margin-left: 16px;
  font-size: 16px;
  color: #31a128;
  font-family: var(--SourceSansPro);
}

.pp-modal .inner-circle {
  width: 8px;
  height: 8px;
  background: var(--themeColor);
  border-radius: 50%;
}

.pp-modal .success-img {
  width: 15px;
  height: 15px;
  margin-top: -4px;
}
.pp-modal .package-card {
  height: 200px;
  width: 160px;
  margin-left: 16px;
  background: red;
}
#explore_wrapper_id {
  /* background: red; */
  /* height: 0px; */
  width: 100%;
  display: block;
  /* margin: 0; */
  /* max-width: 880px;  */
}
.edit-modal-container {
  /* background: green; */
  /* width: 700px; */
}
.edit-modal {
  /* overflow: scroll; */
  /* width: 100vw; */
}
.edit-modal .img-container {
  /* position: relative; */
}
.edit-modal .edit-modal-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  /* background: red; */
  z-index: 100;
}
.edit-modal .modal-header {
  color: #212a39;
  /* background: red; */
  margin: 0;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  /* font-weight: 600; */
  text-align: center;
  /* margin-top: 15px; */
  font-family: "Source Sans Pro", sans-serif;
}
.error-popover {
  width: 285px;
  padding: 12px;
  height: max-content;
  /* background: red; */
  position: fixed;
  background: #fff;
  z-index: 1700;
  bottom: 30px;
  left: 24px;
  /* top: 10px; */
  display: flex;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  border-radius: 4px;
  /* border-bottom-right-radius: 4px; */
}
.error-popover .close {
  width: 20px;
  /* background: blue; */
  /* height: 100%; */
}
.error-popover .ep-icon-container {
  width: 30px;
}
.error-popover .ep-content {
  width: calc(100% - 50px);
  /* color: #000; */
}
.error-popover .ep-msg {
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin: 0;
  /* color: #000; */
  /* background: red; */
  /* width: calc(100% - 20px); */
  /* text-align: center; */
}
.error-popover .ep-heading {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  color: #000;
  font-weight: 600;
}
.error-popover .close-img {
  width: 10px;
  cursor: pointer;
  height: 10px;
}
.error-popover .icon {
  width: 15px;
  height: 15px;
  margin-left: 8px;
}
.hide-error-popover {
  margin-left: calc(-400px - 24px);
}
.edit-modal .img-container {
  width: 100%;
  position: relative;
  height: max-content;
  background: rgba(33, 42, 57, 0.8);
}
.edit-modal .valid-msg {
  /* color: #E94B35; */
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
  padding: 8px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0;
  border-bottom: 0.75px solid rgba(33, 42, 57, 0.25);
}
.edit-modal .change-btn {
  float: right;
  color: var(--themeColor);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  background: transparent;
  border: 1px solid var(--themeColor);
  padding: 5px;
  outline: none;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
  border-radius: 4px;
}
.edit-modal .save-btn {
  float: right;
  outline: none;
  margin-left: 10px;
  color: var(--themeColor);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  background: transparent;
  border: 1px solid var(--themeColor);
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 4px;
}
.edit-modal .save-btn:disabled {
  color: rgba(37, 92, 168, 0.5) !important;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  border: 1px solid rgba(37, 92, 168, 0.5) !important;
  cursor: not-allowed;
  background: transparent !important;
}
.edit-modal .change-btn:hover,
.edit-modal .save-btn:hover {
  float: right;
  background: var(--themeColor);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: #fff;
  border: 1px solid var(--themeColor);
  outline: none;
  padding: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 8px;
  border-radius: 5px;
}

.edit-modal .del-btn {
  color: var(--themeColor);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  margin-top: 5px;
}
.edit-modal .del-btn:hover {
  text-decoration: underline;
}
.edit-modal .close-btn {
  margin-top: 8px;
  height: 10px;
  width: 10px;
  cursor: pointer;
}
.edit-modal .footer {
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
}
.cg-modal .heading {
  color: #212a39;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-top: 15px;
  font-family: "Source Sans Pro", sans-serif;
}
.cg-modal .msg {
  color: #212a39;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-top: 35px;
  font-family: "Source Sans Pro", sans-serif;
}
.cg-modal .footer {
  position: absolute;
  width: 100%;
  height: 50px;
  /* background: red; */
  bottom: 0;
  border-top: 1px solid rgba(33, 42, 57, 0.25);
}
.cg-modal {
  height: 200px;
  /* padding:8px; */
  position: relative;
}
.cg-modal .ok-btn {
  float: right;
  margin-right: 15px;
  margin-top: 10px;
  font-family: "Source Sans Pro", sans-serif;
  width: 50px;
  color: #fff;
  background: var(--themeColor);
  border: 0;
  border-radius: 4px;
  height: 30px;
}
.cg-modal .ok-btn:focus {
  outline: none;
}
.loader {
  /* position: absolute; */
  width: 100%;
  /* background: red; */
  height: calc(100vh - 60px);
  /* background: rgba(0,0,0,0.5); */
  /* opacity: 0.1; */
  display: flex;
  padding-left: 0px;
  justify-content: center;
  align-items: center;
  /* margin-left: 215px; */
}

.loader-2 {
  /* position: absolute; */
  width: 100%;
  /* background: red; */
  height: 100%;
  /* background: rgba(0,0,0,0.5); */
  /* opacity: 0.1; */
  display: flex;
  padding-left: 0px;
  justify-content: center;
  align-items: center;
  /* margin-left: 215px; */
}
.loader-center {
  padding-left: 0px !important;
  height: 100vh !important;
}
.loader-container .loader-fix {
  position: absolute;
  transform: translate(16px, 15px);
}
.loader-container .loader-rotate {
  position: absolute;
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  background-color: orange; /* defines the background color of the image */
  mask: url(https://cdn.pegasus.imarticus.org/images/Imarticus_Loader_Circle-02-1.svg)
    no-repeat center / contain;
  -webkit-mask: url(https://cdn.pegasus.imarticus.org/images/Imarticus_Loader_Circle-02-1.svg)
    no-repeat center / contain;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loader-container {
  width: 50px;
  height: 50px;
  position: relative;
  margin-left: -10px;
  /* background: red; */
}
.loader-msg-container {
  width: 500px;
  height: 100%;
  /* box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5); */
  border-radius: 5px;
  /* background: #fff; */
}
.loading-msg {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  margin: 0;
  margin-top: 28px;
}
.loader-custom {
  /* background: #31a128; */
  width: 100%;
  flex-direction: column;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-container .bg-div-01 {
  width: 100%;
  height: 12px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: rgba(255, 255, 255, 0.5);
  width: calc(100% - 40px);
  margin-left: 20px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  z-index: 51;
}
.course-container .bg-div-02 {
  width: 100%;
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: calc(100% - 80px);
  margin-left: 40px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  z-index: 50;
  margin-top: -20px;
}
.course-container .mb-section {
  display: none;
}
.grp-dtl-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  /* max-width: 880px;  */
  /* flex-direction: column; */
}
.grp-dtl-container .grp-img-section {
  background-color: rgba(207, 224, 235, 0.5);
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: calc(100% - 92px);
  position: relative;
  overflow: hidden;
}
.grp-dtl-container .img-gradient {
  height: 100%;
  display: none;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    transparent,
    transparent,
    transparent,
    transparent,
    transparent,
    transparent,
    rgba(0, 0, 0, 0.03),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.5)
  );
}
.grp-dtl-container .dum-1 {
  width: 30%;
  height: 15px;
  margin-top: 30px;
  margin-left: 15px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  background: rgba(207, 224, 235, 0.5);
}
.grp-dtl-container .grp-img-dtl {
  background: #fff;
  width: 100%;
  height: 92px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
}
.grp-dtl-container .current-status {
  height: 100%;
  background: #31a128;
  border-radius: 5.5px;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
}
.grp-dtl-container .g-code {
  width: 100%;
  color: rgba(33, 42, 57, 0.75);
  position: relative;
  /* background: blue; */
  font-size: 16px;
  font-weight: 600;
  height: 100%;
  line-height: 32px;
  width: 65%;
  display: flex;
  padding-left: 15px;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
}
.grp-dtl-container .admin-section {
  /* background: green; */
  width: 100%;
  font-size: 18px;
  height: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  width: 35%;
  font-weight: 400;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
}
.grp-dtl-container .leave-btn {
  right: 0;
  color: #e94b35;
  font-size: 16px;
  font-weight: 600;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
}
.grp-dtl-container .leave-btn:hover {
  text-decoration: underline;
}
.grp-dtl-container .setting-btn {
  cursor: pointer;
}
.grp-dtl-container .admin-section-mb {
  display: none;
}
.grp-dtl-container .grp-section-1 {
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.grp-dtl-container .grp-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  /* display: none; */
}
.grp-dtl-container .grp-img-name {
  position: absolute;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 22px;
  bottom: 0;
  left: 15px;
  line-height: 24px;
}
.grp-dtl-container .grp-img-code {
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  /* font-weight: 400; */
  font-size: 16px;
}
.options-img {
  cursor: pointer;
}
.grp-options-btn {
  border: 0;
  outline: none;
  background: none;
  margin-left: 20px;
}
.grp-options-btn:focus {
  outline: none;
}
.grp-dtl-container .crs-section {
  display: flex;
  width: 100%;
  /* height: 100px; */
  background: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.grp-dtl-container .crs-section {
  display: flex;
  width: 100%;
  /* height: 100px; */
  background: #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.grp-dtl-container .discuss-section,
.grp-dtl-container .quiz-section,
.grp-dtl-container .feed-section,
.grp-dtl-container .video-section {
  min-height: 74px;
  /* flex: 0 0 calc(50% - 12px ); */
  /* max-width: calc(50% - 12px); */
  max-height: max-content;
  /* width: 50%; */
  position: relative;
  background: #fff;
  /* max-height: max-content; */
  /* background: #fff; */
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  border-radius: 4px;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
}

.grp-dtl-container .course-section {
  min-height: 74px;
  width: 100%;
  /* overflow: hidden; */
  position: relative;
  background: #fff;
  /* max-height: max-content; */
  /* background: #fff; */
  margin-bottom: 20px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  border-radius: 4px;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
}
.grp-dtl-container .add-section {
  min-height: 54px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-top: 15px;
  border-radius: 4px;
  transition: all 0.8s ease;
  -webkit-transition: all 0.8s ease;
}
.grp-dtl-container .crs-section .img-section {
  /* display: flex; */
  width: 70px;
  height: 100%;
  padding: 15px;
}
.grp-dtl-container .card-heading {
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(33, 42, 57, 0.75);
  font-weight: 600;
  font-size: 18px;
  padding-top: 15px;
  margin: 0;
}
.grp-dtl-container .crs-container {
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  border-radius: 4px;
}
.grp-dtl-container .crs-name {
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(33, 42, 57, 0.75);
  font-weight: 400;
  font-size: 18px;
  height: 97px;
  overflow: hidden;
  /* padding-bottom: 5px; */
  /* line-height: 23px; */
  /* padding-top:12px; */
  margin: 0;
}
.ap-modal {
  width: 100%;
  /* background: #fff; */
  height: max-content;
  border-radius: 4px;
}
.ap-modal-wrapper {
  width: 400px;
}
.ap-modal-container {
  width: 100%;
  /* background: #fff; */
  padding: 35px;
  border-radius: 4px;
}
.ap-modal .heading {
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(33, 42, 57, 0.75);
  font-weight: 600;
  font-size: 16px;
}
.ap-modal-container .plugin-section {
  width: 100%;
  /* background: green; */
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 74px;
  border-radius: 4px;
  border: 1px solid #979797;
  margin-bottom: 15px;
}
.ap-modal-container .img-section {
  width: 80px;
  /* background: yellow; */
  padding-left: 20px;
}
.ap-modal-container .label {
  margin: 0;
  /* background: red; */
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(33, 42, 57, 0.75);
  font-weight: 600;
  font-size: 16px;
  width: calc(100% - 120px);
}
.ap-modal-container .add-btn {
  margin: 0;
  /* background: red; */
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  height: 50px;
  background: var(--themeColor);
  border: none;
}
.modal-cover {
  position: fixed;
  top: 0;
  background: red;
  width: 100vw;
  height: 100vh;
  z-index: 280;
}
.ap-modal-container .add-btn:focus {
  outline: none;
}
.ap-modal-container .checkbox-section {
  width: 50px;
  padding-left: 25px;
  /* background: red; */
  height: 74px;
  display: flex;
  align-items: center;
}
.course-container .dum-ss-1 {
  height: 10px;
  width: 30%;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  border-radius: 20px;
  background: rgba(207, 224, 235, 0.5);
  /* background: red; */
}
.course-container .dum-ss-2 {
  height: 10px;
  width: 90%;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  background: rgba(207, 224, 235, 0.5);
  /* background: red; */
}
.grp-dtl-container .crs-status {
  background: #fff;
  /* padding-bottom: 5px; */
  height: 49px;
  display: flex;
  padding-left: 10px;
  padding-right: 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.grp-dtl-container .crs-progress {
  width: calc(100% - 120px);
  display: flex;
  align-items: center;
}
.grp-dtl-container .resume-crs-btn {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--themeColor);
  font-size: 16px;
  cursor: pointer;
  font-family: "Source Sans Pro", sans-serif;
}
.grp-dtl-container .start-crs-btn {
  width: 100%;
  cursor: pointer;
  display: flex;
  padding-left: 60px;
  align-items: center;
  justify-content: flex-start;
  color: var(--themeColor);
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
}
.grp-dtl-container .right-arrow-blue {
  margin-left: 5px;
  margin-top: 3px;
}

.grp-dtl-container .progress-bar {
  width: calc(100% - 60px);
  height: 8px;
  border-radius: 8px;
  background: #efeded;
}
.grp-dtl-container .percent {
  width: 30px;
  margin-left: 10px;
  margin-right: 20px;
  /* color: var(--themeColor); */
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
}
.grp-dtl-container .view-container {
  width: 100%;
  margin-bottom: 24px;
  /* padding-right: 30px; */
  height: 375px;
  /* padding: 0; */
  /* margin:0; */
}
.grp-dtl-container .view-container-2 {
  /* background: green; */
  width: 100%;
  /* overflow-y: hidden;  */
  /* height: max-content; */
  /* padding: 0; */
  /* margin:0; */
}
.grp-dtl-container .section-row {
  margin-left: -12px;
  margin-right: -12px;
}
.grp-dtl-container .col-1 {
  /* background: red; */
  flex: 0 0 50%;
  max-width: 50%;
  /* height: 15px; */
  padding-left: 12px;
  padding-right: 12px;
}
.grp-dtl-container .col-2 {
  /* background: green; */
  flex: 0 0 50%;
  max-width: 50%;
  /* height: 15px; */
  padding-left: 12px;
  padding-right: 12px;
}
.grp-dtl-container .section-col {
  /* padding-left: 12px; */
  /* padding-right: 12px; */
  flex: 0 0 100%;
  max-width: 100%;
}
.grp-dtl-container .section-dropdown {
  width: 100%;
  /* height: 30px; */
  /* padding-left:15px; */
  height: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.4s ease;
  opacity: 1;
  /* background: #fff; */
  /* height: max-content; */
  overflow: hidden;
}
/* .grp-dtl-container .section-dropdown:hover{
   height: 500px!important;
   } */
.d1 {
  width: 100%;
  height: 20px;
  background: black;
  color: #fff;
  transition: all 5.8s ease;
  -webkit-transition: all 5.8s ease;
}
.d1:hover {
  width: 100%;
  height: 120px;
  background: black;
  color: #fff;
}
.show {
  display: block;
}
.grp-dtl-container .section-cover {
  width: 100%;
  height: 74px;
  border-radius: 4px;
  position: absolute;
  cursor: pointer;
}
.grp-dtl-container .section-cover:hover {
  /* box-shadow: 0 4px 15px 0 rgba(207, 224, 235, 0.7); */
  box-shadow: 0 8px 16px 2px rgba(207, 224, 235, 0.7);
}
.grp-dtl-container .section-dropdown-content {
  width: 100%;
  border-top: 1px solid #ededed;
  padding-top: 10px;
  padding-bottom: 5px;
}
.grp-dtl-container .section-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.grp-dtl-container .free-tag {
  color: #fff;
  background: #4bb543;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 5px;
}
.grp-dtl-container .paid-tag {
  color: #fff;
  background: var(--themeColor);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 2px;
  font-size: 12px;
  margin-left: 5px;
}
.grp-dtl-container .section-list-item {
  padding-bottom: 4px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 4px;
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
}
.grp-dtl-container .paid-item:hover {
  /* padding-bottom: 8px; */
  background: rgba(207, 224, 235, 0.5);
  color: var(--themeColor);
  cursor: pointer;
  /* font-size: 14px; */
  font-weight: 600;
  /* font-family: 'Source Sans Pro', sans-serif; */
}
.grp-dtl-container .free-item:hover {
  /* padding-bottom: 8px; */
  background: rgb(49, 161, 40, 0.1);
  color: #31a128;
  cursor: pointer;
  /* font-size: 14px; */
  font-weight: 600;
  /* font-family: 'Source Sans Pro', sans-serif; */
}
.grp-dtl-container .section-content {
  height: 74px;
  width: 100%;
  display: flex;
}
.grp-dtl-container .section-img-container {
  display: flex;
  width: 70px;
  height: 100%;
  /* overflow: hidden; */
  align-items: center;
  /* align-content: center; */
  justify-content: center;
  /* padding: 15px; */
  position: relative;
}
.grp-dtl-container .section-heading {
  margin: 0;
  width: 100%;
}
.grp-dtl-container .section-heading-container {
  display: flex;
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(33, 42, 57, 0.75);
  font-weight: 600;
  font-size: 18px;
  width: calc(100% - 70px);
  align-items: center;
  padding-right: 15px;
  align-content: center;
}
.grp-dtl-container .right-arrow {
  float: right;
  margin-top: 4px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  /* transform: rotate(-90deg); */
}
.grp-dtl-container .down-arrow {
  float: right;
  margin-top: 4px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  transform: rotate(90deg);
}
.grp-dtl-container .right-arrow-rotate {
  float: right;
  margin-top: 10px;
  transform: rotate(90deg);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.grp-dtl-container .down-arrow-rotate {
  float: right;
  margin-top: 10px;
  transform: rotate(-90deg);
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}
.grp-dtl-container .notify {
  background: var(--themeColor);
  display: inline-flex;
  width: 23px;
  height: 23px;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
}

.group-sidebar {
  /* padding-left: 15px; */
  /* padding-top: 35px; */
  /* padding-bottom: 70px; */
}
/* .group-sidebar::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
  } */

/* .group-sidebar::-webkit-scrollbar-thumb
{
	background-color: #212A39;
}
.group-sidebar::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
  } */
.group-sidebar .back-btn {
  color: var(--textColor);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 19px;
  padding-left: 30px;
  cursor: pointer;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
.group-sidebar-mb .back-btn {
  color: var(--textColor);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 19px;
  padding-left: 30px;
  cursor: pointer;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
.group-sidebar .back-btn:hover {
  background: rgba(0, 0, 0, 0.3);
}
.group-sidebar .back-img,
.group-sidebar-mb .back-img {
  margin-top: -3px;
  margin-right: 10px;
}
.group-sidebar .grp-name {
  color: rgba(33, 42, 57, 0.75);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 19px;
  padding-left: 30px;
  cursor: pointer;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.group-sidebar .g-name,
.group-sidebar-mb .g-name {
  color: var(--textColor);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 17px;
  /* padding-left: 32px; */
  /* padding-right: 16px; */
  cursor: pointer;
  margin: 0;
  /* padding-top: 16px; */
  /* padding-bottom:16px; */
  width: 100%;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  -o-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  line-clamp: 2;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  -o-box-orient: vertical;
  box-orient: vertical;
  /* autoprefixer: on */
  text-overflow: ellipsis;
  white-space: normal;
}
.group-sidebar .g-name-wrapper,
.group-sidebar-mb .g-name-wrapper {
  padding-top: 16px;
  padding-left: 32px;
  padding-bottom: 16px;
  padding-right: 16px;
}
.group-sidebar .g-name-wrapper:hover,
.group-sidebar-mb .g-name-wrapper:hover {
  background: rgba(0, 0, 0, 0.3);
}
/* .group-sidebar-mb .grp-name{
    color: #fff;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 600;
    font-size:19px;
    padding-left: 30px;
    cursor: pointer;
    margin:0;
    padding-top: 15px;
    padding-bottom:15px;
    } */
.group-sidebar-mb hr {
  margin: 0;
}
.group-sidebar .active,
.group-sidebar-mb .active {
  background: var(--ctaColor);
  /* border-left: 4px #fff solid; */
  /* padding-left: 28px; */
}
.group-sidebar .active:hover,
.group-sidebar-mb .active:hover {
  background: var(--ctaColor);
}
.group-sidebar .grp-name:hover {
  background: var(--themeColor);
  color: var(--textColor);
}
.group-sidebar hr {
  padding: 0;
  margin: 0;
}
.group-sidebar .grp-heading {
  color: var(--textColor);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding-left: 30px;
  margin: 0;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 15px;
}
.group-sidebar-mb .grp-heading {
  color: var(--textColor);
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding-left: 32px;
  margin: 0;
  /* padding-top:15px; */
  padding-bottom: 16px;
}
/* -------------------- 0.6.Pageheader ----------------------- */

/*-----------------------
  Pageheader
  -------------------------*/

.page-header {
  margin-bottom: 30px;
}

.page-breadcrumb .breadcrumb {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0px;
  margin-bottom: 0px;
  list-style: none;
  background-color: transparent;
  border-radius: 0px;
  border-top: 1px solid #e0e4ef;
  padding-top: 10px;
  font-size: 12px;
}

.page-breadcrumb .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #b1b1c0;
  content: "\f105";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 600;
}

.page-breadcrumb .breadcrumb-item.active {
  color: #71728e;
}

.pageheader-title {
  font-size: 24px;
  margin-bottom: 8px;
}

.pageheader-text {
  margin-bottom: 14px;
  /* display: none; */
}
.explore-wrapper {
  width: 100%;
  /* height: 1px; */
}

/* -------------------- PoweredByImarticusFooter ----------------------- */

.FooterQuoteContainer {
  background-color: #f5f5f5;
}
.FooterQuote {
  padding: 24px 0px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  font-family: Source Sans Pro;
}

/* -------------------- 0.7.Footer ----------------------- */

/*-----------------------
  Footer
  -------------------------*/

.eck-footer {
  display: none;
  border-top: 1px solid rgba(152, 166, 173, 0.2);
  padding: 14px 45px 0px 45px;
  color: #71748d;
  background-color: #212a39;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  width: 100%;
  /* height:740px; */
  color: #fff;
  position: absolute;
  bottom: 0;
}
.eck-footer a {
  color: #fff;
}
.eck-footer a:hover {
  cursor: pointer;
  text-decoration: underline;
}

.eck-footer .content-row-1 {
  padding: 0;
  margin: 0;
}
.eck-footer .content-row-2 {
  padding: 0;
  margin: 0;
  margin-top: 50px;
  margin-bottom: 38px;
}
.eck-footer .content-row-2 .block-1 {
  border-right: 1px solid #777777;
  flex: 0 0 54% !important;
  max-width: 54% !important;
}
.eck-footer .content-row-2 .block-2 {
  flex: 0 0 46% !important;
  max-width: 46% !important;
}
.eck-footer .awards-list {
  list-style: none;
  padding: 0;
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
}
.eck-footer .awards-list .awards-item {
  width: 165px;
  display: inline-block;
  padding-right: 5px;
  border-right: 1px solid #fff;
  margin-bottom: 10px;
}
.eck-footer .social-img {
  margin-left: 10px;
  margin-right: 10px;
}
.eck-footer .awards-list .awards-item:nth-child(3) {
  min-width: 200px;
  display: inline-block;
  padding-right: 5px;
  border-right: 0px solid #fff;
  margin-bottom: 5px;
}
.eck-footer .social-links {
  display: flex;
  justify-content: center;
}
.eck-footer .apple-download {
  margin-bottom: 20px;
}
.eck-footer .awards-list .awards-item:nth-child(2) {
  width: 200px;
  display: inline-block;
  padding-right: 5px;
  border-right: 1px solid #fff;
  margin-bottom: 5px;
  padding-left: 15px;
}
.eck-footer .tos-links {
  display: flex;
  align-items: center;
  padding-left: 0;
  justify-content: flex-end;
}
.eck-footer .tos-list {
  list-style: none;
  margin: 0;
  float: right;
}
.eck-footer .tos-list .tos-item {
  display: inline-block;
  margin-left: 10px;
}
.eck-footer .last-row {
  display: flex;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 40px;
}
.margin-0 {
  margin: 0;
}
.padding-0 {
  padding: 0;
}
.eck-footer .blog-row {
  margin: 0;
}
.eck-footer .company-col {
  flex: 0 0 12% !important;
  max-width: 12% !important;
}
.eck-footer .logo-row {
  margin: 0;
  padding-bottom: 35px;
  padding-top: 35px;
}
.eck-footer .courses-col {
  flex: 0 0 56% !important;
  max-width: 56%;
}
.eck-footer .groups-col {
  flex: 0 0 32% !important;
  max-width: 32%;
}
.eck-footer .logo-row-content {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  display: flex;
  align-items: center;
}
.eck-footer .footer-logo {
  width: 56px;
  height: 56px;
  margin-top: -5px;
}
.eck-footer .dashboard-btn {
  font-family: "Source Sans Pro", sans-serif;
  background: none;
  border: solid 2px #ffffff;
  border-radius: 4px;
  color: #fff;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.eck-footer .eck-title {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 22px;
  color: #fff;
}
.eck-footer .dashboard-btn-wrapper {
  display: flex;
  width: 100%;
  margin-top: -10px;
  justify-content: flex-end;
}
.eck-footer .dashboard-btn-wrapper-mb {
  display: none;
}
.eck-footer .footer-item {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 10px;
  color: #fff;
}
.eck-footer .footer-sub-heading {
  text-transform: uppercase;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 600;
}
.eck-footer .awards-col {
  flex: 0 0 73% !important;
  max-width: 73% !important;
}

.eck-footer .blogs-col {
  flex: 0 0 27% !important;
  max-width: 27% !important;
}
.eck-footer .hr {
  background: #777777;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
}
.eck-footer .hr-mb {
  display: none;
}
.eck-footer .contact {
  margin-bottom: 30px;
}
.eck-footer .contact-info {
  text-align: justify;
  width: calc(100% - 20px);
  padding-left: 25px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  display: inline-flex;
}

.eck-footer .link-btns {
  padding-left: 30px;
}
.eck-footer .edu-btn {
  background: #31a128;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  padding: 8px;
  margin-top: 25px;
}
.eck-footer .copyright {
  display: flex;
  align-items: center;
}

.footer-links {
}

.footer .footer-links a {
  color: #71748d;
  margin-left: 1.5rem;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.tile.bg-danger {
  color: #fff;
}

.user-avatar-md {
  height: 32px;
  width: 32px;
}
.ecko-logo-container {
  width: 215px;
  height: 100%;
  display: flex;
  /* justify-content: center; */
}
.home-dashboard-header .header-heading {
  width: 215px;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: rgba(33, 42, 57, 0.75);
}
.home-dashboard-header .heading-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
}
.test {
  width: 100%;
  background: red;
  height: 30px;
}

/*------------------------ 7.1  Inbox ----------------------------*/
.menu {
  background: #fff;
  position: absolute;
  width: 200px;
  left: -130px;
  margin-top: 10px;
  padding: 5px;
}
.notification-menu {
  background: #fff;
  position: absolute;
  width: 360px;
  min-height: 300px;
  left: -310px;
  border-radius: 4px;
  margin-top: 10px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
}

.dashboard-main-wrapper .main-content {
  margin-left: 266px;
  width: auto;
}
.dashboard-main-wrapper .app-loader {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  z-index: 300;
  display: flex;
  align-items: center;
  margin: 0 auto;
  justify-content: center;
  top: 0;
  padding: 0;
  overflow: hidden;
  margin: 0;
}
.breadcrumb {
  margin: 0;
  background-color: transparent;
}

.dashboard-main-wrapper .main-container {
  overflow: hidden;
  flex: 1;
}

.home-left-sidebar .sidebar-list {
  list-style: none;
  padding: 0;
  /* padding-top: 10px; */
}
@media only screen and (min-width: 800px) {
  .home-left-sidebar .sidebar-list {
    margin-top: 20px;
    padding: 0 10px;
    /* padding-top: 10px; */
  }
  .sidebar-list {
    margin-top: 20px;
    padding: 0 10px;
    /* padding-top: 10px; */
  }
}
.notification-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.notification-menu .no-ntf-container {
  width: 100%;
  height: 262px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notification-menu .no-ntf-text {
  margin-top: 30px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
}
.notification-list .notification-close {
  float: right;
  margin-right: 15px;
  margin-top: 8px;
  color: rgba(33, 42, 57, 0.3);
  cursor: pointer;
  -webkit-text-stroke: 1px white;
}
.notification-list .notification-heading {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: rgba(33, 42, 57, 0.4);
  padding-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(33, 42, 57, 0.15);
}

.notification-list .notification-item {
  height: 80px;
  width: calc(100% - 20px);
  margin: 0;
  padding: 0;
  margin-left: 10px;
  border-bottom: 1px solid rgba(33, 42, 57, 0.15);
}
.notification-list .notification-item:hover {
  background: rgba(0, 0, 0, 0.05);
}
.notification-list .notification-item .notification-pic {
  height: 100%;
  width: 100%;
  padding-top: 12px;
}
.notification-list .notification-item .notification-img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  margin: 0 auto;
}

.notification-list .notification-item .notification-content {
  height: 100%;
  width: 100%;
  padding-left: 30px;
}
.notification-list .notification-name-time-row {
  margin-top: 12px;
}
.notification-list .notification-crs-name {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--themeColor);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notification-active {
  color: var(--themeColor) !important;
}
.notification-list .notification-msg {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  margin-top: 5px;
  max-width: 220px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notification-list .notification-time {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  padding-top: 2px;
  color: rgba(33, 42, 57, 0.4);
}
.notification-list .sender-name {
  font-weight: 600;
}
.notification-list .notification-item:last-child {
  border: none;
}
.notification-menu .see-all-btn {
  margin: 0;
  padding: 0;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--themeColor);
  background-color: rgba(207, 224, 235, 0.5);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.home-left-sidebar .sidebar-list .sidebar-list-item {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  height: 50px;

  /* color: rgba(33, 42, 57,0.4) ; */
  color: var(--textColor);
  cursor: pointer;
}
.sidebar-list .sidebar-list-item {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 20px;
  font-weight: 600;
  height: 50px;

  /* color: rgba(33, 42, 57,0.4) ; */
  color: var(--textColor);
  cursor: pointer;
}
/* .home-left-sidebar .sidebar-list .sidebar-list-item:hover {
  background-color: rgba(0, 0, 0, 0.3);
} */

.home-left-sidebar .sidebar-list .sidebar-list-item .pd-rg {
  padding-right: 10px;
}
.sidebar-list {
  img {
    width: 20px;
    height: 20px;
  }
}
.home-left-sidebar .sidebar-list .active {
  color: var(--textColor);
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0.25) 100%
    ),
    #024a39;
  border-radius: 8px;
  /* border-left: 4px solid #fff; */
}
.sidebar-list .active {
  color: var(--textColor);
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0.25) 100%
    ),
    #024a39;
  border-radius: 8px;
  /* border-left: 4px solid #fff; */
}
.home-left-sidebar .sidebar-list .active:hover {
  /* background-color: rgba(37, 92, 168, 0.15); */
  background-color: var(--ctaColor);
}
.mb-dashboard-sub-header {
  display: none;
}
.mb-dashboard-sub-header .show-sidebar {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.bold {
  font-weight: 600;
}

/* ----- Join Group Modal ----------- */
.jg-modal {
  width: 100%;
  background: transparent;
}
.jg-modal .jg-modal-header {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: rgba(33, 42, 57, 0.75);
  padding-right: 30px;
  padding-top: 30px;
  padding-left: 30px;
}
.jg-modal .info-row {
  display: flex !important;
  flex-direction: row !important;
  margin-left: 30px;
  margin-right: 30px;
  width: auto;
  margin-bottom: 30px;
}
.jg-modal .grp-img-container {
  width: 160px;
  height: 100px;
  background: #e7e4e4;
  border-radius: 4px;
}
.jg-modal .grp-img {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.jg-modal .grp-info-container {
  height: 100px;
  padding-left: 20px !important;
  display: flex;
  width: calc(100% - 160px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.jg-modal .grp-name {
  display: block;
  width: 100%;
  color: var(--themeColor);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.jg-modal .grp-member {
  display: block;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}
.jg-modal .btn-row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}
.jg-modal .cancel-btn {
  background: #fff;
  border: 1px solid rgba(33, 42, 57, 0.5);
  font-family: "Source Sans Pro", sans-serif;
  color: rgba(33, 42, 57, 0.6);
  border-radius: 4px;
  margin-right: 10px;
  padding: 8px;
  width: 100px;
  font-size: 16px;
  outline: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.jg-modal .join-btn {
  background-color: var(--themeColor);
  border-radius: 4px;
  padding: 8px;
  margin-right: 10px;
  border: 0;
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  width: 120px;
  font-size: 16px;
  outline: 0;
  transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
}
.jg-modal .join-btn:hover {
  background-color: rgb(38, 78, 76);
}
.jg-modal .cancel-btn:hover {
  background-color: var(--themeColor);
  border: 1px solid var(--themeColor);
  color: #fff;
}

/* -------- home screen when crs and grps are present ------------- */
.home-cg-container {
  font-family: "Source Sans Pro", sans-serif;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 40px;
}
.home-cg-container .main-heading {
  font-weight: 600;
  color: #212a39;
  font-size: 23px;
  margin: 0;
  padding: 0;
}
.home-cg-container .see-all-btn {
  color: var(--themeColor);
  font-size: 16px;
  padding-left: 15px;
  font-weight: 400;
}
.home-cg-container .see-all-btn:hover {
  cursor: pointer;
  text-decoration: underline;
}
.home-cg-container .grp-card-container {
  /* background: red; */
  /* height:160px; */
}
.home-cg-container .grp-card {
  height: 260px;
  border-radius: 3px;
  /* width:90%; */
  background: #fff;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  margin-bottom: 20px;
}
.home-cg-container .grp-card:hover {
  cursor: pointer;
  box-shadow: 0 7px 7px 1px rgba(0, 0, 0, 0.1);
}
.home-cg-container .grp-img-container {
  height: 160px;
  border-radius: 3px;
}
.home-cg-container .grp-img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
}
.home-cg-container .grp-name-container {
  height: 100px;
  border-radius: 3px;
}
.home-cg-container .grp-row {
  margin-top: 25px;
}
.my-grp-row {
  width: 100%;
  /* background: blue; */
}
.home-cg-container .grp-name {
  margin: 20px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 19px;
  color: #212a39;
  font-weight: 600;
  display: block;
  display: -webkit-box;
  max-height: 50px;
  line-height: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.home-cg-container .join-container {
  margin-top: 20px;
  background: #fff;
  height: 188px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
}
.home-cg-container .all-crs .crs-container {
  height: 140px;
  background: #fff;
  border-radius: 3px;
  display: flex;
}
.home-cg-container .crs-img-container {
  height: 100%;
  width: 180px;
  /* background: red; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-cg-container .img-info-container {
  height: 100%;
  display: flex;
  width: 68%;
  justify-content: center;
  align-items: center;
}
.home-cg-container .crs-img-container .crs-img {
  height: 87px;
  /* width: 160px; */
  background: blue;
  border-radius: 4px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  width: 140px !important;
  overflow: hidden;
  border: 0;
  outline: 0;
}
.home-cg-container .crs-name {
  display: block;
  display: -webkit-box;
  max-width: 300px;
  max-height: 50px;
  font-size: 16px;
  line-height: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 19px;
  color: #212a39;
  font-weight: 600;
  margin-bottom: 10px;
}

.home-cg-container .crs-info-container {
  width: calc(100% - 180px);
  display: block;
}
.home-cg-container .crs-btn-container {
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-cg-container .crs-btn {
  width: 170px;
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 2px;
  outline: 0;
  font-size: 16px;
  font-weight: 600;
  max-width: 100%;
}
.home-cg-container .resume {
  background: #fff;
  border: 1px solid var(--themeColor);
  color: var(--themeColor);
}
.home-cg-container .start {
  background: var(--themeColor);
  border: 1px solid var(--themeColor);
  color: #fff;
}
.home-cg-container .all-crs {
  margin-top: 20px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
}
.home-cg-container .crs-qj-row {
  margin-top: 40px;
}
.home-cg-container .percent-box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-cg-container .percentage {
  width: 43px;
  height: 20px;
  padding-left: 10px;
}
.home-cg-container .percent-bar {
  width: calc(100% - 43px);
  height: 11px;
  background-color: rgba(33, 42, 57, 0.25);
  border-radius: 5.5px;
}
.home-cg-container .percent-status {
  height: 11px;
  background: #4bb543;
  border-radius: 5.5px;
  display: block;
}

@media only screen and (max-width: 948px) {
  .home-dashboard-header .search-inp-web {
    width: 250px;
  }
}

/* Small Laptops md*/
@media only screen and (max-width: 992px) {
  .home-cg-container .join-block {
    margin-top: 40px;
  }
  .dashboard-content-wrapper .c-grp-container .c-grp-form {
    padding: 24px;
    padding-right: 24px;
  }
  /* .join-grp-form .jg-inp ,.join-grp-form .jg-inp-er {
      width:60%;
    }
    .dashboard-content-wrapper .c-grp-container .cg-inp{
      width:60%;
      } */
  /* .dashboard-content-wrapper .c-grp-container .cg-btn, .join-grp-form .jg-btn{
      width:calc(40% - 12px);
      } */
  .dashboard-content-wrapper .c-grp-container .c-grp-img {
    margin-top: 50px;
    width: 100%;
    height: 100%;
  }
  /* .join-grp-form{
      padding: 20px;
      padding-right:0px;
      } */
}
@media only screen and (max-width: 992px) and (min-width: 769px) {
  .dashboard-content-wrapper .home-bg-img {
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
  .home-cg-container .join-container {
    padding-right: 20px;
  }
}

@media only screen and (max-width: 1348px) and (min-width: 967px) {
  .dashboard-content-wrapper .c-grp-container {
    /* margin-bottom : 40px; */
  }
}

@media only screen and (max-width: 966px) and (min-width: 912px) {
  .dashboard-content-wrapper .c-grp-container {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 911px) and (min-width: 769px) {
  .dashboard-content-wrapper .c-grp-container {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 817px) and (min-width: 768px) {
  .dashboard-content-wrapper .c-grp-container {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1348px) and (min-width: 768px) {
  .dashboard-content-wrapper .home-bg-img-container {
    /* overflow: hidden; */
  }
  .dashboard-content-wrapper .home-bg-img-container {
    width: 100%;
  }
  .dashboard-content-wrapper .cg-bg-img-container {
    /* overflow: hidden; */
  }
  .dashboard-content-wrapper .cg-bg-img-container {
    width: 100%;
  }
  .dashboard-content-wrapper .home-bg-img {
    /* right:0; */
    bottom: 0;
  }
  .dashboard-content {
    padding: 32px 16px 32px 16px;
    /* padding-left: 0; */
    /* padding-bottom: 1200px; */
  }
  .eck-footer .copyright {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    justify-content: center;
    margin-bottom: 20px;
  }
  .eck-footer .social-links {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    justify-content: center;
    margin-bottom: 20px;
  }
  .eck-footer .tos-links {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    justify-content: center;
  }
  .eck-footer .tos-list {
    padding: 0;
  }
  .mb-br {
    display: block;
  }
  .eck-footer .link-btns {
    padding-left: 15px;
  }
  .eck-footer .courses-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .eck-footer .groups-col {
    flex: 0 0 66.6666% !important;
    max-width: 66.6666% !important;
  }
  .eck-footer .company-col {
    flex: 0 0 33.3333% !important;
    max-width: 33.3333% !important;
  }
  .eck-footer .content-row-2 .block-1 {
    border-right: 0px solid #777777;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .eck-footer .blogs-col {
    flex: 0 0 40% !important;
    max-width: 40% !important;
  }
  .eck-footer .awards-col {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }
  .eck-footer .awards-list .awards-item {
    width: 100%;
    padding-right: 0px;
    text-align: justify;
    border-right: none;
    margin-bottom: 10px;
  }
  .eck-footer .awards-list .awards-item:nth-child(2) {
    width: 100%;
    display: inline-block;
    padding: 0;
    border-right: 0px;
    margin-bottom: 10px;
  }
  .eck-footer .awards-list .awards-item:nth-child(3) {
    min-width: 100%;
    display: inline-block;
    padding-right: 0px;
    border-right: 0px;
    margin-bottom: 10px;
  }

  .eck-footer .content-row-2 .block-2 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .eck-footer .hr-md {
    width: 100%;
    background: #777777;
    margin-left: 15px;
    margin-right: 15px;
  }
}
@media only screen and (max-height: 767px) {
  .dashboard-content-wrapper .home-bg-img-container {
    height: max-content;
    margin-top: 432px;
  }
  .dashboard-content-wrapper .cg-bg-img-container {
    height: max-content;
    margin-top: 432px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 481px) {
  .profile-bar-completed {
    margin-left: 0px;
  }
  .dashboard-content-wrapper,
  .group-db-content-wrapper {
    margin-left: 0px;
  }
  .group-db-content-wrapper-2 {
    margin-left: 0px;
    padding-top: 10px;
  }
  .dashboard-content-wrapper .home-bg-img-container {
    display: none;
  }
  .dashboard-content-wrapper .cg-bg-img-container {
    display: none;
  }
  .scroller-heading {
    display: none;
  }
  .dashboard-content-wrapper .c-grp-container {
    height: 206px;
  }
  .grp-dtl-container {
    margin: 0;
    width: 100%;
    margin-right: 0;
    max-width: 100%;
  }
  .dashboard-content-wrapper .content-header,
  .group-db-content-wrapper .content-header {
    font-family: "Source Sans Pro", sans-serif;
    display: block;
    font-size: 18px;
    color: var(--themeColor);
    font-weight: 600;
    /* color: rgba(33, 42, 57, 0.75); */
    padding-bottom: 8px;
    border-bottom: 1.5px solid rgba(33, 42, 57, 0.15);
  }
}
/* join group-- */
@media only screen and (max-width: 1080px) and (min-width: 981px) {
  .dashboard-content-wrapper .c-grp-container {
    /* height: 310px; */
  }
  .join-grp-form {
    /* padding-right: 0; */
  }
  .dashboard-content-wrapper .c-grp-container .gc-info-container {
    padding-left: 0;
  }
}
@media only screen and (max-width: 980px) {
  /* .dashboard-content-wrapper .c-grp-container{
      height: 310px;
    }
    .join-grp-form{
       padding-right: 0; 
       } */
  /*.dashboard-content-wrapper .c-grp-container .gc-info-container{
      padding-left: 0;
      } */
  .dashboard-content-wrapper .c-grp-container .c-grp-form-container {
    /* display: flex;
      align-items: center;
      justify-content: center;
      padding:0; */
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dashboard-content-wrapper .join-grp-form .jg-err-mb {
    display: block;
  }
  .dashboard-content-wrapper .join-grp-form .jg-err-web {
    display: none;
  }
  .join-grp-form .jg-inp {
    width: 60%;
  }
  .join-grp-form .jg-inp-er {
    width: 60%;
  }
  .dashboard-content-wrapper .c-grp-container .cg-btn,
  .join-grp-form .jg-btn {
    width: calc(40% - 16px);
    margin-left: 16px;
  }
  .dashboard-content-wrapper .c-grp-container .c-grp-img-container {
    /* display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding:0; */
    height: max-content;
    flex: 0 0 100%;
    max-width: 100%;
    /* background: red; */
  }
  .dashboard-content-wrapper .c-grp-container .gc-info-container {
    padding: 24px;
    padding-top: 0;
  }
  .dashboard-content-wrapper .c-grp-container {
    height: max-content;
  }
  .join-grp-form {
    padding: 24px;
  }
}

/* for sidebar */
@media only screen and (max-width: 910px) {
  .modal {
    margin: 0 auto;
    width: 100%;
  }
  .dashboard-content-wrapper .content-header-container,
  .content-header-container {
    padding: 0;
    display: block;
  }
  .dashboard-content-wrapper .content-header,
  .group-db-content-wrapper .content-header {
    display: block;
  }
  .dashboard-content {
    padding: 16px 16px 32px 16px;
  }
  .home-dashboard-header .nav-bar {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 910px) and (min-width: 767px) {
  .home-left-sidebar,
  .group-sidebar {
    display: none;
  }
  .loader {
    padding-left: 0;
    margin: auto;
  }
  .group-db-content-wrapper {
    margin-left: 0;
  }
  .dashboard-content-wrapper {
    margin-left: 0;
  }
  .home-dashboard-header .fixed-header {
    width: 100%;
    left: 0px;
  }
  .home-dashboard-header .fixed-gdtl-header {
    width: 100%;
    left: 0px;
  }
  .home-dashboard-header .profile-btns {
    display: none;
  }
  .home-dashboard-header .nav-bar {
    height: 60px;
    /* box-shadow: none!important; */
    background: #fff;
    border: 0;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    /* border:none!important; */
  }
  .home-left-sidebar-mb .left-sidebar-menu-mb,
  .group-sidebar-mb .left-sidebar-menu-mb {
    height: 100vh;
    top: 0;
    width: 80vw;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 702;
    position: fixed;
    background: var(--themeColor);
    /* margin-left:-80vw; */
  }
  .home-left-sidebar-mb .close-btn {
    left: calc(80vw - 50px);
    z-index: 703;
  }
  .home-left-sidebar-mb .left-sidebar-menu-mb {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    padding: 0;
    /*justify-content: flex-end;*/
  }
  .demo {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.85);
    z-index: 700 !important;
    top: 0;
    display: block;
  }
  .home-left-sidebar-mb,
  .group-sidebar-mb {
    /* display: block; */
    visibility: visible;
  }
}

/*==========  Non-Mobile First Method  ==========*/

/* Small Devices, Tablets */

@media only screen and (max-width: 767px) {
  .home-cg-container .grp-name-container {
    height: 70px;
  }

  .home-cg-container .grp-card {
    height: auto;
  }
  .dashboard-content-wrapper .c-grp-img-container {
    /*display: none!important;*/
  }
  .dashboard-content-wrapper .c-grp-container .c-grp-form-container {
    /* height: 100px; */
  }
  .home-cg-container .grp-img-container {
    height: 120px;
  }
  .home-cg-container .join-container {
    padding-right: 0px;
  }
  .join-grp-form {
    padding: 24px;
    padding-right: 24px;
  }
  .jg-modal .info-row {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-right: 30px;
    width: auto;
    margin-bottom: 30px;
    flex-direction: column;
  }
  .jg-modal .grp-img-container {
    max-width: 100%;
    width: 160px;
    height: 100px;
    background: #e7e4e4;
    border-radius: 4px;
    align-self: center;
  }
  .jg-modal .grp-info-container {
    height: 100px;
    padding-left: 0px;
    display: flex;
    width: calc(100% - 160px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .eck-footer .copyright {
    margin-bottom: 15px;
  }
  .eck-footer .last-row {
    border-top: 1px solid #777777;
    padding-top: 40px;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .eck-footer .hr {
    display: none;
  }
  .eck-footer .hr-mb {
    background: #777777;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 30px;
  }
  .eck-footer .copyright {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    justify-content: center;
    margin-bottom: 20px;
  }
  .eck-footer .social-links {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    justify-content: center;
    margin-bottom: 10px;
  }
  .eck-footer .tos-links {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    justify-content: center;
  }
  .eck-footer .edu-btn {
    margin-bottom: 10px;
  }
  .eck-footer .tos-list .tos-item {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin: 0;
    padding: 5px;
  }
  .eck-footer .tos-list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-left: 15px;
    float: left;
  }
  .eck-footer .content-row-2 {
    padding: 0;
    margin: 0;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .mb-br {
    display: block;
  }
  .eck-footer .link-btns {
    padding-left: 15px;
    padding-bottom: 15px;
  }
  .eck-footer {
    padding: 14px 15px 0px 15px;
  }

  .eck-footer .company-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    border-bottom: 1px solid #777777;
    margin-bottom: 10px;
  }
  .eck-footer .groups-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    border-bottom: 1px solid #777777;
    margin-bottom: 10px;
  }
  .eck-footer .courses-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    border-bottom: 1px solid #777777;
    margin-bottom: 10px;
  }
  .eck-footer .blogs-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    border-bottom: 1px solid #777777;
    margin-bottom: 10px;
  }
  .eck-footer .awards-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    border-bottom: 1px solid #777777;
    margin-bottom: 10px;
  }
  .eck-footer .awards-list .awards-item {
    width: 100%;
    padding-right: 0px;
    text-align: justify;
    border-right: none;
    margin-bottom: 10px;
  }
  .eck-footer .awards-list .awards-item:nth-child(2) {
    width: 100%;
    display: inline-block;
    padding: 0;
    border-right: 0px;
    margin-bottom: 10px;
  }
  .eck-footer .awards-list .awards-item:nth-child(3) {
    min-width: 100%;
    display: inline-block;
    padding-right: 0px;
    border-right: 0px;
    margin-bottom: 10px;
  }
  .eck-footer .social-img {
    margin-left: 10px;
    margin-right: 10px;
  }
  .eck-footer .social-links {
    display: flex;
    justify-content: center;
  }
  .eck-footer .footer-logo {
    height: 76px;
    width: 76px;
  }
  .eck-footer .content-row-2 .block-1 {
    border-right: 0px solid #777777;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .eck-footer .content-row-2 .block-2 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .eck-footer .dashboard-btn-wrapper-mb {
    display: flex;
    width: 100%;
    margin: 0;
    padding-left: 15px;
    margin-top: 10px;
  }
  .eck-footer .dashboard-btn-mb {
    font-family: "Source Sans Pro", sans-serif;
    background: none;
    border: solid 2px #ffffff;
    border-radius: 4px;
    color: #fff;
    width: 100%;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .eck-footer .dashboard-btn-wrapper {
    display: none;
  }
  .dashboard-content-wrapper {
    padding: 0;
    width: 100%;
  }
  .dashboard-content-wrapper .c-grp-container .c-grp-form {
    padding: 24px;
    padding-right: 24px;
  }
  .dashboard-content-wrapper .c-grp-container .cg-inp {
    width: 60%;
  }
  .join-grp-form .jg-inp,
  .join-grp-form .jg-inp-er {
    width: 60%;
  }
  .dashboard-content-wrapper .c-grp-container .cg-btn,
  .join-grp-form .jg-btn {
    /*width:calc(40% - 12px);*/
  }
  .dashboard-content-wrapper .c-grp-container .c-grp-img {
    width: 100%;
    height: 100%;
  }
  .dashboard-content-wrapper .home-bg-img {
    /* height:100%; */
    width: 100%;
    bottom: 0;
    position: static;
  }
  .dashboard-content-wrapper .home-bg-img-container {
    height: 100%;
    width: 100%;
  }
  .dashboard-content-wrapper .cg-bg-img-container {
    height: 100%;
    width: 100%;
  }
  .sidebar-out {
    margin-left: -215px;
  }
  .home-dashboard-header .web-search-btn {
    display: none;
  }
  .home-dashboard-header .welcome-msg {
    font-size: 18px;
    margin: 0;
    padding: 0;
    padding-right: 5px;
    display: none;
  }
  .home-dashboard-header .header-center {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100%);
  }
  .dashboard-content-wrapper .home-btn-wrapper-first {
    /* padding:10px; */
    padding-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .dashboard-content-wrapper .home-btn-wrapper-mid {
    padding: 0;
    padding-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .dashboard-content-wrapper .home-btn-wrapper-last {
    padding: 0;
    padding-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .dashboard-content {
    padding: 16px 16px 60px 16px;
    /* padding-left: 0; */
    /* padding-bottom: 2000px; */
  }
  .dashboard-content-wrapper {
    margin-left: 0px;
    /* margin-top:40px; */
  }
  .dashboard-footer {
    margin-left: 0px;
  }
  .mb-dashboard-sub-header {
    height: 40px;
    width: 100%;
    position: fixed;
    z-index: 400;
    background: #fff;
    display: none;
    -webkit-box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease;
  }
  .home-dashboard-header .nav-bar {
    height: 60px;
    /* box-shadow: none!important; */
    border: none !important;
  }
  .mb-dashboard-icon {
    text-align: center;
    vertical-align: middle;
    line-height: 40px;
    font-size: 22px;
    color: rgba(33, 42, 57, 0.4);
    -webkit-transition: all 0.3s ease;
  }
  .home-dashboard-header .profile-btns {
    display: none;
  }
  .home-left-sidebar {
    z-index: 70;
    top: 100px;
  }

  .notification-link {
    margin-top: 0px;
    font-size: 22px;
    line-height: 2;
    background: transparent;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 10px;
    align-content: center;
    display: block;
    margin: 0 auto;
    line-height: 40px;
  }
  .notification-menu {
    width: 100vw;
    position: absolute;
    min-height: 300px;
    left: -50vw;
    border-radius: 4px;
    margin-top: 0px;
    float: left;
  }
  .home-dashboard-header .nav-bar {
    padding: 0;
  }
  .home-left-sidebar-mb,
  .group-sidebar-mb {
    /* display: block; */
    visibility: visible;
  }
  .home-right-sidebar-mb,
  .group-sidebar-mb {
    /* display: block; */
    visibility: visible;
  }
  .home-left-sidebar,
  .group-sidebar {
    display: none;
  }
  .demo {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.85);
    z-index: 700 !important;
    top: 0;
    display: block;
  }
  .home-left-sidebar-mb .left-sidebar-menu-mb,
  .group-sidebar-mb .left-sidebar-menu-mb {
    height: 100vh;
    top: 0;
    width: 70vw;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 702;
    position: fixed;
    background: var(--themeColor);
    /* margin-left:-80vw; */
  }
  .home-left-sidebar-mb .close-btn {
    left: calc(70vw - 50px);
    z-index: 703;
  }
  .home-left-sidebar-mb .left-sidebar-menu-mb {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    padding: 0;
    /*justify-content: flex-end;*/
  }

  .home-right-sidebar-mb .right-sidebar-menu-mb {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    padding: 0;
    /*justify-content: flex-end;*/
  }
  .home-right-sidebar-mb .right-sidebar-menu-mb,
  .group-sidebar-mb .right-sidebar-menu-mb {
    height: 100vh;
    top: 0;
    width: 70vw;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 702;
    position: fixed;
    background: var(--themeColor);
    /* margin-right:-80vw; */
  }
  .home-right-sidebar-mb .close-btn {
    right: calc(70vw - 50px);
    z-index: 703;
  }
}

@media only screen and (min-width: 350px) {
  .home-right-sidebar-mb {
    display: none;
  }
  .profile-img .details {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (max-width: 450px) {
  .displayOnWebOnly {
    display: none;
  }
}

@media only screen and (min-width: 450px) {
  .displayOnMobileOnly {
    display: none;
  }
}
@media only screen and (max-width: 450px) {
  .profile-setting-container.col-xl-12.col-lg-12.col-md-12.col-sm-12.col-12 {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 610px) {
  .home-cg-container .crs-container {
    padding-top: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .home-cg-container .crs-info-container {
    width: calc(100% - 120px);
    padding-left: 2%;
  }
  .home-cg-container .crs-btn-container {
    width: 100%;
    margin-bottom: 20px;
    padding-top: 10px;
  }
  .home-cg-container .crs-btn {
    width: 100%;
  }
  .home-cg-container .all-crs .crs-container {
    height: auto;
    flex-direction: column;
  }
  .home-cg-container .crs-img-container {
    width: 120px;
  }
  .home-cg-container .img-info-container {
    height: auto;
    width: 100%;
  }
  .home-cg-container .crs-img-container .crs-img {
    width: 140px;
  }
}
/* tablet-- */
@media only screen and (min-width: 481px) and (max-width: 910px) {
  .home-left-sidebar-mb .close-btn,
  .group-sidebar-mb .close-btn {
    position: fixed;
    margin-right: 40vw;
    top: 10px;
    background: transparent;
    border: none;
    z-index: 702;
    font-size: 30px;
    color: #fff;
    font-family: "Source Sans Pro", sans-serif;
    right: 0;
    outline: none;
  }
  .home-left-sidebar-mb .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    margin: 0;
    width: 50px;
    height: 75px;
    display: flex;
    justify-content: center;
    z-index: 703;
  }

  .group-sidebar-mb .close-btn {
    position: absolute;
    right: 0;
    top: 0;
    left: unset;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    margin: 0;
  }
  .group-sidebar-mb .close-btn img,
  .group-sidebar-mb .close-btn svg {
    display: unset;
    height: 18px;
    width: 18px;
    margin: 12.5px auto;
  }
  .home-left-sidebar-mb .left-sidebar-menu-mb,
  .group-sidebar-mb .left-sidebar-menu-mb {
    height: 100vh;
    width: 250px;
    margin-left: calc(-250px - 20vw);
    z-index: 702;
    overflow-y: auto;
    overflow-x: hidden;
    position: fixed;
    background: var(--themeColor);
    transition: all 0.7s ease;
    -webkit-transition: all 0.7s ease !important;
  }
  .home-left-sidebar-mb .close-btn {
    left: calc(250px - 50px);
    z-index: 703;
  }
  .home-left-sidebar-mb .left-sidebar-menu-mb {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    padding: 0;
    /*justify-content: flex-end;*/
  }
  .out {
    margin-left: 0 !important;
  }
  .dp {
    display: inline-block;
    border-radius: 50%;
    border: none;
    background: transparent;
    height: 40px;
    /* width: 28px; */
  }
  .dp:focus {
    outline: none;
  }
  .home-dashboard-header .ecko-logo-container-mb {
    display: flex;
    width: calc(100% - 200px);
    /* background: green; */
    justify-content: center;
    align-items: center;
  }
  .home-dashboard-header .profile-search-container-mb {
    display: flex;
    width: 110px;
    align-items: center;
    justify-content: flex-end;
    /* background: blue; */
    padding-right: 15px;
  }
  .home-dashboard-header .profile-search-container-mb .search-btn {
    margin-right: 10px;
  }

  .home-dashboard-header .toggle-icon-container-mb {
    display: flex;
    width: 30px;
    /* background: red; */
    padding-left: 15px;
    height: 35px;
    min-width: 30px;
    padding: 0;
    margin: auto auto auto 15px;
  }

  .home-dashboard-header .profile-search-container-mb .profile-search-box {
    display: flex;
    column-gap: 8px;
  }
  .home-dashboard-header .ecko-logo-container-mb .logo-mb {
    /* width: 30%; */
    height: auto;
    /* max-width: 150px; */
    max-width: 100px;
    min-width: 50px;
    /* max-width: 200px; */
  }
  .home-dashboard-header .ecko-logo-container,
  .home-dashboard-header .header-heading {
    display: none;
  }
  .home-dashboard-header .header-center {
    display: none;
  }
  .username-link {
    display: none;
  }
}

/* Extra Small Devices, Phones */
/* #explore_wrapper_id{
    background: red;
    height: 100px;
    width: 100%;
    margin: 0;
    } */
@media only screen and (max-width: 575px) and (min-width: 481px) {
  .my-grp-container .grp-card {
    width: 100%;
    position: relative;
    flex-direction: row;
    height: 120px !important;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 16px;
    display: flex;
  }
  .my-grp-container .grp-img-container {
    width: 180px;
    padding: 10px;
    min-height: 0px;
    height: 100%;
    background: #fff;
  }
  .my-grp-container .grp-name-container {
    width: calc(100% - 180px);
    height: 100%;
    border-radius: 4px;
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
  .my-grp-container .view-btn {
    /* padding-left: 8px; */
    padding-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: 14.08px;
    color: var(--themeColor);
    display: block !important;
  }
  .grp-name-container .grp-name {
    color: #212a39;
    font-size: 14.08px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    /* padding: 8px; */
    height: max-content;
    margin-bottom: 0;
    /* padding-bottom: 8px; */
    /* background: red; */
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -ms-line-clamp: 2;
    -o-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    -o-box-orient: vertical;
    box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
/* for course screen */
@media only screen and (min-width: 480px) and (max-width: 710px) {
  .course-container .btn-section {
    width: 150px;
    /* background: green; */
    display: flex;
    justify-content: flex-end;
    padding-right: 16px;
    padding-left: 16px;
    align-items: center;
  }
  .course-container .progress-section-ss {
    width: calc(100% - 300px);
  }
  .course-container .ss-btn {
    height: 38px;
  }
  .course-container .dum-ss-2 {
    width: 80%;
  }
  .course-container .progress-section {
    width: calc(100% - 150px - 150px);
    padding-left: 0px;
  }
  .course-container .cc-container {
    height: 110px;
    background: #fff;
    margin-top: 16px;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    border-radius: 4px;
    display: flex;
    z-index: 52;
    position: relative;
  }
  .course-container .img-section {
    width: 150px;
    padding: 12px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .course-container .ic-container {
    width: 100%;
    height: 110px;
    background: #fff;
    margin-top: 16px;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    border-radius: 4px;
    display: flex;
    position: relative;
  }
  .course-container .crs-resume-btn {
    border: 1px solid var(--themeColor);
    border-radius: 2px;
    height: 38px;
    width: 170px;
    background: var(--themeColor);
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    z-index: 101;
  }
  .course-container .crs-start-btn {
    height: 38px;
    font-size: 14px;
  }
  .course-container .bg-div-01 {
    height: 10px;
  }
  .course-container .bg-div-02 {
    height: 30px;
  }
}
@media only screen and (max-width: 550px) {
  .hideBellOnMobile {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .dashboard-content-wrapper .ps-main-content {
    max-width: 300px;
  }
  .signupm-block {
    border: 1px solid #cacaca;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 18px;
    margin-top: 18px;
    margin-bottom: 100px;
  }
  .tc-ss-container,
  .grp-ss-container,
  .fc-ss-container {
    display: none;
  }
  .dashboard-content-wrapper .profile-setting-container {
    /* margin-bottom: 16px; */
  }
  .profile-setting-container .err-icon {
    height: 12px;
  }
  .scroller-heading {
    display: block;
  }
  .dashboard-content .add-profile-err {
    margin-top: -18px;
    font-weight: 400;
    font-size: 14px;
  }
  .carousel-container .carousel-card-container {
    height: 100%;
    width: 50%;
    padding-right: 16px;
  }
  .grp-ss-container .card-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grp-ss-container .card-col:last-child {
    display: none;
  }
  .grp-ss-container .card-col:nth-last-child(2) {
    display: none;
  }
  .gc-ss-container .card-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .gc-ss-container .card-col:last-child {
    display: none;
  }
  .gc-ss-container .card-col:nth-last-child(2) {
    display: none;
  }
  .pp-modal .payment-card {
    height: 60px;
  }
  .pp-modal .pk-duration {
    font-size: 30px;
  }
  .pp-modal .pk-unit {
    margin-top: 8px;
  }
  .pp-modal .rupee-section {
    font-size: 28px;
  }
  .pp-modal .rs-sign {
    margin-top: 8px;
    font-size: 18px;
  }
  .pp-modal .btn-section {
    width: 55px;
  }
  .pp-modal-container {
    margin: 0;
    padding-left: 8px;
    padding-right: 8px;
  }
  .pp-modal .coupon-section {
    margin: 0 auto;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .pp-modal .cc-input {
    width: calc(100% - 16px - 36px);
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px;
  }
  .pp-modal .apply-btn {
    font-size: 14px;
    display: inline-block;
    width: 36px;
    margin-left: 16px;
  }
  .pp-modal .footer {
    margin: 0 auto;
    width: 100%;
  }
  .pp-modal .back-btn {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0;
    padding-right: 0;
    width: 47%;
    margin-right: 3%;
  }
  .pp-modal .pay-btn {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 0;
    padding-right: 0;
    width: 47%;
    margin-left: 3%;
  }
  .pp-modal .content-section {
    width: calc(100% - 55px - 150px);
  }
  .grp-dtl-container .g-code {
    line-height: 24px;
  }
  .grp-dtl-container .switch-container {
    left: 160px;
    top: 25px;
  }
  .home-custom-align {
    padding: 0 !important;
  }
  .dashboard-content-wrapper .home-btn-wrapper-first {
    padding-left: 0;
    padding-right: 0;
  }
  .dashboard-content-wrapper .home-btn-wrapper-mid {
    padding-left: 0;
    padding-right: 0;
  }
  .dashboard-content-wrapper .home-btn-wrapper-last {
    padding-left: 0;
    padding-right: 0;
  }
  .course-container .img-section-mb {
    width: 200px;
    display: block;
    display: flex;
    /* background: red; */
    /* padding: 12px; */
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .course-container .img-section-mb {
    width: 45%;
  }
  .course-container .ccrs-name-wrapper {
    display: flex;
    width: 55%;
    align-items: center;
  }
  .course-container .ccrs-name {
    color: #212a39;
    width: 100%;
    display: -webkit-box !important;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    align-items: center;
    padding-left: 16px;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -ms-line-clamp: 2;
    -o-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    line-clamp: 2;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    -o-box-orient: vertical;
    box-orient: vertical;
    /* autoprefixer: on */
    text-overflow: ellipsis;
    white-space: normal;
  }
  .course-container .ccrs-name-container {
    /* display: flex; */
    height: max-content;
    overflow: hidden;
  }

  .dashboard-content-wrapper .c-grp-container {
    margin-bottom: 40px;
  }
  .course-container .cc-container {
    height: 140px;
  }
  .jg-extra-spacing {
    margin-bottom: 0px;
    display: none;
    /* background: red; */
  }
  .grp-name-container .grp-name {
    color: #212a39;
    font-size: 14.08px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    /* padding: 8px; */
    height: max-content;
    margin-bottom: 0;
    /* padding-bottom: 8px; */
    /* background: red; */
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -ms-line-clamp: 2;
    -o-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    line-clamp: 2;
    /* autoprefixer: off */
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -ms-box-orient: vertical;
    -o-box-orient: vertical;
    box-orient: vertical;
    /* autoprefixer: on */
    text-overflow: ellipsis;
    white-space: normal;
  }
  .dashboard-content-wrapper .content-header,
  .group-db-content-wrapper .content-header {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    display: block;
    color: var(--themeColor);
    font-weight: 600;
    /* color: rgba(33, 42, 57, 0.75); */
    padding-bottom: 8px;
    border-bottom: 1.5px solid rgba(33, 42, 57, 0.15);
  }
  .course-container .crs_hr {
    margin: 0;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .loader {
    padding-left: 0px;
    width: 90%;
    margin: auto;
    /* margin-left: 5%; */
  }
  .grp-dtl-container .section-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ex-sm-hide {
    display: block;
  }
  .nf-wrapper .nf-msg {
    font-size: 18px;
    margin-top: 0px;
    max-width: 80%;
  }
  .nf-wrapper .nf-img {
    margin-top: 2vh;
    height: 40vh;
    /* min-height: 190px; */
    width: auto;
    max-width: 80%;
  }
  .nf-wrapper .back-btn {
    width: 90%;
    min-width: 70%;
    height: 50px;
    position: absolute;
    bottom: 32px;
  }
  .grp-dtl-container {
    padding-bottom: 15px;
  }

  .course-container .crs-start-btn {
    display: none;
  }
  .course-container .crs-resume-btn {
    display: none;
  }
  .course-container .btn-section,
  .course-container .img-section,
  .course-container .progress-section {
    display: none;
  }
  .course-container .bg-div-01 {
    height: 10px;
  }
  .course-container .bg-div-02 {
    height: 30px;
  }
  .course-container .ic-container {
    border-top: 6px solid #212a39;
    height: fit-content;
  }
  .course-container .mb-cc-section {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    padding-top: 16px;
    padding-bottom: 16px;
    width: 100%;
    position: relative;
  }
  .course-container .mb-section {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    display: block;
    width: 100%;
    position: relative;
  }
  .course-container .mb-section-cover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    border-radius: 4px;
    /* background: red; */
  }
  .course-container .mb-heading {
    text-transform: uppercase;
    margin: 0;
    font-family: "Source Sans Pro", sans-serif;
  }
  .course-container .crs-name {
    color: #212a39;
    padding-top: 5px;
    width: 100%;
    margin: 0;
    font-size: 18px;
  }
  .course-container .progress-heading {
    width: 100%;
    margin-top: 15px;
    height: max-content;
    /* background: red; */
    font-family: "Source Sans Pro", sans-serif;
  }
  .course-container .percent-mb {
    float: right;
    font-weight: 600;
    color: var(--themeColor);
  }
  .course-container .progress-bar-mb {
    width: 100%;
    background: #31a128;
    height: 5px;
    margin-top: 10px;
    border-radius: 15px;
  }
  .course-container .progress-bar-container-mb {
    width: 100%;
    background-color: rgba(33, 42, 57, 0.25);
    height: 5px;
    margin-top: 10px;
    border-radius: 15px;
  }
  .course-container .progress-label {
    font-weight: 600;
  }
  .my-grp-container .grp-card {
    width: 100%;
    position: relative;
    flex-direction: row;
    height: 100px !important;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 16px;
    display: flex;
  }
  .my-grp-container .grp-img-container {
    width: 120px;
    padding: 10px;
    min-height: 0px;
    height: 100%;
    background: #fff;
  }
  .my-grp-container .grp-card {
    /* width: 120px; */
    /* padding: 10px; */
    min-height: 0px;
    /* height: 100%; */
    /* background: #fff ; */
  }

  .pdfViewer {
    height: 100vh;
    width: 100vh;
  }
  .my-grp-container .grp-name-container {
    width: calc(100% - 120px);
    height: 100%;
    border-radius: 4px;
    margin: 0;
    padding-left: 5px;
    padding-right: 5px;
  }
  .my-grp-container .view-btn {
    /* padding-left: 8px; */
    padding-top: 5px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    font-size: 14.08px;
    color: var(--themeColor);
    display: block;
  }
  .header-cover {
    display: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  .dp {
    display: inline-block;
    border-radius: 50%;
    border: none;
    width: 40px;
    height: 40px;
    background: transparent;
  }
  .dp:focus {
    outline: none;
  }
  .user-menu {
    width: 230px;
    left: auto;
    right: 16px;
    margin-top: 25px;
  }
  .user-list .user-list-item {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .modal-content {
    /* margin-top: -250px; */
  }
  .jg-modal .grp-img-container {
    width: 120px;
    height: 80px;
    /* background: blue */
  }
  .jg-modal .grp-info-container {
    height: 100px;
    padding-left: 20px;
    /* background: red; */
    /* display:  flex; */
    width: calc(100% - 120px);
    /* flex-direction: row; */
    /* align-items: center; */
    /* justify-content: center; */
  }
  .jg-modal .info-row {
    /* display: flex; */
    /* margin-left:30px; */
    /* margin-right:30px; */
    /* width:auto; */
    flex-direction: row;
    /* background: green; */
    margin-bottom: 15px;
  }
  .my-grp-container .grp-card {
    width: 100%;
    position: relative;
    height: 230px;
  }
  .username-link {
    display: none;
  }
  .gd-row {
    display: none;
  }
  .group-msg {
    color: rgba(33, 42, 57, 0.75);
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding-top: 16px;
    margin: 0;
    margin-bottom: 0px;
  }
  .carousel-wrapper,
  .carousel-header {
    display: none;
  }
  .grp-dtl-container {
    display: flex;
    flex-direction: column;
  }
  .grp-dtl-container .view-container {
    /* background: red; */
    width: 100%;
    padding-right: 0;
    padding: 0;
    height: 300px;
    margin-bottom: 24px;
  }
  .grp-dtl-container .grp-img-name {
    font-size: 18px;
    /* bottom: -10px; */
  }
  .grp-dtl-container .grp-img-section {
    /* background:yellowgreen; */
    height: calc(100% - 92px);
  }

  .grp-dtl-container .view-container-2 {
    width: 100%;
  }
  .grp-dtl-container .admin-section {
    /* display: none; */
    font-size: 14px;
    /* padding:0; */
  }
  .grp-dtl-container .admin-section-mb {
    /* background: red; */
    width: 100%;
    font-size: 18px;
    height: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    font-family: "Source Sans Pro", sans-serif;
    width: 55%;
    font-weight: 400;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    display: none;
  }
  .grp-dtl-container .grp-img-dtl {
    background: #fff;
    width: 100%;
    height: 92px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
  }
  .grp-dtl-container .g-code {
    font-size: 14px;
  }
  .card-heading {
    padding: 0;
    width: calc(100% - 2px);
    padding-left: 8px;
    padding-right: 8px;

    padding-top: 8px;
    color: #212a39;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;
  }
  .home-dashboard-header .toggle-icon-container-mb {
    display: flex;
    width: 30px;
    /* background: red; */
    padding-left: 15px;
    height: 35px;
    min-width: 30px;
    padding: 0;
    margin: auto auto auto 15px;
  }
  .group-hr {
    display: none;
  }
  .scroller {
    /* height: max-content; */
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    background: transparent;
    /* margin-bottom:20px; */
    margin-left: 2px;
    display: block;
    margin-bottom: 100px;
    /* display: flex;  */
  }
  .scroller .card-container {
    height: max-content;
    /* padding-bottom: 16px; */
    padding-top: 5px;
    /* width: 1000px; */
  }

  .scroller .card {
    background: #fff;
    min-height: 209px;
    border-radius: 4px;
    width: 50vw;
    margin-left: 16px;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    float: left;
    position: relative;
    margin-bottom: 16px;
  }
  .scroller .grp-card-container {
    height: 265px;
    /* background: red; */
    padding-top: 5px;
    /* width: 1000px; */
  }

  .scroller .grp-card {
    background: #fff;
    border-radius: 4px;
    width: 50vw;
    /* height: calc(100% - 24px); */
    margin-left: 16px;
    border: none;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    /* display: block; */
    /* flex-grow: 1; */
    /* display: inline-block; */
    float: left;
    position: relative;
  }
  .scroller .card-cover {
    background: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: transparent;
  }
  .scroller .card:last-of-type {
    margin-right: 22px;
  }
  .demoo {
    display: flex;
    width: 100%;
    height: 100%;
  }
  /* hide scrollbar in mobile view */
  /* ::-webkit-scrollbar {
      background: transparent;  /* Optional: just make scrollbar invisible */
  /* } */
  /* Optional: show position indicator in red */
  /* ::-webkit-scrollbar-thumb {
      background: transparent;
      } */
  .custom-padding {
    padding-bottom: 15px !important;
  }
  .home-dashboard-header .ecko-logo-container-mb {
    display: flex;
    width: calc(100% - -333px);
    /* background: green; */
    justify-content: center;
    align-items: center;
  }
  .home-dashboard-header .profile-search-container-mb {
    display: flex;
    width: 100px;
    align-items: center;
    justify-content: flex-end;
    /* background: blue; */
    padding-right: 15px;
  }
  .home-dashboard-header .profile-search-container-mb .search-btn {
    margin-right: 10px;
  }

  .home-dashboard-header .profile-search-container-mb .profile-search-box {
    display: flex;
    column-gap: 8px;
  }
  .home-dashboard-header .ecko-logo-container-mb .logo-mb {
    /* width: 65%; */
    height: 31px;
    min-width: 50px;
  }
  .home-dashboard-header .ecko-logo-container {
    display: none;
  }
  .home-dashboard-header .header-center {
    display: none;
  }
  .join-grp-form {
    padding: 24px;
    padding-right: 24px;
  }
  .tab-row {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .dashboard-content .home-bg-img-container {
    display: none;
  }
  .dashboard-content .cg-bg-img-container {
    display: none;
  }
  .home-dashboard-header .welcome-msg {
    display: none;
  }
  .dashboard-content-wrapper .c-grp-container .c-grp-form {
    padding: 24px;
    padding-right: 24px;
  }
  .dashboard-content-wrapper .c-grp-container .cg-inp {
    width: 100%;
  }
  .join-grp-form .jg-inp {
    width: 100%;
  }
  .join-grp-form .jg-inp-er {
    width: 100%;
    margin-bottom: 4px;
  }
  .dashboard-content-wrapper .c-grp-container .err-msg,
  .join-grp-form .err-msg,
  .pf-content-container .err-msg {
    font-weight: 400;
    font-size: 12px;
  }
  .dashboard-content-wrapper .c-grp-container .err-icon,
  .join-grp-form .err-icon {
    height: 12px;
    margin-top: -2px;
  }
  .dashboard-content-wrapper .c-grp-container .cg-btn,
  .join-grp-form .jg-btn {
    width: 100%;
    margin: 0;
    /* margin-top:15px; */
  }
  .dashboard-content-wrapper .c-grp-container {
    /* height:250px; */
    /* padding-bottom: 70px; */
  }
  .dashboard-content-wrapper .c-grp-container .c-grp-img {
    width: 100%;
    height: 100%;
  }
  .dashboard-content-wrapper .c-grp-container .cg-err-web,
  .join-grp-form .jg-err-web {
    display: none;
  }
  .dashboard-content-wrapper .c-grp-container .cg-err-mb {
    display: block;
  }
  .join-grp-form .jg-err-mb {
    display: block;
  }
  .dashboard-content {
    padding: 16px 16px 64px 16px;
    /* padding-left: 0; */
    /* padding-right: 0; */
    /* padding-bottom: 0px; */
    /* padding-left: 0; */
    /* padding-bottom:2150px; */
  }
  .dashboard-content-wrapper,
  .group-db-content-wrapper {
    margin-left: 0px;
  }
  .dashboard-footer {
    margin-left: 0px;
  }

  .dashboard-main-wrapper .main-content {
    margin-left: 0px;
    width: auto;
  }

  .footer {
    margin-left: 0px;
  }

  .left-sidebar-menu {
    height: calc(100% - 60px);
    overflow-y: auto;
  }
}

/* My Group - Home Page */

.mgrp-card-container {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
  width: 252px;
  height: 241px;
  margin-bottom: 15%;
}

.mgrp-card-img {
  width: 100%;
  height: 138px;
  border-radius: 3px;
}

.mgrp-card-nm {
  padding: 9%;
  color: #000000;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 19.2px;
  font-weight: 600;
  line-height: 23px;
  text-align: left;
}

/* Explore Scree CSS */

.hdline {
  color: #212a39;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 23.04px;
  font-weight: 600;
  line-height: 28px;
}

.free-crs-container {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 4px 16px 0 rgba(207, 224, 235, 0.75);
  width: 340px;
  height: 335px;
  margin-right: 20px;
}

.free-crs-img {
  /* background-image: url(https://marvel-live.freetls.fastly.net/canvas/2018/2/3bac41d56a5a4c659e06f1a48b45ee46?quality=95&fake=.png); */
  background-size: 100%;
  border-radius: 3px;
  width: 340px;
  height: 205px;
}

.free-crs-dtl-container {
  display: flex;
  height: 130px;
}

.free-crs-dtl-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  color: #000000;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 19.2px;
  font-weight: 600;
  line-height: 23px;
}

.lrn-mr {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.lrn-mr-btn {
  /* border: 1px solid var(--themeColor);
	border-radius: 2px;
	background-color: white; */
  border: 1px solid var(--themeColor);
  border-radius: 2px;
  width: 109px;
  height: 44px;
  background-color: white;
  font-family: "Source Sans Pro", sans-serif;
}

.lrn-nm {
  color: #000000;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 17.2px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
}

.lrn-mem {
  color: rgba(33, 42, 57, 0.75);
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: center;
}

.rectangle {
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 4px 16px 0 rgba(207, 224, 235, 0.75);
  width: 375px;
  height: 360px;
  width: 0 !important;
}

.rec-img {
  background-image: url(https://marvel-live.freetls.fastly.net/canvas/2018/2/3bac41d56a5a4c659e06f1a48b45ee46?quality=95&fake=.png);
  background-size: 100%;
  border-radius: 3px;
  width: 375px;
  height: 230px;
}

/* Utility CSS */
.br-hide {
  border: none !important;
}
.pd-0 {
  padding: 0;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mr-0 {
  margin-left: 0;
}

.ml-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}
.pl-0 {
  padding-left: 0;
}
.pr-15 {
  margin-right: 15px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-60 {
  margin-bottom: 60px;
}
.overflow-hidden {
  overflow: hidden !important;
}

/* Tablet css for carousel */
@media only screen and (max-width: 1140px) and (min-width: 711px) {
  .carousel-container .carousel-card-container {
    height: 100%;
    width: 33.333%;
    padding-right: 16px;
  }
  .grp-ss-container .card-col {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
  .grp-ss-container .card-col:last-child {
    display: none;
  }
  .gc-ss-container .card-col {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
  .gc-ss-container .card-col:last-child {
    display: none;
  }
}
/* mobile css for carousel */
@media only screen and (max-width: 710px) and (min-width: 482px) {
  .carousel-container .carousel-card-container {
    height: 100%;
    width: 50%;
    padding-right: 16px;
  }
  .grp-ss-container .card-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grp-ss-container .card-col:last-child {
    display: none;
  }
  .grp-ss-container .card-col:nth-last-child(2) {
    display: none;
  }
  .gc-ss-container .card-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .gc-ss-container .card-col:last-child {
    display: none;
  }
  .gc-ss-container .card-col:nth-last-child(2) {
    display: none;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 711px) {
  .course-container .ic-container {
    width: 100%;
    height: 140px;
    background: #fff;
    margin-top: 10px;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    border-radius: 4px;
    display: flex;
  }
  .course-container .cc-container {
    width: 100%;
    height: 140px;
    background: #fff;
    margin-top: 10px;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    border-radius: 4px;
    display: flex;
    z-index: 52;
    position: relative;
  }
  .course-container .img-section {
    width: 200px;
    /* background: red; */
    padding: 12px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .course-container .img-section-mb {
    display: none;
  }
  .course-container .progress-section {
    width: calc(100% - 410px);
    /* background: yellow; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 15px;
  }

  .course-container .crs-name {
    color: #212a39;
    width: 80%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-container .ccrs-name {
    color: #212a39;
    width: 80%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 18px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .course-container .btn-section {
    width: 210px;
    /* background: green; */
    display: flex;
    justify-content: flex-end;
    padding-right: 24px;
    padding-left: 24px;
    align-items: center;
  }
  .course-container .crs-start-btn {
    border: 1px solid var(--themeColor);
    border-radius: 2px;
    height: 48px;
    width: 170px;
    background: #fff;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    color: var(--themeColor);
    font-weight: 600;
    outline: none;
  }
  .course-container .crs-resume-btn {
    border: 1px solid var(--themeColor);
    border-radius: 2px;
    height: 48px;
    width: 170px;
    background: #fff;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    color: var(--themeColor);
    font-weight: 600;
    outline: none;
  }

  .course-container .crs-img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }
  .course-container .progress-section .content-container {
    width: 100%;
  }
  .course-container .progress-bar {
    width: calc(100% - 60px); /* percent width + margin-left   */
    height: 5.3px;
    background-color: rgba(33, 42, 57, 0.1);
    /* background: red; */
    border-radius: 5.5px;
  }
  .course-container .active-status {
    background: #4bb543;
    height: 100%;
    border-radius: 5.5px;
    transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
  }
  .course-container .pb-container {
    display: flex;
    width: 100%;
    align-items: center;
    /* justify-content: center; */
    /* background: blue; */
  }
  .course-container .percent {
    width: 35px;
    /* background: red; */
    margin-left: 25px;
  }
  .course-container .bg-div-01 {
    width: 100%;
    height: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: rgba(255, 255, 255, 0.5);

    width: calc(100% - 40px);
    margin-left: 20px;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    z-index: 51;
  }
  .course-container .bg-div-02 {
    width: 100%;
    height: 30px;
    background: rgba(255, 255, 255, 0.2);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    /* background: red; */
    width: calc(100% - 80px);
    margin-left: 40px;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    z-index: 50;
    margin-top: -20px;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .tb-hide {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .home-dashboard-header .fixed-header {
    position: fixed;
    top: 0;
    width: calc(100%);
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    /* /* max-width: 880px;  /*
    left: 0;
    /* margin: 0 auto; */
    z-index: 630;
    right: 0;
  }
  .home-dashboard-header .fixed-gdtl-header {
    position: fixed;
    top: 0;
    width: calc(100%);
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(207, 224, 235, 0.5);
    /* /* max-width: 880px;  /*
    left: 0;
    /* margin: 0 auto; */
    z-index: 630;
    right: 0;
  }
  .loader {
    padding-left: 0px;
    margin: auto;
  }
  .mb-hide {
    display: none;
  }
  .tb-hide {
    display: none;
  }
  /* .scroller-heading{
    display: block;
    } */
  .home-custom-align {
    /* padding: 0; */
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    /* /* max-width: 880px;  /*
    /* margin:0; */
    /* margin: */
  }
}
@media only screen and (max-width: 850px) and (min-width: 768px) {
  /* .my-grp-container .grp-card{
    width: 100%;
    position: relative;
    height: 34vh;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 30px;
    } */
  .my-grp-container .grp-img-container {
    /* width: 100%;
    height: calc(34vh - 72px);
    background: #ededed; */
  }
  .my-grp-container .grp-name-container {
    /* width: 100%;
    height: 72px;
    border-radius: 4px;
    margin:0;
    padding-left: 5px;
    padding-right: 5px; */
  }
}
@media only screen and (max-width: 900px) and (min-width: 769px) {
  /* .dashboard-content-wrapper .c-grp-container .c-grp-img-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 100%;
    max-width: 100%;
    } */

  /* .dashboard-content-wrapper .c-grp-container .c-grp-form-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:0;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 20px;
    } */
  /* .dashboard-content-wrapper .c-grp-container .c-grp-img{
    width: 85%;
    height: 85%;
    margin-top: 10px;
  }
  
  .dashboard-content-wrapper .c-grp-container{
    height: 520px;
    } */
}

@media only screen and (max-width: 1023px) and (min-width: 481px) {
  .grp-ss-container .g-card-col:nth-child(1) {
    display: none;
  }
  .grp-ss-container .g-card-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .gc-ss-container .g-card-col:nth-child(1) {
    display: none;
  }
  .gc-ss-container .g-card-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .grp-dtl-container {
    display: flex;
    flex-direction: column;
  }
  .grp-dtl-container .view-container {
    /* background: red; */
    width: 100%;
    padding-right: 0;
    padding: 0;
    height: 285px;
    margin-bottom: 24px;
  }
  .grp-dtl-container .grp-img-name {
    font-size: 18px;
    /* bottom: -10px; */
  }
  .grp-dtl-container .grp-img-section {
    /* background:yellowgreen; */
    height: calc(100% - 92px);
  }

  .grp-dtl-container .view-container-2 {
    width: 100%;
  }
  .grp-dtl-container .admin-section {
    /* display: none; */
  }
  .grp-dtl-container .admin-section-mb {
    /* background: red; */
    width: 100%;
    font-size: 18px;
    height: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    font-family: "Source Sans Pro", sans-serif;
    width: 55%;
    font-weight: 400;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    display: none;
  }
  .grp-dtl-container .grp-img-dtl {
    background: #fff;
    width: 100%;
    height: 92px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
  }
}

.all-lives {
  max-width: 100%;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.live-container {
  background-color: #ffffff;
  /* box-shadow: 0 4px 8px 0 rgb(207 224 235 / 50%) !important; */
  padding: 24px;
  margin-bottom: 24px;
  width: 100%;
  border-radius: 16px;
  border: solid 0.5px;
  border-color: #dc3545;
}

.live-container .live-status {
  display: inline-flex;
  width: 100%;
  line-height: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #000;
}

.live-status {
  display: flex;
  gap: 8px;
}

.live-container .live-info {
  display: inline-block;
  font-size: 18px;
  color: #212a39;
  /* line-height: 22px; */
  width: 100%;
  padding-top: 16px;
}

.live-info .icon {
  max-width: 8.33333333%;
  position: relative;
  padding: 0px 16px;
  float: left;
}

.live-info .detail {
  width: 66.66666667%;
  float: left;
  position: relative;
  padding-right: 16px;
  padding-left: 16px;
}

.icon {
  padding: 0px !important;
}

@media only screen and (max-width: 1000px) {
  .detail {
    width: 100% !important;
  }
}

.live-info .time {
  color: #828282;
  font-size: 15px;
}
@media only screen and (max-width: 430px) {
  .live-info .join {
    margin: auto;
    float: nonw;
  }
}
.live-info .join {
  width: 170px;
  height: 44px;
  float: right;
  background: #055646 !important;
  font-size: 16px;
  padding: 0 10px;
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif !important;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 0;
  outline: none;
  border-radius: 8px;
  white-space: nowrap;
  vertical-align: middle;
}

@media only screen and (max-width: 1023px) and (min-width: 900px) {
  .grp-dtl-container .view-container {
    height: 375px;
  }

  .live-info .icon {
    max-width: 16.667%;
  }

  .live-info .detail {
    width: 75%;
  }

  .live-info .join {
    width: 100%;
    margin: 0;
  }
}
@media only screen and (max-width: 767px) and (min-width: 481px) {
  .grp-dtl-container .view-container {
    height: 375px;
  }

  .live-info .icon {
    max-width: 16.667%;
  }

  .live-info .detail {
    width: 75%;
  }

  .live-info .join {
    width: 100%;
    margin: 0;
  }
}
.powered-by-container {
  margin-top: 48px;
  position: inherit;
  margin: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media only screen and (max-width: 481px) {
  .course-container .dum-container {
    display: block;
  }
  .course-container .progress-section-ss {
    display: none;
  }
  .dashboard-content-wrapper .join-grp-form .jg-err-mb {
    display: none;
  }
  .dashboard-content-wrapper .join-grp-form .jg-err-web {
    display: block;
  }
  .home-dashboard-header .header-heading {
    display: none;
  }
  .grp-dtl-container .col-1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .grp-dtl-container .col-2 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .powered-by-container {
    margin-top: 48px;
    position: absolute;
    margin: 24px;
    margin-right: 0 !important;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    bottom: 0;
  }
}
@media only screen and (min-width: 481px) {
  .web-hide {
    display: none;
  }
}
@media only screen and (min-width: 481px) and (max-width: 1023px) {
  .tc-carousel-container .tc-carousel-card-container,
  .fc-carousel-container .fc-carousel-card-container {
    width: 50%;
  }
}

/* React Crop CSS =============================================================================*/

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
  background-color: #000;
}
.ReactCrop:focus {
  outline: none;
}
.ReactCrop--disabled,
.ReactCrop--locked {
  cursor: inherit;
}
.ReactCrop__image {
  /* autoprefixer: off */
  /* height: 300px; */
  /* width: 300px; */
  display: block;
  max-width: 100%;
  max-height: -webkit-fill-available;
  max-height: -moz-available;
  max-height: fill-available;
  touch-action: manipulation;
}
.ReactCrop--crop-invisible .ReactCrop__image {
  opacity: 0.5;
}
.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  touch-action: manipulation;
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat;
}
.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}
.ReactCrop__drag-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  outline: 1px solid transparent;
}
.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}
.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}
.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}
.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}
.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}
.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}
.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}
.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}
.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}
.ReactCrop__drag-bar {
  position: absolute;
}
.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}
.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}
.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}
.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}
.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}
@media (pointer: coarse) {
  .ReactCrop__drag-handle {
    width: 20px;
    height: 20px;
  }
  .ReactCrop .ord-nw {
    margin-top: -10px;
    margin-left: -10px;
  }
  .ReactCrop .ord-n {
    margin-top: -10px;
    margin-left: -10px;
  }
  .ReactCrop .ord-ne {
    margin-top: -10px;
    margin-right: -10px;
  }
  .ReactCrop .ord-e {
    margin-top: -10px;
    margin-right: -10px;
  }
  .ReactCrop .ord-se {
    margin-bottom: -10px;
    margin-right: -10px;
  }
  .ReactCrop .ord-s {
    margin-bottom: -10px;
    margin-left: -10px;
  }
  .ReactCrop .ord-sw {
    margin-bottom: -10px;
    margin-left: -10px;
  }
  .ReactCrop .ord-w {
    margin-top: -10px;
    margin-left: -10px;
  }
  .ReactCrop__drag-bar.ord-n {
    height: 16px;
    margin-top: -8px;
  }
  .ReactCrop__drag-bar.ord-e {
    width: 16px;
    margin-right: -8px;
  }
  .ReactCrop__drag-bar.ord-s {
    height: 16px;
    margin-bottom: -8px;
  }
  .ReactCrop__drag-bar.ord-w {
    width: 16px;
    margin-left: -8px;
  }
}
/* .ReactCrop__crop-selection{
    border-radius: 50%;
    border-image-source: none!important;
    border: 5px solid red!important ;
  }
  .ReactCrop__drag-handle{
    opacity: 1;
    } */

.img-crop-canvas {
  border-radius: 50%;
  /* display: none; */
}

.opac-f {
  opacity: 0;
}

/**************** FRESHDESK CSS *********/

.side-bar-freshdesk-container {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  bottom: 0;
  padding: 1.5rem 0;
  color: var(--textColor);
  border-top: 1px solid;
  border-color: rgba(256, 256, 256, 0.5);
}
.side-bar-freshdesk-heading {
  font-family: "Source Sans Pro", sans-serif;
  text-align: left;
  width: 90%;
  margin-bottom: 8px;
}
.side-bar-freshdesk-button {
  height: 40px;
  background-color: #ffffff14;
  -webkit-filter: brightness(2);
  -moz-filter: brightness(2);
  -o-filter: brightness(2);
  -ms-filter: brightness(2);
  width: 90%;
  color: var(--textColor);
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  padding: 0.3rem 0;
  &:hover {
    outline: 1px solid var(--textColor);
  }
}
.side-bar-freshdesk-button:hover {
  outline: 1px solid var(--textColor);
}
@media screen and (max-width: 768px) {
  .side-bar-freshdesk-container {
    bottom: 80px;
  }
}
/**************** FRESHDESK CSS end *********/
.header-get-help {
  display: flex;
  background-color: #f9f6f6;
  border: none;
  font-family: var(--SourceSansPro);
  border-radius: 8px;
  padding: 8px 10px;
  justify-content: center;
  font-size: 18px;
  color: #3c4852;
  gap: 3px;
  width: 100%;
}
.header-get-help-container {
  flex: 1;
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  justify-self: flex-start;
  margin-right: 1.5rem;
}
/* ================================================================================================== */

.error-popover.error {
  bottom: 100px !important;
}
.side-bar__logo-img {
  max-width: 100%;
  max-height: 50px;
  margin: unset !important;
}
.EmailVerificationModal__resendButton {
  color: var(--themeColor);
  font-size: 16px;
  font-weight: 700;
  font-family: "Source Sans Pro";
  background: transparent;
  border: none;
}
.EmailVerificationModal__resendButton:disabled {
  color: gray;
}
.EmailVerificationModal__resendTimer {
  margin: 0 auto;
  font-size: 16px;
}
.EmailVerificationModal__resendContainer {
  display: flex;
  width: 90%;
  align-items: center;
  font-family: "Source Sans Pro";
  margin: 0 auto;
  justify-content: flex-end;
}
.EmailVerificationModal__error {
  text-align: center;
  color: #f16f6b;
  font-weight: 600;
  font-size: 16px;
  font-family: "Source Sans Pro";
}
.pe-adnew-input-error {
  border: 1.5px solid #f16f6b;
}

/* custom loader */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4x;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.edit-window__view-container-heading {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 16px;
  color: #3c4852;
}
.edit-window__view-container-detail {
  font-family: "Source Sans Pro";
  font-style: normal;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  margin-bottom: 16px;

  font-size: 20px;
  color: rgba(60, 72, 82, 0.75);
}
.edit-window__view-container-line {
  color: rgba(60, 72, 82, 0.5);
}
.pe-cnf-btn__otp-2 {
  font-family: "Source Sans Pro";
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(100%, 375px);
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  margin: 20px auto 10px auto;
  background: var(--ctaColor);
  outline: none;
}

/*For Batch Name On All Courses  */
.batchname {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #3c4852;
  background-color: #f9f6f6;
  border-radius: 4px;
  padding: 8px 12px;
  max-width: 100%;
  width: fit-content;
  height: 35px;
  margin-bottom: 16px;
  font-family: "Source Sans Pro", sans-serif;
}

.tooltipabc {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  color: #055646;
  background-color: rgba(5, 86, 70, 0.1);
  border-radius: 4px;
  padding: 7px 12px;
  max-width: 100%;
  width: fit-content;
  height: 35px;
  margin-top: 4px;
  font-family: "Source Sans Pro", sans-serif;
}

.tooltipabc .tooltiptext {
  visibility: hidden;
  max-width: 100%;
  background-color: #222222;
  color: #fff;
  padding: 8px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 0%;
  margin-left: 0px;
  height: fit-content;
  white-space: normal;
  text-align: center;
  overflow-wrap: break-word;
}

.tooltipabc:hover .tooltiptext {
  visibility: visible;
}

.tooltiptext i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -10px;
  width: 20px;
  height: 10px;
  overflow: hidden;
}

.tooltiptext i::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #222222;
}

.pe-cnf-btn__otp-3 {
  font-family: "Source Sans Pro";
  background: none;
  outline: none;
  width: 100%;
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 18px;
  gap: 10px;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

/*****EDIT CSS*********/

.edit-main {
  width: 100%;
  font-family: "Source Sans Pro";
}

.edit-title {
  color: #212a39;
  font-style: normal;
  font-weight: 600;
  padding-top: 22px;
  font-size: 18px;
  text-align: center;
}

/*****AUTH CSS********/

.auth-main {
  width: 100%;
  font-family: "Source Sans Pro";
  background: url(https://cdn.pegasus.imarticus.org/UI_FIXES/Login%20Image.webp)
    center center no-repeat;
  /* background-position-x: -300px; */
  height: 100vh;
  background-size: cover;
}

.auth-main-2 {
  display: flex;
  font-family: "Source Sans Pro";
  justify-content: center;
  align-items: center;
  background: rgba(3, 86, 66, 0.15);
  height: 100vh;
  width: 100%;
}

.auth-block-2 {
  background-color: #fff;
  padding: 16px;
  min-width: 40%;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .auth-block {
  padding: 16px;
  background-color: #fff;
  margin-left: auto;
  float: right;
  height: 100vh;
  overflow: auto;
  width: 50%;
} */
.auth-block-signup {
  height: 90vh !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  /* display: none; */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.auth-block {
  padding: 24px 32px;
  background-color: #fff;
  margin-left: auto;
  float: right;
  height: 90vh;
  width: 400px;
  overflow: auto;
  position: absolute;
  top: 5%;
  right: 5%;
  box-shadow: 0px 4px 24px 4px rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(12px);
  border-radius: 16px;
  text-align: center;
}
.auth-h {
  color: #212a39;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 38px;
}

.auth-txt {
  font-style: normal;
  /* padding-top: 2%; */
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  /* margin-top: 8px;
  margin-bottom: 8px; */
  color: rgba(33, 42, 57, 0.75);
}

.auth-box {
  padding: 8px 0px;
}

.auth-ttl {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #212a39;
  margin-bottom: 8px;
}

.auth-block .green {
  color: var(--themeColor);
}

.auth-block .right {
  float: right;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.auth-inp {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  /* padding-top: 25px;
  padding-bottom: 25px; */
  background: rgba(33, 42, 57, 0.06);
  border: 1px solid rgba(33, 42, 57, 0.5);
  border-radius: 5px;
  font-size: 16px;
  border: none;
  line-height: 20px;
  outline: none;
  box-shadow: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl,
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100%;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.Mui-focused:focus-visible,
.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.Mui-focused:focus-visible {
  /* outline: 1px solid green; */
}
.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl {
  border-color: green !important;
}
.auth-inp:focus {
  outline: none;
  box-shadow: none;
  background: rgba(33, 42, 57, 0.06);
}

.auth-block .mobile {
  /* padding-top: 15px !important;
  padding-bottom: 15px !important; */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.auth-name-box {
  width: 100%;
  margin-right: 5%;
}

.auth-mobile-box {
  width: 100%;
}

.auth-name-mob-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.auth-block .dropdown {
  background: none;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: rgba(33, 42, 57, 0.06);
}
.auth-block .dropdown .myDropDown {
  padding: 0 !important;
  background: transparent !important;
}

.auth-btn {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 18px;
  gap: 10px;
  color: #ffffff;

  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  background: var(--themeColor);
  border-radius: 8px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.auth-btn:hover {
  background: var(--ctaColor);
  border: none;
}

.auth-btn-brand {
  width: 100%;
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 18px;
  gap: 10px;
  color: var(--textColor) !important;

  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;

  border-radius: 5px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: var(--themeColor) !important;
}

.auth-btn-brand:hover {
  border: none;
  background: var(--ctaColor) !important;
}

.auth-btn-red {
  background: rgba(255, 12, 12, 0.4);
}

.auth-btn-red:hover {
  background: rgba(255, 12, 12, 0.5);
}

.pe-disable {
  background-color: var(--ctaColor) !important;
  outline: var(--ctaColor) !important;
  border: var(--ctaColor) !important;
}

.pe-disable:hover {
  background-color: var(--ctaColor) !important;
  outline: var(--ctaColor) !important;
  border: var(--ctaColor) !important;
}

.disable {
  background-color: var(--ctaColor);
  outline: var(--ctaColor) !important;
}

.disable:hover {
  background-color: var(--ctaColor);
  outline: var(--ctaColor) !important;
  cursor: not-allowed;
}

.disable-red {
  opacity: 0.5;
}

.disable-red:hover {
  opacity: 0.5;
  background: none;
  outline: none;
  color: #dc3545;
}

.auth-block .disable {
  background-color: var(--themeColor);
  opacity: 0.9;
}

.auth-block .disable:hover {
  /* background-color: var(--ctaColor); */
  opacity: 0.8;
}

.auth-login-btn {
  text-align: center;
  display: block;
  border: 1px solid #cacaca;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 6px;
  margin: 4px 0;
  color: #212a39;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-login-btn.socials {
  /* border: none; */
  width: 48%;
}

.auth-login-btn-full {
  text-align: center;
  display: block;
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-top: 18px;
  margin-bottom: 18px;
  color: #212a39;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  width: calc(100% - 16px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.auth-login-btn:hover,
.auth-login-btn-full:hover {
  background: lightgray;
  color: #212a39;
}

.auth-login-btn img,
.auth-login-btn-full img {
  margin-right: 8px;
}

.OR {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212a39;
  font-size: 16px;
  line-height: 0px;
  margin: 16px 0px;
}

.OR .line {
  background-color: rgba(33, 42, 57, 0.5);
  height: 1px;
  width: 100%;
  margin: 8px 8px;
}

.oauth {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212a39;
  padding-bottom: 8px;
}

.oauth img {
  margin-right: 8px;
}

.oauth-btn {
  text-align: center;
  height: 60px;
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-sizing: border-box;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 47%;
  font-size: 0.9rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.oauth-btn:hover {
  background: lightgray;
}

@media screen and (max-width: 615px) {
  .auth-name-box {
    width: 100%;
  }

  .auth-mobile-box {
    width: 100%;
  }

  .auth-name-mob-container {
    display: block;
  }

  .auth-login-btn,
  .auth-login-btn-full {
    width: auto;
    margin-top: 0px;
  }

  .oauth {
    display: block;
  }

  .oauth-btn {
    width: auto;
    margin-bottom: 16px;
  }
}

.reset-back {
  width: fit-content;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #212a39;
  opacity: 0.75;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.reset-back:hover {
  color: #212a39;
}

.error-outline {
  border: 1px solid #ff0c0c;
}

.error-outline:focus {
  border: 1px solid #ff0c0c;
}

.auth-error {
  box-sizing: border-box;
  background: rgba(255, 12, 12, 0.1);
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 5px;
  color: #212a39;
  opacity: 0.75;
}

.auth-success {
  box-sizing: border-box;
  background: #00a40010;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 5px;
  color: #212a39;
  opacity: 0.75;
}

.signup-block {
  border: 1px solid #cacaca;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 18px;
  margin-top: 18px;
}

.auth-h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 20px;
  color: #000000;
}

.create-ac-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 18px;
  gap: 10px;
  background: rgba(3, 86, 66, 0.08);
  border-radius: 5px;

  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;

  color: var(--textColor) !important;
  background-color: var(--themeColor);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.create-ac-btn:hover {
  background: rgba(3, 86, 66, 0.2);
  background-color: var(--ctaColor);
}

@media only screen and (max-width: 948px) {
  .auth-block {
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
  }
  .profile-img {
    height: 32px;
    width: 32px;
  }
}

@media only screen and (max-width: 450px) {
  .auth-block {
    padding: 1rem;
  }

  .auth-login-btn.socials {
    width: 100%;
  }
}

.powered-by {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #212a39;
  opacity: 0.5;
  margin-top: 24px;
}

.powered-by:hover {
  color: #212a39;
}

.resend-otp-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.auth-timer {
  float: right;
}

.strength-text {
  background: none !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 25%;
  float: right;
}

.password-strength {
  display: flex;
  align-items: center;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
}

.password-strength .none {
  background: #d9d9d9;
  color: rgba(33, 42, 57, 0.5);
}

.password-strength .weak {
  background: #ff0c0c;
  color: #ff0c0c;
}

.password-strength .moderate {
  background: #f2d059;
  color: #f2d059;
}

.password-strength .strong {
  background: #00a400;
  color: #00a400;
}

.strength-dash {
  border-radius: 8px;
  height: 5px;
  width: 23%;
  margin-right: 2%;
}

.check-pass {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 11px;
  line-height: 20px;
  color: rgba(33, 42, 57, 0.75);
  margin-bottom: 8px;
}

.check-pass img {
  margin-right: 5px;
  height: 1rem;
}

.pass-container {
  display: block;
  width: 100%;
}

.auth-pass {
  display: block;
  background: rgba(33, 42, 57, 0.06);
  height: 50px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 16px;
  border: none;
  width: 100%;
}

.auth-pass:focus {
  border: none;
}

.auth-container .line {
  display: flex;
  height: 4px;
  width: 100%;
  opacity: 0.25;
}

.pass-container .line {
  display: flex;
  height: 4px;
  width: 100%;
  opacity: 0.25;
}

.lred {
  background: #ff0c0c;
}

.lgreen {
  background: #00a400;
}

.show-btn {
  background: rgba(33, 42, 57, 0.06);
  padding: 15px;
  text-align: center;
  padding-left: 1px;
  padding-right: 1px;
  height: 54px;
  display: block;
  width: 25%;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border: 1px solid rgba(33, 42, 57, 0.25);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #212a39;
  opacity: 0.75;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.show-btn:hover {
  background: rgba(33, 42, 57, 0.2);
}

.show-btn img {
  margin-right: 4px;
}

.profile-popup {
  width: 100%;
  /* padding: 30px 66px 0 66px; */
  /* background: white; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 1.5rem; */
  /* margin: 16px 0 0; */
  /* background: #fef6e9; */
  /* border: 1px solid #f9ce86; */
  border-radius: 16px;
}

@media only screen and (max-width: 500px) {
  .profile-popup {
    padding: 10px;
  }
}

.profile-bar-total {
  flex-direction: row;
  display: flex;
}

.installmentContainer {
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;
  margin: 16px 0 24px 0;
  background: #fef6e9;
  border: 1px solid #f9ce86;
  border-radius: 16px;
}
.installmentContainer .statement {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  font-family: "Source Sans Pro";
  color: #3c4852;
}
.installmentContainer .secondaryStatement {
  margin-top: 0.5px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #3c4852;
}
.installmentContainer .installmentHeader {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: rgba(60, 72, 82, 0.5);
  margin: 0;
}
.installmentContainer .actionRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0.5rem 0;
  gap: 4rem;
}
.installmentContainer .actionRow .installmentRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
}

.installmentRow::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.installmentContainer .actionRow .installmentRow.vertical {
  flex-direction: column;
  padding: 0;
  margin-top: 1rem;
  gap: 0;
}
.installmentContainer .actionRow .installmentRow .installment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  margin: 0;
  flex-direction: row;
}
.installmentContainer .actionRow .installmentRow .amount {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #3c4852;
  margin: 0;
}
.installmentContainer .pay {
  all: unset;
  cursor: pointer;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--textColor);
  background: var(--themeColor);
  border-radius: 8px;
  padding: 0 2.25rem;
  min-height: 48px;
  &:hover {
    background-color: var(--ctaColor);
  }
}
.installmentLine {
  box-shadow: 0px 3px 0px #000 inset;
}

.edit-profile-heading {
  font-family: Source Sans Pro;
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

@media only screen and (max-width: 500px) {
  .troubleShoot {
    padding: 0px 0px !important;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.loginBackBtn {
  width: 48px !important;
  height: 48px !important;
  align-items: center;
  border-radius: 8px;
  background: rgba(3, 86, 66, 0.08);
}

.reset-back {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.auth-h2.titleText {
  text-align: left;
  font-size: 32px;
  line-height: 40px;
}

.modalContainer {
  padding: 40px;
}
.firstContainer {
  padding: 24px;
  text-align: center;
}
.verificationCodeP {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.EnteredWrongNumberP {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.ChangeNumberP {
  color: #048e6c;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}
.ValidateOtpButton {
  border-radius: 8px;
  background: #035642;
  width: 200px;
  color: white;
  text-align: center;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
.ValidateOtpButton:hover {
  color: white;
}
.DidnotRecieveP {
  color: #3c4852;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.troubleShootUL {
  list-style: none;
  li {
    border-radius: 10px;
    background: rgba(60, 72, 82, 0.04);
    padding: 16px;
  }
}

.google_new_btn,
.fb_new_btn {
  border: solid 1px;
  border-color: #34a853;
}

.fb_new_btn {
  border-color: #1877f2;
}

.levelContainer ::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.hiddenScroll::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.EmptyStateContainerNew {
  height: 100vh;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

@media screen and (max-width: 1000px) {
  .sidebar-list-item {
    height: 50px;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
    margin-right: auto;
    margin-left: auto;
    border-radius: 8px;
    /* text-align: center; */
  }
}

.sidebar-list-item {
  display: flex;
  justify-content: start;
  gap: 8px;
  align-items: center;
  padding-left: 16px;
}

.header-heading {
  margin-left: 20px;
}

.hideBellOnMobile {
  border-radius: 8px;
  background-color: transparent;
}

.hideBellOnMobile:focus {
  outline: none;
  background-color: #3c485214;
  border-radius: 8px;
}

.user-list-item {
  display: flex;
  gap: 8px;
}

.g-name {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
}

.edit-profile-heading {
  width: 100%;
  padding: 0px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.profile-setting-container {
  padding: 24px;
}

.editPopup__new {
  width: 720px !important;
  max-width: 720px !important;
  position: fixed;
  top: 0%;
  left: 30%;
  margin: 0;
  padding: 0;
  height: 100vh;
}

@media only screen and (max-width: 1100px) {
  .editPopup__new {
    left: 20%;
  }
}

@media only screen and (max-width: 800px) {
  .editPopup__new {
    left: 4%;
  }
}

@media only screen and (max-width: 500px) {
  .editPopup__new {
    left: 0%;
    max-width: 100vw !important;
    width: 100vw !important;
  }
}

.modal-content {
  border: none;
  border-radius: 16px;
}

.editPopup__new_2 {
  width: 480px !important;
  max-width: 480px !important;
  position: fixed;
  top: 0%;
  left: 37%;
  margin: 0;
  padding: 0;
  height: 100vh;
}

@media only screen and (max-width: 1100px) {
  .editPopup__new_2 {
    left: 32%;
  }
}

@media only screen and (max-width: 800px) {
  .editPopup__new_2 {
    left: 20%;
  }
}

@media only screen and (max-width: 500px) {
  .editPopup__new_2 {
    left: 0%;
    max-width: 100vw !important;
    width: 100vw !important;
  }
}

.fade_new:disabled {
  opacity: 0.4;
}

.dashboard-content-2-new {
  border: solid 1px rgba(60, 72, 82, 0.08);
  padding: 16px !important;
  border-radius: 8px;
  margin-bottom: 16px !important;
}

.updated_heading_profile {
  color: rgba(60, 72, 82, 0.8);
  font-family: "Source Sans Pro";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
}

.updated_heading_profile_2 {
  color: rgba(60, 72, 82, 0.6);
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.profile_box_updated:disabled,
.profile_box_updated select {
  border: none;
  background-color: #fff;
  padding: 0;
  color: #212a39;
  font-family: "Source Sans Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  -moz-appearance: none;
  /* for Safari, Chrome, Opera */
  -webkit-appearance: none;
}

.user_name_fix {
  width: 120px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
