.verifyCertiScss {
  .header-nav {
    padding: 40px 0 0 0;
  }

  .header-nav .nav-link {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #ffffff !important;
    margin: 0 15px;
  }
  .navbar-brand {
    margin-right: 0;
    margin-top: 0;
    padding: 0;
    margin-bottom: 5px;
  }
  .header-nav .nav-item:last-child .nav-link {
    margin-right: 0;
  }
  .header-nav .login .nav-link {
    border-radius: 26px;
    padding: 7px 38px;
    border: solid 2px #ffffff;
    color: #fefefe !important;
  }
  .courses-page .header-nav {
    padding: 35px 0;
    height: 60px;
    background-color: #ffffff !important;
    box-shadow: 0 0 14.6px 1.4px rgba(0, 0, 0, 0.2);
  }

  .courses-page .header-nav .nav-link {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #294362 !important;
    margin: 0 15px;
  }

  .courses-page .header-nav .nav-item:last-child .nav-link {
    margin-right: 0;
  }

  .courses-page .header-nav .login .nav-link {
    border-radius: 26px;
    padding: 7px 38px;
    border: solid 2px #294362;
    color: #294362 !important;
  }
  @media (max-width: 991px) {
    .header-nav {
      padding: 10px 0 0 0;
    }
  }
  .var-container {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .user-certy-main-header-container {
    height: 60px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-family: "Source Sans Pro", sans-serif;
    width: 680px;
  }
  .user-certy-main-container {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    padding: 50px 10px;
    background: #7b83fa;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 680px;
  }
  .center-all {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vcr-verified {
    background: #6bff57;
    color: #fff !important;
  }
  .vcr-not-verified {
    background: rgb(255, 97, 97);
    color: #fff !important;
  }
  .vcr-user-container {
    height: 100px;
    width: 100%;
    position: absolute;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    color: #000;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }
  .certy-madel {
    height: 150px;
  }
  .vcr-certy-code {
    display: none;
  }
  .vrfcert-user-detail-name {
    color: #ffffff;
    font-size: 35px;
    font-weight: bold;
  }
  .vrfcert-user-detail-course-name {
    color: #e6e6e6;
    font-size: 25px;
    font-weight: 400;
    font-style: italic;
  }
  .vrfcert-user-detail-issue-date {
    color: #d8d8d8;
    font-size: 25px;
    font-weight: 400;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }
  .display-text {
    height: 36px;
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
    text-transform: uppercase;
    color: #3c4852;
    margin-top: 64px;
  }
  .dashed-border-long {
    width: 80%;
    border: 1px dashed rgba(60, 72, 82, 0.25);
    margin-top: 56px;
  }
  .student-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .username {
    font-family: "Source Sans Pro";
    font-weight: 600;
    font-size: 28px;
    line-height: 35px;
    color: #3c4852;
    margin-top: 36px;
    text-align: center;
  }
  .certificate-name {
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #3c4852;
    opacity: 0.75;
    margin: 10px 10px;
    text-align: center;
  }
  .issue-date {
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #3c4852;
    opacity: 0.75;
    margin-bottom: 40px;
  }
  .display-images {
    display: flex;
    width: 85%;
    justify-content: space-evenly;
    margin-top: 72px;
    align-items: center;
  }
  .dashed-border-short {
    height: 60px;
    transform: rotate(90deg);
    border: 1px dashed rgba(60, 72, 82, 0.25);
    margin: 0 17px;
  }
  .dashed-border-short-mb {
    height: 28px;
    transform: rotate(90deg);
    border: 1px dashed rgba(60, 72, 82, 0.25);
    margin: 0 12px;
  }
  .side-image {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .display-images-mb {
    display: none;
  }
  .certificate-row {
    width: fit-content;
  }
  .img-mobile-middle {
    width: 100px;
    height: 96px;
  }
  .img-mobile-side {
    width: 45px;
    height: 43px;
  }
  @media only screen and (max-width: 768px) {
    .cert-detail-padding {
      padding: 0 15px;
    }
    .vrfcert-user-detail-name {
      color: #ffffff;
      font-size: 30px;
      font-weight: bold;
    }
    .vrfcert-user-detail-course-name {
      color: #e6e6e6;
      font-size: 24px;
      font-weight: 400;
      font-style: italic;
    }
    .vrfcert-user-detail-issue-date {
      color: #d8d8d8;
      font-size: 20px;
      font-weight: 400;
    }

    .user-certy-main-container {
      min-height: 250px;
      border-bottom: 15px;
    }
    .vcr-user-container {
      font-size: 20px;
      height: 70px;
      border-bottom: 15px;
    }
    .certy-madel {
      height: 150px;
    }
    .display-images {
      display: none;
    }
    .display-images-mb {
      display: flex;
      width: 90%;
      justify-content: space-evenly;
      margin-top: 40px;
      align-items: center;
    }
    .display-text {
      margin-top: 24px;
    }
    .certificate-row {
      width: unset;
    }
  }
}
