.certificateViewPage {
  .certi-view-page {
    /* height: 100%; */
    height: 100%;
    overflow: scroll;

    width: 100%;
    position: fixed;
    background: #eaeaea;
  }
  font-family: "Source Sans Pro", sans-serif;
  .certi-view-page__nav {
    display: flex;
    width: 100%;
    height: 80px;
    background: linear-gradient(0deg, #ffffff, #ffffff);
    box-shadow: 0px 4px 8px rgba(33, 42, 57, 0.15);
    padding: 1rem 5rem;
    gap: 2rem;
    /* justify-content: center; */
    align-items: center;
  }

  .certi-view-page__backbutton {
    margin-right: auto;
    border: none;
    background: none;
  }

  .certi-view-page__sharebutton {
    padding: 0.3rem 2.2rem;
    font-size: 18px;
    display: flex;
    align-items: center;
    font-weight: 600;
    /* font-style: ; */
    background: none;
    border-radius: 5px;
    color: #055646;
    color: var(--ctaColor);
    gap: 1rem;
    border: 2px solid var(--ctaColor);
    font-family: "Source Sans Pro";
  }

  .certi-view-page__downloadbutton {
    font-size: 18px;
    padding: 0.3rem 1.2rem;

    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 1rem;
    text-decoration: none;
    color: var(--textColor);
    background-color: var(--ctaColor);
    border: 2px solid #055646;
    border-radius: 5px;
    font-family: "Source Sans Pro";
  }

  .certi-view-page__downloadbutton span {
    color: white;
  }

  .certi-view-page__modalBackground {
    height: 100%;
    overflow: scroll;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
    width: 100%;
    position: fixed;
  }

  .certi-view-page__modalContainer {
    height: 260px;
    border-radius: 4px;
    width: 400px;
    position: fixed;
    padding: 1.5rem 2rem;
    background-color: white;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .certi-view-page__modalHeading {
    font-size: 24px;
    text-align: center;
    width: 100%;
    font-size: 24px;
    flex: 1;
    font-weight: 600;
    text-align: center;
    margin-bottom: 0;
  }

  .certi-view-page__modalHeadingContainer {
    display: flex;
    align-items: center;
    width: 100%;
    color: black;
  }

  .certi-view-page__modalHeadingCloseBtn {
    justify-self: flex-end;
  }

  .certi-view-page__modalPageLinkContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(33, 42, 57, 0.08);
    color: black;
    padding: 1rem 0.8rem;
  }

  .certi-view-page__modalPageLinkContainer span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .certi-view-page__modalPageLinkHeading {
    font-size: 16px;
    font-weight: 900;
    color: black;
  }

  .certi-view-page__modalPageLinkCopyImage {
    height: 25px;
  }

  .certi-view-page__modalPageShareLinkContainer {
    margin-top: 10%;
  }

  .certi-view-page__pdf-view {
    width: 100%;
    height: 600px;
    margin: 0;
  }

  .certi-view-page__copiedSuccess {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0.4rem auto;
  }

  .certi-view-page__copiedSuccess span {
    color: white;
    padding: 0.1rem 0.3rem;
    border-radius: 20px;
    background-color: rgb(32, 32, 32);
  }

  .pdf-view {
    margin: auto;
    margin-top: 5%;
    height: 476px;
    width: 100%;
    border: 1px solid #dadada;
    margin-bottom: 5%;
  }

  @media screen and (max-width: 750px) {
    .certi-view-page__nav {
      padding: 1rem;
    }

    .certi-view-page__sharebutton {
      padding: 0.3rem 0.8rem;
    }

    .certi-view-page__sharebuttonText {
      display: none;
    }

    .certi-view-page__downloadbuttonText {
      display: none;
    }

    .certi-view-page__modalContainer {
      width: 288px;
      height: 250px;
      border-radius: 8px;
    }

    .certi-view-page__modalPageLinkContainer span {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
