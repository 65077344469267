.chatScss {
  .header {
    top: 60px;
    position: sticky;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    z-index: 1000; /* Ensures header stays above other content */
  }

  .content {
    margin-top: 60px; /* Adjust according to header height */
    padding-bottom: 100px; /* Adjust according to footer height */
    overflow-y: auto;
    height: calc(100vh - 160px); /* Calculate the height of the content area */
  }

  .footerchat {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #333;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    z-index: 1000; /* Ensures footer stays above other content */
  }

  .cardText {
    display: flex;
    justify-content: space-between;
  }

  .cardText > small {
    align-self: center;
  }

  /* chatbox styles start*/

  .chatbox {
    background-color: #0556464f;
    background-image: url("https://cdn.pegasus.imarticus.org/index/discussion_bg_neutral.svg");
    background-repeat: repeat;
    /* height: 100%;
    padding-top: 75px;
    padding-left: 0px;
    margin-left: 250px; */
    /*padding-bottom: 65px;*/
    overflow-y: scroll;
    height: 100vh;
    padding-top: 75px;
    overflow-x: hidden;
    background-size: 400px;
  }

  @media only screen and (max-width: 910px) and (min-width: 762px) {
  }
  @media only screen and (max-width: 910px) {
    .dashboard-content-wrapper {
      margin-left: 0px !important;
    }
    .shootpad {
      left: 0 !important;
    }
    /* .shootpad{
      width: 100% !important;
    }   */
    .make-abs {
      left: 0 !important;
    }
    .eck-bc-header-right {
      float: right;
    }
  }
  @media only screen and (max-width: 992px) and (min-width: 911px) {
    .dashboard-content-wrapper {
      right: 0;
      width: 100%;
    }
    .shootpad {
      width: 80% !important;
    }
    .shootpad-left {
      margin-left: 20px;
    }
    .shootpad-rightt {
      margin-right: 20px;
      font-size: 20px;
    }
  }

  .card-body {
    margin: 0px;
    padding: 0px;
  }
  .card-text {
    margin-bottom: 0px;
  }
  .make-abs {
    position: fixed;
    top: 60px;
    left: 250px;
    width: 100%;
    height: 70px;
    z-index: 1;
    background-color: #fff;
  }
  .pg-viewer-wrapper {
    height: 200px !important;
    margin-bottom: 15px;
  }
  .pdf-canvas > canvas {
    width: 400px;
  }
  .studentsMessage {
    font-size: 16px;
    border-top: 2.5px solid #fcd703;
    margin: 5px 0 5px 15px;
    padding: 10px;
    width: fit-content;
    max-width: 500px;
    font-family: "Source Sans Pro,sans-serif";
    word-break: break-all;
  }
  .studentsMessage::after {
    content: "";
    position: absolute;
    top: 0px;
    left: -8px;
    width: 0;
    height: 0;
    border: 8px solid #fff;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 3px;
  }
  .myMessage {
    font-size: 16px;
    background-color: #f6e1e1;
    margin: 10px 0;
    padding: 10px;
    width: fit-content;
    max-width: 500px;
    float: right;
    font-family: "Source Sans Pro,sans-serif";
    word-break: break-all;
  }
  .myMessage::after {
    content: "";
    position: absolute;
    top: -1px;
    right: -12px;
    width: 0;
    height: 0;
    border: 10px solid #f6e1e1;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-radius: 3px;
  }

  .chatbox:after {
    content: "";
    height: 60px;
    display: block;
  }

  .chatbox::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    margin-top: 65px;
    margin-bottom: 60px;
  }

  .chatbox::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  .chatbox::-webkit-scrollbar-thumb {
    background-color: #c3d6db;
  }

  .drop-options {
    display: none;
  }

  .message:hover .drop-options {
    display: block;
    position: absolute;
    right: 4px;
    top: 0px;
    cursor: pointer;
    padding: 3px;
    color: #6b6868;
  }

  .msg-self-container {
    position: relative;
    background-color: #f6e1e1;
    min-height: 60px;
    border: 4px solid #f6e1e1;
    margin-right: 18px;
    margin: 5px 18px 5px 5px;
    border-radius: 8px;
    padding: 4px;
    padding-right: 26px;
    display: inline-block;
    min-width: 100px;
    max-width: 400px;
    float: right;
    box-shadow: 1px 1px 3px #888;
  }

  .msg-self-container::after {
    content: "";
    position: absolute;
    top: -4px;
    right: -12px;
    width: 0;
    height: 0;
    border: 10px solid #f6e1e1;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-radius: 3px;
  }

  .msg-self-content {
    font-size: 16px;
    font-weight: 200;
    word-wrap: break-word;
  }

  .msg-self-media {
    width: 100%;
    background-color: #fff;
    border-radius: 2px;
    margin-bottom: 10px;
  }

  .msg-self-file {
    width: 100%;
    font-size: 16px;
    padding-bottom: 0px;
    font-weight: 200;
    word-wrap: break-word;
  }

  .msg-self-file a {
    cursor: pointer;
  }

  .msg-self-file .doc-cont {
    padding: 20px 5px 0;
  }

  .msg-self-file .doc-cont p.name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #757070;
    display: inline-block;
    max-width: 75%;
  }

  .msg-self-file .doc-cont p.ext {
    color: #757070;
    display: inline-block;
    margin-left: -6px;
    overflow: hidden;
  }

  .msg-self-file #download {
    width: 26px;
    margin: 0;
    padding: 20px 0;
  }

  .msg-self-file img {
    width: 40px;
    display: block;
    margin: 15px auto;
  }

  .msg-self-seens {
    position: absolute;
    margin: 0;
    font-size: 11px;
    bottom: 2px;
    right: 65px;
    font-weight: 400;
    cursor: pointer;
  }

  .msg-self-status {
    position: absolute;
    right: 0;
    bottom: -8px;
    font-size: 12px;
  }

  .msg-self-media-image {
    height: auto;
    max-width: 250px;
    margin: 5px 10px;
    /*border: 2px solid #cecaca;*/
    border-radius: 2px;
  }

  .msg-self-time {
    position: absolute;
    font-size: 11px;
    font-weight: 400;
    bottom: 2px;
    right: 12px;
  }

  .msg-other-container {
    position: relative;
    min-height: 60px;
    background-color: #fff;
    border-radius: 8px;
    padding: 8px;
    display: inline-block;
    min-width: 150px;
    max-width: 400px;
    padding-top: 1px;
    margin: 5px 18px 5px 5px;
    box-shadow: 1px 1px 3px #888;
  }

  .msg-other-container-teacher {
    border-top: 2.5px solid #d32e2e;
  }

  .msg-other-container-parent {
    border-top: 2.5px solid #4fb953;
  }

  .msg-other-container-student {
    border-top: 2.5px solid #fcd703;
  }

  .msg-other-ppic {
    height: 40px;
    width: 40px;
    margin-left: 6px;
    margin-top: 4px;
  }

  .msg-other-container::after {
    content: "";
    position: absolute;
    top: 0px;
    left: -8px;
    width: 0;
    height: 0;
    border: 8px solid #fff;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 3px;
  }

  .msg-other-username {
    font-size: 16px;
    font-weight: 400;
  }

  .msg-other-role {
    background-color: #d32e2e;
    padding: 2px 5px;
    color: #fff;
    font-size: 10px;
    border-radius: 2px;
    margin: 0px 8px 0px 8px;
  }

  .msg-other-content {
    font-weight: 200;
    font-size: 16px;
    word-wrap: break-word;
  }

  .msg-other-time {
    position: absolute;
    font-size: 11px;
    font-weight: 400;
    bottom: 5px;
    right: 12px;
  }

  .msg-other-seens {
    position: absolute;
    margin: 0;
    bottom: 5px;
    right: 65px;
    font-weight: 400;
    font-size: 11px;
    cursor: pointer;
  }
  .msg-update {
    text-align: center;
    margin: 5px 18px 5px 5px;
  }

  .msg-update-content {
    background-color: white;
    padding: 5px 14px;
    border-radius: 8px;
    color: black;
    font-size: 14px;
    font-weight: 400;
  }

  .msg-other-media {
    width: 100%;
    background-color: #f7f7f4;
    border-radius: 2px;
    margin-bottom: 10px;
  }

  .msg-other-media-image {
    height: auto;
    max-width: 250px;
    margin: 5px 10px;
    /*border: 2px solid grey;*/
    border-radius: 2px;
  }

  .img-cover {
    margin-bottom: 15px;
  }
  .waiting-loader {
    position: absolute;
    top: 38px;
    right: 45px;
    color: #585c61;
    font-size: 18px;
  }

  /* chat box styles end */

  /* shootpad styles start */

  .shootpad {
    border-right: 1px solid #ccc;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #fff;
    left: 250px;
    /* margin-left: 250px; */
  }

  .shootpad-left {
    font-size: 30px;
    padding: 4px 4px 0 0;
    float: left;
  }

  .shootpad-rightt {
    font-size: 30px;
    padding: 4px 4px 0 0;
    float: right;
  }

  .newMessageSpan {
    background: rgb(23, 129, 24);
    color: white;
    padding: 10px 20px;
    margin: 25px 0px;
    border: 2px solid black;
    border-radius: 50%;
    position: sticky;
    position: -webkit-sticky;
    top: 500px;
  }

  .chatbox.loading {
    pointer-events: none;
  }

  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #cccc;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 9;
    height: 100vh;
  }

  .centerdIcons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%; /* Ensure the parent div takes up the full height */
  }

  /* .cameraIcon {
  }

  .attachIcon {
  } */

  .shootpad-left > img {
    width: 30px;
  }

  .shootpad-right {
    font-size: 30px;
    padding: 4px 8px;
  }

  .shootpad-right > img {
    width: 35px;
    margin-left: 23px;
  }

  .shootpad-main {
    padding: 8px;
    margin: 10px 0px;
    border: 0.5px solid #21506d;
    border-radius: 4px;
  }

  .shootpad-main > textarea {
    display: inline-block;
    width: 100%;
    height: 1.4em;
    line-height: 1.1;
    border: none;
    resize: none;
    outline: none;
    overflow: hidden;
    overflow-y: auto;
    max-height: 200px;
    vertical-align: middle;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
  }

  .shootpad-main > textarea::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  .shootpad-main > textarea::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  .shootpad-main > textarea::-webkit-scrollbar-thumb {
    background-color: #c3d6db;
  }

  /* shootpad styles end */

  /* group member list styles start */

  .group-img-big {
    width: 85px;
    height: 85px;
    margin-left: 20px;
    margin-top: 30px;
  }

  .group-settings-content {
    padding-top: 50px;
  }

  .admin-panel {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
  }

  .admin-panel > h4 {
    margin-bottom: 5px;
    font-weight: 600;
  }

  .admin-panel > p {
    margin: 0;
  }

  .admin-panel > p > span {
    font-weight: 600;
  }

  .admin-make-way {
    margin-top: 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }

  .admin-make-way > h5 {
    font-size: 16px;
    margin: 11px;
  }

  .admin-make-way > p {
    margin: 0;
  }

  .admin-edit-group {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .admin-edit-group > button {
    float: left;
  }

  .admin-edit-group > p {
    margin-top: 0px;
    padding-top: 5px;
    padding-left: 100px;
  }

  .members-list > h4 {
    padding: 6px 40px;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-weight: 600;
    margin-bottom: 0;
  }

  .all-members {
    max-height: 80%;
    overflow-y: scroll;
    margin: 0;
  }

  .all-members::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  .all-members::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  .all-members::-webkit-scrollbar-thumb {
    background-color: #c3d6db;
  }

  .each-member {
    border: 1px solid #fff;
    margin-bottom: 0px;
    border-radius: 5px;
    position: relative;
    margin: 0 30px;
    padding: 2px 15px;
    height: 55px;
  }

  .each-member-hover {
    border: 1px solid #fff;
  }

  .btn-group.show {
    display: inline-block !important;
  }
  .each-member:hover {
    border: 1px solid black;
    background-color: #ebf4f7;
  }

  .each-member:hover .member-options {
    display: block;
  }

  .each-member:hover .active-on-hover {
    /*-webkit-filter : blur(1px);*/
    background-color: #eee;
  }

  .each-member:hover .member-for-admin {
    /* margin-right: 45px; */
  }

  /*.each-member:hover .member-status-banned{
    margin-right: 45px;
  }
  */
  .member-dropdown-options {
    right: 0;
    top: -4px;
    left: initial;
  }

  .member-options {
    display: none;
    height: 32px;
    width: 28px;
    position: absolute;
    bottom: 6px;
    right: 12px;
  }

  .member-img {
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 5px;
  }

  .role-label {
    width: 10px;
    height: 10px;
  }

  .member-role-label {
    margin: 12px 4px 0 0;
    float: left;
  }

  .member-name {
    margin-left: 50px;
    margin-top: 10px;
    font-weight: 600;
  }

  .member-status {
    float: right;
    margin-top: 10px;
  }

  .member-status > i {
    font-size: 18px;
    margin-left: 5px;
  }

  .member-status-admin {
    margin-top: 6px;
    border: 1px solid #009688;
    border-radius: 10px;
    padding: 2px 4px;
    color: #009688;
  }

  .member-status-banned {
    color: #d32e2e;
  }

  .chat-container {
    width: 90%;
    margin-left: 260px;
    margin: 120px auto 0px auto;
    padding: 20px;
    /*border: 1px solid lightgrey;
    border-radius: 5px;*/
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .dummy_message {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    animation: pulse 1s infinite ease-in-out;
    margin-top: 50px;
  }
  .dummy_message_heading {
    align-items: center;
    margin-bottom: 20px;
    animation: pulse 1s infinite ease-in-out;
    margin-top: 15px;
    padding-left: 70px;
  }
  .dummy_message.mine {
    display: flex;
    flex-direction: revert;
    justify-content: end;
    align-items: center;
    margin-bottom: 20px;
    animation: pulse 1s infinite ease-in-out;
  }
  .mine > .skeleton-avatar {
    margin-left: 10px;
  }

  .skeleton-avatar {
    width: 40px;
    height: 40px;
    background-color: #ddd;
    border-radius: 50%;
    margin-right: 10px;
    animation: pulse 1s infinite ease-in-out;
  }

  .skeleton-text {
    height: 10px;
    width: 80%;
    background-color: #f0f0f0;
    border-radius: 5px;
    animation: pulse 1s infinite ease-in-out;
  }

  .skeleton-text-heading {
    height: 20px;
    width: 150px;
    background-color: #f0f0f0;
    border-radius: 5px;
    animation: pulse 1s infinite ease-in-out;
    margin-bottom: 15px;
  }
  .skeleton-text-small {
    height: 10px;
    width: 100px;
    background-color: #f0f0f0;
    border-radius: 5px;
    animation: pulse 1s infinite ease-in-out;
  }

  @keyframes pulse {
    0% {
      opacity: 0.7;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.7;
    }
  }
  .dummy_message:first-child {
    margin-top: 10px;
  }

  .cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
  }
  .cmn-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
  }
  .switch {
    float: right;
    margin: 4px 0;
  }

  input.cmn-toggle-round + label {
    padding: 2px;
    width: 60px;
    height: 30px;
    background-color: #dddddd;
    border-radius: 60px;
  }
  input.cmn-toggle-round + label:before,
  input.cmn-toggle-round + label:after {
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    content: "";
  }
  input.cmn-toggle-round + label:before {
    right: 1px;
    background-color: #f1f1f1;
    border-radius: 60px;
    transition: background 0.4s;
  }
  input.cmn-toggle-round + label:after {
    width: 29px;
    background-color: #fff;
    border-radius: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: margin 0.4s;
  }
  input.cmn-toggle-round:checked + label:before {
    background-color: #8ce196;
  }
  input.cmn-toggle-round:checked + label:after {
    margin-left: 30px;
  }

  /* socket status */
  .chatchat {
    width: 10px;
    height: 10px;
    border-radius: 25px;
  }
  .chatOnline {
    background: green;
  }
  .chatOffline {
    background: red;
  }
  /* socket status */

  .new-message-line {
    height: 1px;
    width: 100%;
    background-color: #045642; /* Adjust color as needed */
  }
  .new-message-container {
    text-align: center;
    position: relative;
    top: -18px; /* Adjust position to align with the line */
    margin: auto;
  }
  .new-message {
    display: inline-block;
    background-color: #045642;
    padding: 5px 20px;
    font-size: 16px;
    position: relative;
    border-radius: 25px;
    font-family: Source Sans Pro, sans-serif;
    color: white;
  }

  /* 
  .custom-modal-body {
    padding: 0px;
  }

  .custom-modal > .modal-content .custom-modal-body {
    background: transparent;
  }
  .arrowIcon {
    transition: opacity 0.3s ease, transform 0.3s ease; /* Add transition for opacity and transform
    width: 50px;
  }
  .arrowIcon.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
  .rightArrow:hover {
    opacity: 0.8; /* Set opacity on hover 
    transform: translateX(5px); /* Move the icon 5 pixels to the left on hover
  }
  .leftArrow:hover {
    opacity: 0.8; /* Set opacity on hover
    transform: translateX(-5px); /* Move the icon 5 pixels to the left on hover
  }

  .disableButton {
    cursor: not-allowed;
  } */

  .image-preview {
    position: relative;
    overflow: hidden;
    margin: auto;
    border: 1px solid #ccc; /* Border style */
  }

  .image-container {
    overflow-x: auto;
    white-space: nowrap;
  }

  .image-container img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  .scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    z-index: 2;
    width: 50px;
  }

  .scroll-button.left {
    left: 0;
  }

  .scroll-button.right {
    right: 0;
  }

  .select-wrapper {
    background: url("./img/uploadIcon.svg") no-repeat;
    background-size: cover;
    display: block;
    padding-bottom: 5px;
    position: relative;
    /* margin-bottom: 5px; */
    width: 48px;
    height: 48px;
  }

  .image_src {
    width: 26px;
    height: 26px;
    margin-right: 100px;
    opacity: 0;
    filter: alpha(opacity=0); /* IE 5-7 */
  }
}

.image-preview {
  position: relative;
  overflow: hidden;
  margin: auto;
  border: 1px solid #ccc; /* Border style */
}

.image-container {
  overflow-x: auto;
  white-space: nowrap;
}

.image-container img {
  display: block;
  max-width: 100%;
  height: auto;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.8);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  z-index: 2;
  width: 50px;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

.select-wrapper {
  background: url("./img/uploadIcon.svg") no-repeat;
  background-size: cover;
  display: block;
  padding-bottom: 5px;
  position: relative;
  /* margin-bottom: 5px; */
  width: 48px;
  height: 48px;
}

.image_src {
  width: 26px;
  height: 26px;
  margin-right: 100px;
  opacity: 0;
  filter: alpha(opacity=0); /* IE 5-7 */
}

.each-member {
  border: 1px solid #fff;
  margin-bottom: 0px;
  border-radius: 5px;
  position: relative;
  margin: 0 30px;
  padding: 2px 15px;
  height: 55px;
}

.each-member-hover {
  border: 1px solid #fff;
}

.btn-group.show {
  display: inline-block !important;
}
.each-member:hover {
  border: 1px solid black;
  background-color: #ebf4f7;
}

.each-member:hover .member-options {
  display: block;
}

.each-member:hover .active-on-hover {
  /*-webkit-filter : blur(1px);*/
  background-color: #eee;
}
